import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const AvailableLanguages = {
  en: 'en',
  ger: 'ger',
};

const changeLanguage = (lenguage = AvailableLanguages.en) => {
  i18n.changeLanguage(lenguage);
};

const useTranslations = (translationFileName = '', keyPrefixValue = '') => {
  const keyPrefix = keyPrefixValue ? { keyPrefix: keyPrefixValue } : '';
  const { t } = useTranslation(translationFileName, keyPrefix);

  return {
    AvailableLanguages,
    changeLanguage,
    t,
  };
};

export { useTranslations };
