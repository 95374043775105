import React, { useState } from 'react';
import {
  currencyFormat,
  testNumberWithDecimal,
  validateEmail,
} from '../../util';
import './InputWithError.css';

const REQUERID_ERROR = 'This is a required field.';
const EMAIL_FORMAT_ERROR = 'Invalid email.';

const InputWithError = ({
  absoluteButtonContent = '',
  ariaProps,
  autoComplete = 'new-password',
  containerClass = '',
  disabled = false,
  forwardRef,
  id,
  inputClass = '',
  isCurrency = false,
  label = '',
  labelClass = '',
  labelContainerClass = '',
  labelError,
  labelErrorClass = '',
  onAbsoluteBtnClick = () => { },
  onBlur,
  onChange,
  onFocus,
  placeholder = '',
  required,
  showError = false,
  type = 'text',
  value,
  ...props
}) => {
  const [internalError, setInternalError] = useState('');
  const [showInternalError, setShowInternalError] = useState(false);

  function validateValue(event) {
    const { target } = event;

    if (required && !target.value) {
      setInternalError(REQUERID_ERROR);
      setShowInternalError(true);

      return;
    }

    if (type === 'email'
      && !validateEmail(target.value)) {
      setInternalError(EMAIL_FORMAT_ERROR);
      setShowInternalError(true);
    }
  }

  function showLabelError() {
    if (showError) {
      return labelError;
    }

    return internalError;
  }

  function handleOnchange(event) {
    const { target } = event;

    if (isCurrency && !testNumberWithDecimal(target.value)) {
      return;
    }

    if (onChange) {
      onChange(event);
    }
  }

  function handleOnblur(event) {
    const { target } = event;
    const eventFormated = {
      ...event,
      target: {
        ...event.target,
        value: isCurrency
          ? currencyFormat({ value: target.value })
          : target.value,
      },
    };

    if (onBlur) {
      onBlur(eventFormated);
    }

    validateValue(eventFormated);
  }

  function handleOnFocus(event) {
    const { target } = event;
    const eventFormated = {
      ...event,
      target: {
        ...event.target,
        value: isCurrency
          ? target.value.replace('$', '')
          : target.value,
      },
    };

    if (onFocus) {
      onFocus(eventFormated);
    }

    setShowInternalError(false);
  }

  return (
    <div className={`Flex Column Relative ${containerClass}`}>
      <label className={`Flex Column ${labelContainerClass}`} htmlFor={id}>
        <span
          className={`Input-whit-error-label ${showError || showInternalError
            ? 'Input-whit-error-label-fail' : ''} ${labelClass}`}
        >
          {label}
        </span>

        <input
          ref={forwardRef}
          aria-disabled={disabled}
          aria-errormessage={`error${id}`}
          aria-invalid={showError || showInternalError}
          aria-required={required}
          autoComplete={autoComplete}
          className={`Input-with-error ${inputClass}`
            + `${showError || showInternalError
              ? ' Input-with-error-error' : ''}`}
          disabled={disabled}
          id={id}
          onBlur={handleOnblur}
          onChange={handleOnchange}
          onFocus={handleOnFocus}
          placeholder={placeholder}
          required={required}
          type={type}
          value={value}
          {...ariaProps}
          {...props}
        />
      </label>

      <span
        aria-live="polite"
        className={'Input-with-error-label-error '
          + `${labelErrorClass}`
          + `${showError || showInternalError ? '' : 'Hide'}`}
        id={`error${id}`}
      >
        {showLabelError()}
      </span>

      {absoluteButtonContent ? (
        <button
          className="Input-with-error-absolute-button"
          onClick={onAbsoluteBtnClick}
          type="button"
        >
          {absoluteButtonContent}
        </button>
      ) : null}
    </div>
  );
};

export default InputWithError;
