import React, { useState, createRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  createNewLiveVetAccount,
  liveVetModalLoginVisibleChanged,
  resetLoginFailMessage,
} from '../../actions';
import syncCloudIcon from '../../assets/sync-clouds.svg';
import './ModalCustomerLogin.css';
import FigoLottie from './FigoLottie';
import InputWithError from './InputWithError';
import TouchableDiv from './TouchableDiv';
import { useB2CInfo } from '../../hooks';
import { useROPC } from '../../util';

const ModalCustomerLogin = ({
  emailUser,
  isModal = true,
  onForgotPassword,
  onDismiss,
  isLiveVet = false,
}) => {
  const history = useHistory();
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const {
    sessionInformation,
    customerValidationResponse: { hasPassword },
  } = store;
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState(emailUser);
  const dispatch = useDispatch();
  const passwordInput = createRef(null);
  const { loginB2CROPC, ropcError, setRopcError } = useB2CInfo();

  useEffect(() => {
    if (!hasPassword && useROPC) {
      onForgotPassword();
    }
  }, [hasPassword, onForgotPassword]);

  function renderPrimaryButtonText() {
    if (sessionInformation.loadingLogin) {
      return (
        <FigoLottie
          height={30}
          width={30}
        />
      );
    }

    return <span>Login </span>;
  }

  const onChangePassword = useCallback((event) => {
    const { target: { value } } = event;

    setPassword(value);
  }, []);

  const onSubmitLogin = (event) => {
    event.preventDefault();

    if (!password && passwordInput && passwordInput.current) {
      passwordInput.current.focus();
      passwordInput.current.blur();
      return;
    }

    if (sessionInformation.loadingLogin) {
      return;
    }

    loginB2CROPC({ password, username: email });
  };

  useEffect(() => document.activeElement.blur(), []);

  useEffect(() => {
    if (sessionInformation.nopCommerceUser.LoginSuccess) {
      dispatch(liveVetModalLoginVisibleChanged(false));
    }
  }, [dispatch, sessionInformation]);

  function showPasswordOrUserError() {
    const {
      nopCommerceUser: { LoginMessage, LoginSuccess },
    } = sessionInformation;

    if (ropcError) {
      return ropcError;
    }

    if (!LoginSuccess && LoginMessage) {
      return LoginMessage;
    }

    return '';
  }

  const onFocusPassword = useCallback(() => {
    dispatch(resetLoginFailMessage());
    setRopcError('');
  }, [dispatch, setRopcError]);

  const onChangeEmail = useCallback((event) => {
    const { target } = event;

    setEmail(target.value);
  }, []);

  const goCreateNewAccount = async () => {
    await dispatch(createNewLiveVetAccount());

    if (isLiveVet) {
      dispatch(liveVetModalLoginVisibleChanged(false));
      const url = '/quoting/getQuote/liveVetInfo';
      history.push(url, { isNewUser: true });
    }

    onDismiss();
  };

  return (
    <div className={'Flex Column '
      + `${isModal ? 'Modal-login-user-subscription-container' : 'No-tablet'}`}
    >
      {isModal && (
        <img
          alt=""
          className="Modal-login-user-subscription-bubbles-image"
          src={syncCloudIcon}
        />
      )}

      <span className={isModal
        ? 'Modal-login-user-subscription-title'
        : 'Modal-login-user-subscription-title-get-quote'}
      >
        {isModal ? 'We’ve met before' : 'Login to your account'}
      </span>

      <p className={'Modal-login-user-subscription-description '
        + `${isModal ? '' : 'No-modal-login-user-subscription-description'}`}
      >
        {isModal
          ? `We notice that you already have a Pet Cloud account
        with ${email}. Please log into your account to complete purchase.`
          : `We noticed you already have a Pet Cloud account. 
            You may qualify for a multi-pet discount!`}
      </p>

      <form
        aria-busy={sessionInformation.loadingLogin}
        className={'Modal-login-user-button-container'
          + `${isModal ? '' : ' No-modal-login-user-button-container'}`}
        onSubmit={onSubmitLogin}
      >

        {!isModal && (
          <div className="Login-input-email-container">
            <span className="Login-input-text">Email</span>
            <InputWithError
              containerClass="Login-input-container-class"
              id="inpEmailUser"
              inputClass="Login-input-email"
              onChange={onChangeEmail}
              placeholder="Email"
              type="email"
              value={email}
            />
          </div>
        )}

        <div
          className={'Login-input-password-general-container'
            + `${isModal ? ''
              : ' Login-input-password-general-container-no-modal'}`}
        >
          <div className={'Login-input-password-container'
            + `${isModal ? '' : ' Login-input-password-container-no-modal'}`}
          >
            <div
              className="Row Inter-medium-13 Modal-login-user-input-headers"
            >
              <span className="Login-input-text" id="inpPasswordUser-label">
                Password
              </span>

              <TouchableDiv
                className="Btn Text-mint"
                id="btnForgotPassword"
                onClick={onForgotPassword}
              >
                <span className="Text-mint No-clicked">
                  Forgot?
                </span>
              </TouchableDiv>
            </div>

            <InputWithError
              ariaProps={{
                'aria-labelledby': 'inpPasswordUser-label',
              }}
              autoComplete="new-password"
              containerClass="Login-input-container-class"
              disabled={sessionInformation.loadingLogin}
              forwardRef={passwordInput}
              id="inpPasswordUser"
              inputClass={'Login-input-password'
                + ` ${isModal ? '' : 'Login-input-password-no-modal'}`}
              labelError={showPasswordOrUserError()}
              onChange={onChangePassword}
              onFocus={onFocusPassword}
              required
              showError={!!showPasswordOrUserError()}
              type="password"
              value={password}
            />
          </div>

          <button
            aria-busy={sessionInformation.loadingLogin}
            aria-label={sessionInformation.loadingLogin ? 'Loading' : 'Login'}
            aria-live={sessionInformation.loadingLogin ? 'polite' : 'off'}
            className={'Modal-login-user-subscription-primary-button'
              + ` ${isModal ? ''
                : 'No-modal-login-user-subscription-primary-button'}`}
            onClick={onSubmitLogin}
            type="submit"
          >
            <span
              className="Modal-login-user-subscription-primary-button-text"
            >
              {renderPrimaryButtonText()}
            </span>
          </button>
        </div>
      </form>

      {isModal && (
        <span className="Modal-login-user-footer-container">
          {'Prefer a new account? '}

          <TouchableDiv
            className="Inline Btn Like-span"
            id="btnNewAccount"
            onClick={goCreateNewAccount}
          >
            <span className="No-clicked">
              Click here.
            </span>
          </TouchableDiv>
        </span>
      )}
    </div>
  );
};

export default ModalCustomerLogin;
