import React, { useRef, useCallback, useContext } from 'react';
import { forceFocusElement } from '../util';

const SavedElementContext = React.createContext();

export const SavedElementProvider = (({ children }) => {
  const refElement = useRef();
  const refId = useRef();

  const clean = useCallback(() => {
    refElement.current = null;
    refId.current = null;
  }, []);

  const saveId = useCallback((id) => {
    refId.current = id;
  }, []);

  const saveElement = useCallback((element) => {
    if (element) {
      refElement.current = element;
      saveId(element.id);
    }
  }, [saveId]);

  const saveElementById = useCallback((id) => {
    const element = document.getElementById(id);
    saveElement(element);
  }, [saveElement]);

  const goToSavedElement = useCallback(() => {
    if (!refElement.current && refId.current) {
      forceFocusElement(refId.current);
    } else if (refElement.current && refElement.current.nodeName !== 'BODY') {
      refElement.current.focus();
    }
  }, []);

  const goToSavedElementAndForgot = useCallback(() => {
    goToSavedElement();
    clean();
  }, [goToSavedElement, clean]);

  return (
    <SavedElementContext.Provider
      value={{
        goToSavedElement,
        goToSavedElementAndForgot,
        saveElement,
        saveElementById,
        saveId,
      }}
    >
      {children}
    </SavedElementContext.Provider>
  );
});

export const useSavedElement = () => useContext(SavedElementContext);
