import React from 'react';
import { useTranslations } from '../../hooks/useTranslations';

import './WhatsCoveredLink.css';

const WhatsCoveredLink = ({
  className = '',
  icon = null,
  id = 'whatsCoveredLink',
  isHeader,
  onClick = () => { },
  text = '',
}) => {
  const { t } = useTranslations('quoting', 'powerUpsSection');

  return (
    <button
      className={`Powerup-see-whats-covered-link-main-container ${className}`}
      id={id}
      onClick={onClick}
      type="button"
    >
      {icon && (
        <img
          alt=""
          className="Powerup-card-plan-info-image"
          src={icon}
        />
      )}

      <p
        className={'Powerup-see-whats-covered-link '
          + `${isHeader ? 'No-mobile' : ''}`}
      >
        {text || t('whatsCovered')}
      </p>
    </button>
  );
};

export { WhatsCoveredLink };
