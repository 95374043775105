import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LandingEn from './translations/landing.en.json';
import LandingGer from './translations/landing.ger.json';
import PurchaseResultEn from './translations/purchaseResult.en.json';
import QuotingEn from './translations/quoting.en.json';
import LoginEn from './translations/login.en.json';
import CommonEn from './translations/common.en.json';
import EnrollmentEn from './translations/enrollment.en.json';

const resources = {
  en: {
    common: CommonEn,
    enrollment: EnrollmentEn,
    landing: LandingEn,
    login: LoginEn,
    purchaseResult: PurchaseResultEn,
    quoting: QuotingEn,
  },
  ger: {
    landing: LandingGer,
  },
};

i18n
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
    },
    lng: 'en',
    resources,
  });

export default i18n;
