import { useEffect, useRef } from 'react';

// This hook returns the previous value of an specific sent value
function usePrevious(value) {
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]);
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export { usePrevious };
