import React, {
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  changeIsNewCustomerValid,
  newCustomerChanged,
  validateLiveVetGetQuote,
} from '../../actions';
import {
  NEW_CUSTOMER_EMAIL_CHANGED,
  NEW_CUSTOMER_PASSWORD_CHANGED,
} from '../../actions/types';

import {
  validateEmail,
  removeEmojis,
  testContainLetter,
  testContainNumber,
} from '../../util';
import newCustomerImage from '../../assets/yellow-pet-cloud-new-customer.svg';
import showPasswordIcon from '../../assets/yourInfo/show-password.svg';
import showingPasswordIcon from '../../assets/yourInfo/showing-password.svg';
import { LiveVetContext } from '../contexts/LiveVetContext';

import { ModalHasLiveVetSubscription } from './ModalHasLiveVetSubscription';

import './LiveVetNewCustomer.css';
import Checkbox from '../common/Checkbox';

const TYPES = {
  newCustomerEmail: NEW_CUSTOMER_EMAIL_CHANGED,
  newCustomerPassword: NEW_CUSTOMER_PASSWORD_CHANGED,
};

const TEST_SPACES = /\s/;
const MIN_PASSWORD_LENGTH = 8;

const LiveVetNewCustomer = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const dispatch = useDispatch();
  const { t } = useTranslation('purchaseResult');
  const { newCustomerValue } = useContext(LiveVetContext);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const {
    liveVetData,
    newCustomer,
    sessionInformation: { nopCommerceUser },
  } = store;

  const isNewCustomerValid = useMemo(() => (
    !!newCustomer.email.trim().length
    && validateEmail(newCustomer.email)
    && newCustomer.password.length >= MIN_PASSWORD_LENGTH
    && passwordConfirm.length >= MIN_PASSWORD_LENGTH
    && !TEST_SPACES.test(newCustomer.password)
    && (nopCommerceUser.LoginSuccess
      || newCustomer.password === passwordConfirm)
  ), [newCustomer, passwordConfirm, nopCommerceUser.LoginSuccess]);

  const forceNewCustomerCallback = useCallback(() => {
    if (!newCustomer.email || !validateEmail(newCustomer.email)) {
      setEmailError('Invalid email address');
    }

    if (newCustomer.password.length < MIN_PASSWORD_LENGTH) {
      setPasswordError('Minimum 8 characters');
    }

    if (passwordConfirm.length !== newCustomer.password) {
      setPasswordConfirmError('Passwords do not match');
    }
  }, [newCustomer.email, newCustomer.password, passwordConfirm]);

  useEffect(() => {
    dispatch(changeIsNewCustomerValid(isNewCustomerValid));
  }, [dispatch, isNewCustomerValid]);

  useEffect(() => {
    newCustomerValue.setForceNewCustomer(forceNewCustomerCallback);
  }, [forceNewCustomerCallback]);

  const onInputFocus = (callbackState) => () => {
    callbackState('');
  };

  function onBlurEmail(event) {
    const { value } = event.target;
    let error = value ? '' : 'Required';

    if (value) {
      error = validateEmail(value) ? '' : 'Invalid email address';

      if (!error) {
        dispatch(validateLiveVetGetQuote(value || '@'));
      }
    }

    setEmailError(error);
  }

  function onBlurPassword(event) {
    const { value } = event.target;
    let error = '';
    if (value.length < MIN_PASSWORD_LENGTH) {
      error = 'Minimum 8 characters';
    }
    if (TEST_SPACES.test(value)) {
      error = 'Empty space is not a valid character';
    }

    setPasswordError(error);
  }

  function validatePasswordMatch({ confirm, password }) {
    if (!confirm) {
      return;
    }

    const error = password === confirm ? '' : 'Passwords do not match';

    setPasswordConfirmError(error);
  }

  function onBlurPasswordConfirm(event) {
    const { value } = event.target;
    const { password } = store.newCustomer;
    const error = password === value ? '' : 'Passwords do not match';

    setPasswordConfirmError(error);
  }

  function onPasswordConfirmChanged(event) {
    setPasswordConfirm(event.target.value);
    onBlurPasswordConfirm(event);
  }

  function onValueChanged(event) {
    const { id, value } = event.target;
    const cleanValue = removeEmojis(value);

    dispatch(newCustomerChanged({ type: TYPES[id], value: cleanValue }));

    if (id === 'newCustomerPassword') {
      validatePasswordMatch({ confirm: passwordConfirm, password: cleanValue });
    }
  }

  function tooglePassword1() {
    setShowPassword1((prev) => !prev);
  }

  function tooglePassword2() {
    setShowPassword2((prev) => !prev);
  }

  const InputError = ({ error }) => (
    <span className="LiveVet-NewCustomer-label-error">
      {error}
    </span>
  );

  const requirements = useMemo(() => [{
    completed: newCustomer.password.length >= MIN_PASSWORD_LENGTH,
    id: t('livevet.passwordRequirements.minLengthId'),
    label: t('livevet.passwordRequirements.minLengthLabel'),
  },
  {
    completed: testContainLetter(newCustomer.password),
    id: t('livevet.passwordRequirements.letterRequiredId'),
    label: t('livevet.passwordRequirements.letterRequiredLabel'),
  },
  {
    completed: testContainNumber(newCustomer.password),
    id: t('livevet.passwordRequirements.numberRequiredId'),
    label: t('livevet.passwordRequirements.numberRequiredLabel'),
  },
  ], [newCustomer.password, t]);

  return (
    <div className="LiveVet-NewCustomer-container-card">
      <div className="LiveVet-NewCustomer-container">
        <div className="LiveVet-NewCustomer-container-div-left">
          <h3>Pet Cloud Login</h3>

          <p className="LiveVet-NewCustomer-TextInfo">
            {t('livevet.customerLogin')}
          </p>
        </div>

        <div className="LiveVet-NewCustomer-container-div
            LiveVet-NewCustomer-login"
        >
          <div>
            <div className="Pet-parent-input-container">
              <span
                className="LiveVet-Pet-parent-input-label"
                htmlFor="newCustomerEmail"
              >
                Login Email
              </span>

              <input
                className={'LiveVet-NewCustomer-input-container '
                  + `${emailError ? 'NewCustomer-input-error' : ''}`}
                id="newCustomerEmail"
                name="email"
                onBlur={onBlurEmail}
                onChange={onValueChanged}
                onFocus={onInputFocus(setEmailError)}
                placeholder="Required"
                type="email"
                value={newCustomer.email}
              />

              <InputError error={emailError} />
            </div>

            <div className="Pet-parent-input-container ">
              <span
                className="LiveVet-Pet-parent-input-label"
                htmlFor="newCustomerPassword"
              >
                Create Password
              </span>

              <input
                autoComplete="new-password"
                className={'LiveVet-NewCustomer-input-container '
                  + `${passwordError ? 'NewCustomer-input-error' : ''}`}
                id="newCustomerPassword"
                name="password"
                onBlur={onBlurPassword}
                onChange={onValueChanged}
                onFocus={onInputFocus(setPasswordError)}
                placeholder="Minimum 8 characters"
                type={showPassword1 ? 'text' : 'password'}
                value={newCustomer.password}
              />

              <button
                className="Livevet-NewCustomer-login-button"
                onClick={tooglePassword1}
                type="button"
              >
                <img
                  alt={showPassword1 ? 'hide' : 'show'}
                  src={showPassword1 ? showingPasswordIcon : showPasswordIcon}
                />
              </button>

              <InputError error={passwordError} />
            </div>

            <div className="Pet-parent-input-container">
              <span
                className="LiveVet-Pet-parent-input-label"
                htmlFor="newCustomerPasswordConfirm"
              >
                Confirm Password
              </span>

              <input
                autoComplete="new-password"
                className={'LiveVet-NewCustomer-input-container '
                  + `${passwordConfirmError
                    ? 'NewCustomer-input-error' : ''}`}
                id="newCustomerPasswordConfirm"
                name="password"
                onBlur={onBlurPasswordConfirm}
                onChange={onPasswordConfirmChanged}
                onFocus={onInputFocus(setPasswordConfirmError)}
                placeholder="Minimum 8 characters"
                type={showPassword2 ? 'text' : 'password'}
              />

              <button
                className="Livevet-NewCustomer-login-button"
                onClick={tooglePassword2}
                type="button"
              >
                <img
                  alt={showPassword2 ? 'hide' : 'show'}
                  src={showPassword2 ? showingPasswordIcon : showPasswordIcon}
                />
              </button>

              <InputError error={passwordConfirmError} />
            </div>
          </div>

          <div className="NewCustomer-container-div">
            <span className="NewCustomer-requirements-title">
              Password requirements:
            </span>

            {requirements.map((requirement) => (
              <Checkbox
                key={requirement.id}
                checked={requirement.completed}
                className="NewCustomer-requirement"
                containerClassName="New-customer-requirement-container"
                dark
                id={requirement.id}
                isRounded
                readOnly
              >
                {requirement.label}
              </Checkbox>
            ))}
          </div>
        </div>
      </div>

      <img
        alt="new customer"
        className="LiveVet-NewCustomer-image"
        src={newCustomerImage}
      />

      <ModalHasLiveVetSubscription
        email={newCustomer.email}
        show={liveVetData?.HasAccess}
      />
    </div>
  );
};

export default React.memo(LiveVetNewCustomer);
