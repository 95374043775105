import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  DropDownIcon,
  PetQuoteConfirmButton,
  PetQuoteInfo,
} from './PetQuoteDropDownCommon';
import { usePetQuoteDropdown } from './hooks/usePetQuoteDropdown';
import { useSavedElement } from '../../../hooks';

import { DROPDOWN_ITEM_TYPE } from '../../../constants';
import './PetQuoteDropDown.css';
import { validatePowerupsSelected } from '../../../util';
import { setPowerUpErrors } from '../../../actions';

const PetQuoteDropDownItem = ({
  dropDownItemType,
  isOpen,
  onAddPetClick,
  onEditPetSelection,
  onHeaderClick,
  onItemClick,
  petQuote = {},
  petQuoteToEdit,
  quoteSelectedValues,
  toggleOpen = () => { },
}) => {
  const [showEditSection, setShowEditSection] = useState(false);
  const location = useLocation();
  const isLiveVet = location.pathname.includes('liveVetInfo');
  const dispatch = useDispatch();
  const { data, petQuoteSelected } = useSelector(({ quoting }) => quoting);

  const {
    onEditPet,
    onRemovePetAskConfirmation,
    petRemovedLoading,
    quoteSelected,
  } = usePetQuoteDropdown({ petQuote, toggleOpen });

  const { saveId } = useSavedElement();

  const isAddPet = dropDownItemType === DROPDOWN_ITEM_TYPE.addPet;
  const quoteCount = quoteSelectedValues.length;
  const petActionText = showEditSection ? 'Cancel' : 'Edit';

  useEffect(() => {
    if (!petQuoteToEdit) {
      return;
    }

    if (petQuoteToEdit !== quoteSelected.quoteId) {
      setShowEditSection(false);
    }
  }, [petQuoteToEdit, quoteSelected]);

  const handleItemClick = () => {
    switch (dropDownItemType) {
      case DROPDOWN_ITEM_TYPE.header:
        onHeaderClick();
        break;

      case DROPDOWN_ITEM_TYPE.addPet:
        onAddPetClick();
        break;

      case DROPDOWN_ITEM_TYPE.pet:
        onItemClick(petQuote.petQuoteId);
        break;

      default:
        break;
    }
  };

  const handleEditPet = () => {
    onEditPet();
    setShowEditSection(false);
    saveId(`petQuoteDropDownItem${petQuote.petQuoteId}-header`);
  };

  const toogleEdtiSection = () => {
    if (petRemovedLoading) {
      return;
    }

    const petQuoteRateItem = data.ebPetQuoteResponseList
      .find((pqRateItem) => pqRateItem.petQuoteId === petQuoteSelected);
    const powerUpValidation = validatePowerupsSelected(petQuoteRateItem);
    if (!powerUpValidation.isValid) {
      dispatch(setPowerUpErrors(powerUpValidation.modifierIdNotSelectedList));
      toggleOpen();
      return;
    }
    if (quoteCount === 1) {
      handleEditPet();

      return;
    }

    setShowEditSection((prev) => !prev);
    onEditPetSelection(quoteSelected.quoteId);
  };

  const handleCancelRemovePet = () => {
    if (petRemovedLoading) {
      return;
    }

    onEditPetSelection();
    setShowEditSection(false);
  };

  const handleKeyDown = (event) => {
    const { keyCode, shiftKey } = event;
    const TAB_KEY = 9;
    if (dropDownItemType === DROPDOWN_ITEM_TYPE.addPet
      && !shiftKey && keyCode === TAB_KEY) {
      toggleOpen();
    }
  };

  const currentSelection = useMemo(() => petQuote.petName
    || 'None', [petQuote]);

  const renderLabel = () => {
    if (dropDownItemType === DROPDOWN_ITEM_TYPE.addPet) {
      return (
        <span>
          Add Pet

          <span
            className={`Pet-quote-drop-down-item-get-discount-label
            ${isLiveVet ? ' Gone' : ''}`}
          >
            Get a 5% Discount
          </span>
        </span>
      );
    }

    return currentSelection;
  };

  function getAriaPropsForDiv() {
    let ariaProps = {
      role: 'menuitem',
      title: `${currentSelection} selected`,
    };

    if (dropDownItemType === DROPDOWN_ITEM_TYPE.header) {
      ariaProps = {
        ...ariaProps,
        'aria-controls': 'dd-options-pet-list',
        'aria-expanded': isOpen,
        'aria-haspopup': 'menu',
        role: 'button',
      };
    }

    if (dropDownItemType === DROPDOWN_ITEM_TYPE.addPet) {
      ariaProps = {
        role: 'menuitem',
        title: 'Add Pet',
      };
    }

    return ariaProps;
  }

  function renderChildren() {
    return (
      <>
        <div className="Pet-quote-drop-down-item-up">
          <button
            className={'Like-span Pet-quote-drop-down-item'
              + ` ${(dropDownItemType === DROPDOWN_ITEM_TYPE.remove
                ? 'Pet-quote-dropdown-item-to-remove' : '')}`}
            id={`petQuoteDropDownItem${petQuote.petQuoteId}`
              + `${dropDownItemType === DROPDOWN_ITEM_TYPE.header
                ? '-header' : ''}`}
            onClick={handleItemClick}
            onKeyDown={handleKeyDown}
            type="button"
            {...getAriaPropsForDiv()}
          >
            <DropDownIcon
              dropDownItemType={dropDownItemType}
              isOpen={isOpen}
              petType={petQuote.petType}
            />

            <h2
              className={'Pet-quote-drop-down-pet-name'
                + ` ${dropDownItemType === DROPDOWN_ITEM_TYPE.addPet
                && 'Pet-quote-drop-down-pet-name-full-width'}`}
            >
              {renderLabel()}
            </h2>

            <PetQuoteInfo
              dropDownItemType={dropDownItemType}
              handleCancelRemovePet={handleCancelRemovePet}
              totalMontly={quoteSelected.totalMontly}
            />
          </button>

          {isOpen
            && !isAddPet
            && dropDownItemType !== DROPDOWN_ITEM_TYPE.remove
            && dropDownItemType !== DROPDOWN_ITEM_TYPE.header
            && (
              <button
                aria-expanded={showEditSection}
                className="Like-span Pet-quote-drop-down-remove-pet"
                onClick={toogleEdtiSection}
                type="button"
              >
                <span className="Pet-quote-drop-down-no-events">
                  {petActionText}
                </span>
              </button>
            )}
        </div>

        {isOpen
          && showEditSection
          && dropDownItemType !== DROPDOWN_ITEM_TYPE.remove
          && dropDownItemType !== DROPDOWN_ITEM_TYPE.header
          && (
            <PetQuoteConfirmButton
              onEditPet={handleEditPet}
              onRemovePet={onRemovePetAskConfirmation}
              petRemovedLoading={petRemovedLoading}
            />
          )}
      </>
    );
  }

  function renderContent() {
    if (dropDownItemType === DROPDOWN_ITEM_TYPE.pet) {
      return (
        <div className="Pet-quote-drop-down-item-container">
          {renderChildren()}
        </div>
      );
    }

    let className = 'Pet-quote-drop-down-item-container';
    if (dropDownItemType === DROPDOWN_ITEM_TYPE.header) {
      className += ' Pet-quote-drop-down-item-combo ';
    }

    return (
      <div className={className}>
        {renderChildren()}
      </div>
    );
  }

  return renderContent();
};

export { PetQuoteDropDownItem };
