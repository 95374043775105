import React from 'react';

const AddPetGenderSelector = ({
  error = '',
  handleFemaleGenderSelected = () => { },
  handleMaleGenderSelected = () => { },
  InputError = () => { },
  maleGenderRef = null,
  petGenderSelected = {},
}) => (
  <div className="Modal-add-pet-gender-selector-wrapper">
    <span className="Modal-add-pet-gender-selector-title">Gender</span>
    <div className="Modal-add-pet-gender-selector">
      <button
        ref={maleGenderRef}
        aria-describedby="progressQueueId"
        aria-label={petGenderSelected.maleGender.label}
        aria-live={petGenderSelected.maleGender.selected
          ? 'assertive' : 'off'}
        className={'Modal-add-pet-gender-selector-item '
          + `${petGenderSelected.maleGender.className}`}
        id="modalAddPetMaleSelector"
        onClick={handleMaleGenderSelected}
        type="button"
      >
        Male
      </button>

      <button
        aria-describedby="progressQueueId"
        aria-label={petGenderSelected.femaleGender.label}
        aria-live={petGenderSelected.femaleGender.selected
          ? 'assertive' : 'off'}
        className={'Modal-add-pet-gender-selector-item '
          + 'Modal-add-pet-gender-selector-item-right '
          + `${petGenderSelected.femaleGender.className}`}
        id="modalAddPetFemaleSelector"
        onClick={handleFemaleGenderSelected}
        type="button"
      >
        Female
      </button>
    </div>

    <InputError
      className="Modal-add-pet-align-self-start"
      error={error}
    />
  </div>
);

export { AddPetGenderSelector };
