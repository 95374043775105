import React, { useRef, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import calendarIcon from '../../assets/yourInfo/calendar-icon-dates.svg';
import './LiveVetImportantDates.css';

const ImportantDates = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const contentRef = useRef();
  const { t } = useTranslation('purchaseResult');
  const { effectiveDate } = store.data;

  const importantDates = useMemo(() => {
    const isValidDate = new Date(`${effectiveDate} 12:00:00`) > new Date();
    const validEffectiveDate =
      moment(isValidDate ? effectiveDate : Date.now())
        .add(1, 'd').format('MMMM D, YYYY');

    const renewalDate =
      moment(validEffectiveDate).add({ days: 1, years: 1 })
        .format('MMMM D, YYYY');

    return {
      effectiveDate: validEffectiveDate,
      renewalDate,
    };
  }, [effectiveDate]);

  if (store && store.data) {
    return (
      <div className="Livevet-Important-dates-main-card">
        <h3 className="Important-dates-title-header">
          {t('livevet.importantDates.title')}
        </h3>

        <div className="Important-dates-cards-container">
          <div className="Important-dates-invdividual-card">
            <img
              alt=""
              className="Important-dates-invdividual-card-icon"
              src={calendarIcon}
            />

            <div className="Important-dates-invdividual-card-col">
              <span className="Important-dates-invdividual-card-title">
                {t('livevet.importantDates.effectiveDate')}
              </span>

              <span className="Important-dates-invdividual-card-description">
                {importantDates.effectiveDate}
              </span>
            </div>
          </div>

          <div className="Important-dates-invdividual-card">
            <img
              alt=""
              className="Important-dates-invdividual-card-icon"
              src={calendarIcon}
            />

            <div className="Important-dates-invdividual-card-col">
              <span className="Important-dates-invdividual-card-title">
                {t('livevet.importantDates.renewalDate')}
              </span>

              <span className="Important-dates-invdividual-card-description">
                {importantDates.renewalDate}
              </span>
            </div>
          </div>
        </div>

        <span ref={contentRef} className="Important-dates-description">
          {t('livevet.importantDates.description')}
        </span>
      </div>
    );
  }

  return null;
};

export default ImportantDates;
