import React from 'react';
import { useTranslation } from 'react-i18next';

import checkIcon from '../../../assets/powerUps/circle-check.svg';
import { usePowerupsRadioButtons } from '../../../util';

import './PowerUpTitle.css';

const PowerUpTitle = ({
  addedLabel = '',
  children,
  localSelected,
  title,
}) => {
  const { t } = useTranslation('quoting');
  return (
    <div
      className={`Powerup-title-container${usePowerupsRadioButtons
        ? '-optional' : ''}`}
    >
      <div className="Powerup-title">
        <h3>
          {usePowerupsRadioButtons && (
            <span className="Powerup-optional">
              {t('powerUpsSection.optional')}
            </span>
          )}

          {title}
        </h3>

        {children}
      </div>

      {!usePowerupsRadioButtons && localSelected && (
        <div className="Powerup-right-content Row">
          <img alt="" src={checkIcon} />

          <span>{addedLabel}</span>
        </div>
      )}
    </div>
  );
};

export { PowerUpTitle };
