import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import arrowRightIcon from '../../../assets/next-white.svg';

import './PurchaseStickyBar.css';

const PurchaseStickyBar = ({
  disabled = false,
  onPurchaseClick = () => { },
}) => {
  const { t } = useTranslation('quoting');
  const history = useHistory();

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <section
      aria-label={t('priceStickyBar.priceBanner')}
      className="Purchase-sticky-bar-container"
    >
      <div className="Purchase-sticky-bar-price-inner-container">
        <button
          className="Purchase-sticky-bar-back-button"
          onClick={onBackClick}
          type="button"
        >
          {t('purchaseStickyBar.back')}
        </button>

        <button
          className={`Purchase-sticky-bar-purchase-button${disabled
            ? ' Purchase-sticky-bar-purchase-button-disabled' : ''}`}
          onClick={onPurchaseClick}
          type="button"
        >
          {t('purchaseStickyBar.purchase')}

          <img
            alt=""
            className="Purchase-sticky-bar-button-icon"
            src={arrowRightIcon}
          />
        </button>
      </div>
    </section>
  );
};

export { PurchaseStickyBar };
