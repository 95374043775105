import React, { useEffect, useRef } from 'react';
import './MultiModalInfo.css';

const MultiModalInfoItemMenu = ({
  id = '',
  onClick = () => { },
  selected = false,
  title = '',
}) => {
  const buttonRef = useRef(null);

  function handleClick() {
    if (onClick) {
      onClick(id);
    }
  }

  useEffect(() => {
    if (selected) {
      buttonRef.current.focus();
    }
  }, [selected]);

  return (
    <button
      ref={buttonRef}
      className={`Multi-modal-info-menu-item ${
        selected ? ' Multi-modal-info-menu-item-selected' : ''}`}
      id={String(id)}
      onClick={handleClick}
      type="button"
    >
      <span
        className={`Multi-modal-info-item-title ${
          selected ? ' Multi-modal-info-item-title-selected' : ''}`}
      >
        {title}
      </span>
    </button>
  );
};

export default MultiModalInfoItemMenu;
