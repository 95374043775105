import React, { forwardRef } from 'react';
import './ScrollableDiv.css';

const ScrollableDiv = forwardRef((props, ref) => (
  <div
    ref={ref}
    className={`Content-scrollable-y ${
      props.className ? props.className : ''}${
      props.scrollbarMargin ? ' Scrollbar-margin' : ''}
    `}
    id={props.id}
    {...props.ariaProps}
    role={props.role}
    style={props.style}
  >
    {props.children}
  </div>
));

export default ScrollableDiv;
