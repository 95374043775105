import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslations } from '../../hooks';
import './WhatsNotCovered.css';

const WhatsNotCovered = () => {
  const { t } = useTranslations('quoting', 'whatsNotCovered');

  const store = useSelector((coverage) => coverage.quoting);
  const notCovered = store.data?.CoverageInformation?.NotCovered || [];

  return (
    <div className="Whats-not-covered-container">
      <div className="Whats-not-covered-first-box">
        <h1 className="Whats-not-covered-title">{t('title')}</h1>
      </div>

      <div className="Whats-not-covered-second-box">
        <ul className="Whats-not-covered-second-item">
          {notCovered.map((item) => (
            <li key={String(item.Id)} className="Whats-not-covered-item">
              <div className="Whats-not-covered-boxitem">
                <img alt="" src={item.Icon} />

                <p>{item.Title}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { WhatsNotCovered };
