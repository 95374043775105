import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useEnrollmentflag } from '../../util';
import './MultiModalInfo.css';

const MultiModalInfoItemContent = ({
  bulletIcon = null,
  icon = null,
  isBold = false,
  isNotCovered = false,
  singleItem = false,
  subtitle = '',
  title = '',
  value = '',
}) => {
  const titleRef = useRef();
  const subtitleRef = useRef();
  const [contentLoaded, setContentLoaded] = useState();

  useEffect(() => {
    if (titleRef.current && !contentLoaded) {
      let htmlContent = title;
      let htmlContainer = document.createElement('div');
      htmlContainer.innerHTML = htmlContent;
      titleRef.current.appendChild(htmlContainer);

      htmlContent = subtitle;
      htmlContainer = document.createElement('div');
      htmlContainer.innerHTML = htmlContent;
      subtitleRef.current.appendChild(htmlContainer);

      setContentLoaded(true);
    }
  }, [contentLoaded, subtitle, title]);

  const iconClassname = useMemo(() => {
    let iconStyle = `Multi-modal-info-content-row-icon
    ${isNotCovered ? ' Multi-modal-info-content-row-cross-icon' : ''}`;

    if (!icon) {
      return 'Multi-modal-info-element-gone';
    }

    if (bulletIcon) {
      iconStyle += ' Multi-modal-info-content-row-bullet';
    }

    return iconStyle;
  }, [bulletIcon, icon, isNotCovered]);

  return (
    <li
      className={`Multi-modal-info-content-row${singleItem ? '-single' : ''}`}
    >
      <div className="Multi-modal-info-content-row-top">
        {!useEnrollmentflag && (
        <img
          alt="bullet"
          aria-label="bullet"
          className={iconClassname}
          src={icon}
        />
        )}

        <span
          ref={titleRef}
          className={`${useEnrollmentflag
            ? 'Multi-modal-info-content-row-title-enrollment'
            : 'Multi-modal-info-content-row-title'} ${
            isBold ? 'Inter-semi-bold-16' : 'Inter-regular-14'
          } ${icon ? 'Multi-modal-info-content-bullet-value' : ''}
          `}
        />

        <span className="Inter-medium-16 Multi-modal-info-content-row-value">
          {value}
        </span>
      </div>

      <div className="Multi-modal-info-content-row-middle">
        <span
          ref={subtitleRef}
          className={`${useEnrollmentflag
            ? 'Multi-modal-info-content-row-subtitle-enrollment'
            : 'Multi-modal-info-content-row-subtitle'}`}
        />
      </div>
    </li>
  );
};

export default MultiModalInfoItemContent;
