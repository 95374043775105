import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import {
  CUSTOMER_SERVICE_PHONE_NUMBER,
  UNDERWRITING_LINK,
} from '../../../constants';
import './css/HeaderUnderwriting.css';

const HeaderUnderwriting = () => {
  const { t } = useTranslation('common');
  const store = useSelector(({ quoting }) => ({
    guidData: quoting.guidData,
    supportPhoneNumber: quoting.supportPhoneNumber,
  }), shallowEqual);

  const supportPhoneNumber = useMemo(() => {
    if (store.supportPhoneNumber) {
      return `${store.supportPhoneNumber}.`;
    }

    if (store.guidData) {
      return `${store.guidData.PhoneNumber}.`;
    }

    return `${CUSTOMER_SERVICE_PHONE_NUMBER}.`;
  }, [store]);

  return (
    <div className="Header-underwriting">
      <span>
        <span className="Header-underwriting-call">{t('header.call')}</span>

        <a href={`tel:${supportPhoneNumber}`}>
          {supportPhoneNumber}
        </a>
      </span>

      |

      <a href={UNDERWRITING_LINK} rel="noopener noreferrer" target="_blank">
        {t('header.underwriting')}
      </a>
    </div>
  );
};

export { HeaderUnderwriting };
