import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ModalError from '../../common/ModalError';
import './PurchaseError.css';
import PurchasePage from './PurchasePage';
import { resetPurchaseData, resetSubscriptionData } from '../../../actions';
import { CUSTOMER_SERVICE_PHONE_NUMBER } from '../../../constants';
import { URL_LIVE_VET_YOUR_INFO, URL_YOUR_INFO } from '../../../routes';

const error = `Sorry, we're unable to provide a quote at this time. 
Please contact Customer Service at ${CUSTOMER_SERVICE_PHONE_NUMBER}
to purchase a policy.`;

const PurchaseError = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(resetPurchaseData());
    dispatch(resetSubscriptionData());
  }, [dispatch]);

  function handleErrorGoBack() {
    if (params.liveVet) {
      history.push(URL_LIVE_VET_YOUR_INFO);
    } else {
      history.push(URL_YOUR_INFO);
    }
  }

  return (
    <PurchasePage>
      <div className="Purchase-error-container">
        <ModalError
          buttonText="Go Back"
          message={error}
          onButtonClick={handleErrorGoBack}
        />
      </div>
    </PurchasePage>
  );
};

export default PurchaseError;
