import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { isPaycheck } from '../../../util';
import { resetLiveVetAccess } from '../../../actions';
import arrowRightIcon from '../../../assets/next-citron.svg';

import ModalContainer from '../../common/ModalContainer';
import ModalLiveVetSubscription from '../ModalLiveVetSubscription';
import './LiveVetCard.css';

const LiveVetCard = ({ email, parameters }) => {
  const [
    liveVetSubscriptionModal,
    setLiveVetSubscriptionModal,
  ] = useState(false);
  const store = useSelector(({ quoting }) => quoting);
  const dispatch = useDispatch();
  const { t } = useTranslation('quoting');

  const { liveVetPrice, showModalLoginLiveVet } = store;

  useEffect(() => {
    if (showModalLoginLiveVet) {
      setLiveVetSubscriptionModal(false);
    }
  }, [dispatch, showModalLoginLiveVet, parameters]);

  const handleLiveVetSubscribeButtonClick = useCallback(() => {
    dispatch(resetLiveVetAccess());
    setLiveVetSubscriptionModal(true);
  }, [dispatch]);

  const handleLiveVetSubscriptionModalOutClick = useCallback(() => {
    setLiveVetSubscriptionModal(false);
  }, []);

  if (liveVetPrice === null) {
    return null;
  }

  return (
    <div className="Live-vet-card-container">
      <h3>{t('liveVet.header')}</h3>

      <p className="Live-vet-card-text">
        {t('liveVet.text')}
      </p>

      <div className="Live-vet-card">
        <div className="Live-vet-card-content">
          <h4>{t('liveVet.card.title')}</h4>

          <p>
            {t('liveVet.card.text')}
          </p>
        </div>

        <div className="Live-vet-card-actions">
          <div className="Live-vet-price">
            <span className="Live-vet-price-small">$</span>

            <span>
              {liveVetPrice.integer}
            </span>

            <span className="Live-vet-price-small">
              {`.${liveVetPrice.decimal}/yr.`}
            </span>
          </div>

          <button
            className="Live-vet-button"
            disabled={isPaycheck}
            onClick={handleLiveVetSubscribeButtonClick}
            type="button"
          >
            {isPaycheck
              ? t('liveVet.card.comingSoon')
              : t('liveVet.card.subscribe')}

            <img alt="" src={arrowRightIcon} />
          </button>
        </div>
      </div>

      <ModalContainer
        cancelable
        handleOutClick={handleLiveVetSubscriptionModalOutClick}
        show={liveVetSubscriptionModal}
      >
        <ModalLiveVetSubscription
          email={email}
          onDismiss={handleLiveVetSubscriptionModalOutClick}
        />
      </ModalContainer>
    </div>
  );
};

export { LiveVetCard };
