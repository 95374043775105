import React from 'react';

const DropdownItemList = React.forwardRef(({
  disabled = false,
  id,
  item,
  itemSelectedId,
  listSize = 0,
  onClick = () => { },
  onKeyDown = () => { },
  onKeyPress = () => { },
  selected = false,
}, ref) => (
  <li
    ref={ref}
    aria-disabled={disabled}
    aria-selected={selected}
    aria-setsize={listSize}
    className="Dropdown-common-list-item-container"
    data-disabled={disabled}
    data-selected={!!ref}
    id={id || `dropdownItem${item.value}`}
    onClick={onClick}
    onKeyDown={onKeyDown}
    onKeyPress={onKeyPress}
    role="option"
    tabIndex={-1}
  >
    <span
      className={`Dropdown-common-list-item ${item.value === itemSelectedId
        && 'Dropdown-common-list-item-selected'}`}
      tabIndex={-1}
    >
      {item.label}
    </span>
  </li>
));

export { DropdownItemList };
