import React from 'react';
import Lottie from 'react-lottie';
import loadingSpinner from '../../assets/loading-spinner.json';

const FigoLottie = ({
  animationData = loadingSpinner,
  ariaLabel = '',
  ariaRole = 'presentation',
  height = 30,
  loop = true,
  width = 30,
}) => {
  const defaultOptions = {
    animationData,
    autoplay: true,
    loop,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      ariaLabel={ariaLabel}
      ariaRole={ariaRole}
      height={height || 30}
      isClickToPauseDisabled
      options={defaultOptions}
      style={{ outline: 'initial' }}
      width={width || 30}
    />
  );
};

export default FigoLottie;
