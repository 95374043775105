import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../common/Checkbox';
import {
  AGREE_TERMS_AND_CONDITION_CHANGED,
  CONSENT_ELECTRONIC_DELIVERY_CHANGED,
  UNDERSTAND_CONDITIONS_CHANGED,
} from '../../../actions/types';
import {
  termsAndCondModalVisibleChanged,
  validateTermsAndConditions,
} from '../../../actions/quoting';
import { ELECTRONIC_DELIVERY_NOTICE } from '../../../constants';
import { useEectronicConsent } from '../../../util';
import './TermsAndConditionsCheckbox.css';
import './TermsAndConditions.css';

const TermsAndConditions = ({
  containerRef = null,
  triggerValidation,
}) => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const { rateSummary: { PetQuotes } } = store;
  const {
    agreeTermsAndConditionCheck,
    agreeTermsAndConditionError,
    consentElectronicDeliveryCheck,
    understandConditionCheck,
    understandConditionError,
  } = store.termsAndConditions;
  const dispatch = useDispatch();

  useEffect(() => {
    if (triggerValidation) {
      dispatch(validateTermsAndConditions());
    }
  }, [triggerValidation, dispatch]);

  function isElectronicConsent() {
    if (PetQuotes && PetQuotes[0]) {
      return PetQuotes[0].IsElectronicConsent;
    }

    return false;
  }

  function handleAgreeTermsClick() {
    dispatch({ type: AGREE_TERMS_AND_CONDITION_CHANGED });
  }

  function handleConsentDeliveryClick() {
    dispatch({ type: CONSENT_ELECTRONIC_DELIVERY_CHANGED });
  }

  function handleUnderstandConditionClick() {
    dispatch({ type: UNDERSTAND_CONDITIONS_CHANGED });
  }

  function handleTermsAndConditionsModalOpen() {
    const categoryId = 8;
    const visible = true;
    dispatch(termsAndCondModalVisibleChanged({ categoryId, visible }));
  }

  function handleUnderstandConditionModalOpen() {
    const categoryId = 9;
    const visible = true;
    dispatch(termsAndCondModalVisibleChanged({ categoryId, visible }));
  }

  function renderElectronicConsent() {
    if (useEectronicConsent || isElectronicConsent()) {
      return (
        <Checkbox
          checked={consentElectronicDeliveryCheck}
          className="Terms-condition-checkbox"
          dark
          id="electronicDeliveryNotice"
          keepSpaceError
          onClick={handleConsentDeliveryClick}
        >
          I agree to the&nbsp;
          <a
            className="Terms-and-condition-child-modal-clickable"
            href={ELECTRONIC_DELIVERY_NOTICE}
            rel="noopener noreferrer"
            target="_blank"
          >
            electronic delivery notice
          </a>,
          including going paperless and receiving policy documents
          and communication electronically.
        </Checkbox>
      );
    }

    return null;
  }

  return (
    <>
      <div
        ref={containerRef}
        className={'Your-info-agreements-constainer '
          + 'Terms-condition-checkbox-container'}
      >
        <Checkbox
          checked={agreeTermsAndConditionCheck}
          className="Terms-condition-checkbox"
          containerClassName="Terms-and-condition-checkbox-container"
          dark
          error={agreeTermsAndConditionError}
          errorClasName="Terms-and-condition-error-label"
          errorMessage="Required. Please confirm statement to proceed."
          id="iAgreeTerms"
          keepSpaceError
          onClick={handleAgreeTermsClick}
        >
          I agree to the&nbsp;
          <button
            className="Terms-and-condition-child-modal-clickable"
            onClick={handleTermsAndConditionsModalOpen}
            onKeyPress={handleTermsAndConditionsModalOpen}
            type="button"
          >
            terms and conditions
          </button>
        </Checkbox>

        <Checkbox
          checked={understandConditionCheck}
          className="Terms-condition-checkbox"
          containerClassName="Terms-and-condition-checkbox-container"
          dark
          error={understandConditionError}
          errorClasName="Terms-and-condition-error-label"
          errorMessage="Required. Please confirm statement to proceed."
          id="iUnderstandCondition"
          keepSpaceError
          onClick={handleUnderstandConditionClick}
        >
          I understand that&nbsp;
          <button
            className="Terms-and-condition-child-modal-clickable"
            onClick={handleUnderstandConditionModalOpen}
            onKeyPress={handleUnderstandConditionModalOpen}
            type="button"
          >
            pre-existing conditions&nbsp;
          </button>
          will not be covered
        </Checkbox>

        {renderElectronicConsent()}
      </div>
    </>
  );
};

export default TermsAndConditions;
