import React, { useCallback, useEffect, useState, useMemo } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { openQuote } from '../../actions';
import { FigoLoadingOverlay } from '../common/FigoLoadingOverlay';
import { URL_OPEN_QUOTE_ERROR } from '../../routes';

const OpenQuote = () => {
  const { search } = useLocation();
  const [isMounted, setIsMounted] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((stores) => stores.quoting);
  const { message, searchParameters, saveQuote } = store;
  const history = useHistory();

  const queryParams = useMemo(() => {
    const parameters = queryString.parse(search);
    const { CustomerId, partnerguid, quoteid, ...rest } = parameters;
    const utmRest = rest || {};

    return {
      customerId: CustomerId && encodeURIComponent(CustomerId),
      partnerId: partnerguid && encodeURIComponent(partnerguid),
      quoteId: quoteid && encodeURIComponent(quoteid),
      utmRest,
    };
  }, [search]);

  const redirectOnceLoadIsCompleted = useCallback(() => {
    const url = `/quoting/getQuote${searchParameters}`;

    history.push(url);
  }, [history, searchParameters]);

  useEffect(() => {
    if (saveQuote.openQuoteLoaded) {
      redirectOnceLoadIsCompleted();
    }
  }, [saveQuote.openQuoteLoaded, redirectOnceLoadIsCompleted]);

  useEffect(() => {
    const { customerId, quoteId } = queryParams;

    if (saveQuote.failOpenQuote || (!customerId && !quoteId)) {
      if (saveQuote.redirectLink) {
        window.open(`https://${saveQuote.redirectLink}`, '_self');
      } else {
        history.push('/');
      }
    }
  }, [history, queryParams, saveQuote.failOpenQuote, saveQuote.redirectLink]);

  useEffect(() => {
    if ((queryParams.customerId || queryParams.quoteId) && !isMounted) {
      setIsMounted(true);
      dispatch(openQuote(queryParams));
    }
  }, [isMounted, queryParams, dispatch]);

  useEffect(() => {
    if (saveQuote.savedQuoteExpired) {
      history.push(URL_OPEN_QUOTE_ERROR);
    }
  }, [history, saveQuote.savedQuoteExpired]);

  return (
    <FigoLoadingOverlay visible={!saveQuote.openQuoteLoaded && !message} />
  );
};

export default OpenQuote;
