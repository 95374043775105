import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import step1Icon from '../../../assets/landing/step1.svg';
import step2Icon from '../../../assets/landing/step2.svg';
import step3Icon from '../../../assets/landing/step3.svg';

import { SectionTitle } from '../common';
import { Step } from './Step';
import './LandingHowWorks.css';

const LandingHowWorks = () => {
  const { t } = useTranslation('landing');

  const steps = useMemo(() => [
    {
      icon: step1Icon,
      text: t('howWorks.step1.text'),
      title: t('howWorks.step1.title'),
    },
    {
      icon: step2Icon,
      text: t('howWorks.step2.text'),
      title: t('howWorks.step2.title'),
    },
    {
      icon: step3Icon,
      text: t('howWorks.step3.text'),
      title: t('howWorks.step3.title'),
    },
  ], [t]);

  return (
    <div>
      <SectionTitle title={t('howWorks.title')} />

      <div className="Landing-how-works-steps">
        {steps.map((step) => (
          <Step
            key={step.title}
            icon={step.icon}
            number={step.number}
            text={step.text}
            title={step.title}
          />
        ))}
      </div>
    </div>
  );
};

export { LandingHowWorks };
