import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Header } from '../header/Header';
import { setCurrentQuoteStep } from '../../../actions';
import { QuotingSteps } from '../../../constants';
import './PurchasePage.css';

const PurchasePage = ({
  children,
  success = false,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentQuoteStep(QuotingSteps.Welcome.value));
  }, [dispatch]);

  return (
    <div className="Purchase-page-container">
      <Header isError={!success} />

      <div className="Purchase-page-content-container">
        {children}
      </div>
    </div>
  );
};

export default PurchasePage;
