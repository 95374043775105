import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ill8 from '../../../assets/ills/ill-8.svg';
import { CollapsibleQuestion } from './CollapsibleQuestion';

import './LandingQuestions.css';

// eslint-disable-next-line max-len
const LINK_1 = 'https://www.blackenterprise.com/1-in-3-pets-will-need-emergency-care-heres-how-to-best-protect-your-fur-baby/';
// eslint-disable-next-line max-len
const LINK_2 = 'https://www.bankrate.com/banking/savings/emergency-savings-report/';

const LandingQuestions = () => {
  const [visibleAnswers, setVisibleAnswers] = useState([]);
  const { t } = useTranslation('landing');

  const toggle = useCallback((idQuestion) => {
    setVisibleAnswers((prevAnswers) => {
      if (prevAnswers.find((item) => item === idQuestion)) {
        return [...prevAnswers.filter((item) => item !== idQuestion)];
      }
      return [...prevAnswers, idQuestion];
    });
  }, []);

  const isVisible = (idQuestion) => visibleAnswers
    .find((item) => item === idQuestion);

  return (
    <div className="Landing-questions-container">
      <div className="Landing-questions-title-container">
        <img alt="" className="Landing-question-ill" src={ill8} />

        <h2>
          {t('questions.title')}

          <br aria-hidden />

          {t('questions.title2')}
        </h2>
      </div>

      <div className="Landing-questions-questions">
        <CollapsibleQuestion
          collapsed={!isVisible('question1')}
          id="question1"
          onClick={toggle}
          question={t('questions.whyInsurance.question')}
        >
          <p>{t('questions.whyInsurance.answer1')}</p>

          <Trans
            components={[
              <a href={LINK_1} rel="noopener noreferrer" target="_blank">1</a>,
              <a href={LINK_2} rel="noopener noreferrer" target="_blank">2</a>,
            ]}
            i18nKey="questions.whyInsurance.answer2"
            ns="landing"
          />
        </CollapsibleQuestion>

        <CollapsibleQuestion
          collapsed={!isVisible('question2')}
          id="question2"
          onClick={toggle}
          question={t('questions.whatCovers.question')}
        >
          <p>
            {t('questions.whatCovers.answer')}
          </p>

          <ul className="Landing-questions-question-list">
            <li>
              <Trans i18nKey="questions.whatCovers.accidents" ns="landing" />
            </li>

            <li>
              <Trans i18nKey="questions.whatCovers.illness" ns="landing" />
            </li>

            <li>
              <Trans i18nKey="questions.whatCovers.wellness" ns="landing" />
            </li>
          </ul>

          <p>
            {t('questions.whatCovers.end')}
          </p>
        </CollapsibleQuestion>

        <CollapsibleQuestion
          collapsed={!isVisible('question3')}
          id="question3"
          onClick={toggle}
          question={t('questions.whatNotCovers.question')}
        >
          <p>
            {t('questions.whatNotCovers.answer')}
          </p>

          <ul className="Landing-questions-question-list">
            {t('questions.whatNotCovers.items', { returnObjects: true })
              .map((item) => (
                <li key={item.replace(' ', '')}>{item}</li>
              ))}
          </ul>
        </CollapsibleQuestion>

        <CollapsibleQuestion
          collapsed={!isVisible('question4')}
          id="question4"
          onClick={toggle}
          question={t('questions.whatArePowerups.question')}
        >
          <p>
            {t('questions.whatArePowerups.answer')}
          </p>

          <ul className="Landing-questions-question-list">
            <li>
              <Trans
                i18nKey="questions.whatArePowerups.wellness"
                ns="landing"
              />
            </li>

            <li>
              <Trans
                i18nKey="questions.whatArePowerups.vetFees"
                ns="landing"
              />
            </li>
          </ul>
        </CollapsibleQuestion>

        <CollapsibleQuestion
          collapsed={!isVisible('question5')}
          id="question5"
          onClick={toggle}
          question={t('questions.whyFigo.question')}
        >
          <p>
            {t('questions.whyFigo.answer')}
          </p>

          <ul className="Landing-questions-question-list">
            <li>
              <Trans
                i18nKey="questions.whyFigo.incidentCaps"
                ns="landing"
              />
            </li>

            <li>
              <Trans
                i18nKey="questions.whyFigo.anyVet"
                ns="landing"
              />
            </li>

            <li>
              <Trans
                i18nKey="questions.whyFigo.coverage"
                ns="landing"
              />
            </li>
          </ul>
        </CollapsibleQuestion>
      </div>
    </div>
  );
};

export { LandingQuestions };
