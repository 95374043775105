import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import syncCloud from '../../../../assets/sync-clouds.svg';
import alertIcon from '../../../../assets/alert-hydrant.svg';
import catIcon from '../../../../assets/cat-selected.svg';
import dogIcon from '../../../../assets/dog-selected.svg';

import ModalContainer from '../../../common/ModalContainer';
import { RoundButton } from '../../../common/RoundButton';

import {
  AFTER_RATE_TAG,
  CUSTOMER_SERVICE_PHONE_NUMBER,
  PetGender,
} from '../../../../constants';
import { closeDuplicatedPurchasesModal, removePet } from '../../../../actions';
import { useCallbackAfterRate, useRateSummary } from '../../../../hooks';
import './DuplicatedPurchasesModal.css';
import { scrollToElement } from '../../../../util';

const PetRow = ({ name = '', policyNumber = '', isCat = false }) => {
  const { t } = useTranslation('enrollment');
  return (
    <li className="Duplicated-purchases-modal-pet">
      <img
        alt={t(`duplicatedPurchases.${isCat ? 'altCat' : 'altDog'}`)}
        src={isCat ? catIcon : dogIcon}
      />

      <div>
        <span className="Duplicated-purchases-modal-pet-name">{name}</span>

        <span>
          {policyNumber
            ? t('duplicatedPurchases.policyNumber', { policyNumber })
            : t('duplicatedPurchases.noPolicy')}
        </span>
      </div>
    </li>
  );
};

const IdenticalPurchases = ({
  onClose = () => { },
  pets = [],
  phoneNumber = CUSTOMER_SERVICE_PHONE_NUMBER,
}) => {
  const { t } = useTranslation('enrollment');

  const handleContinue = useCallback(() => {
    window.open(process.env.REACT_APP_PETCLOUD_URL, '_blank');
  }, []);

  return (
    <>
      <img alt="" src={syncCloud} />

      <h1>{t('duplicatedPurchases.identical.title')}</h1>

      <div className="Duplicated-purchases-modal-text">
        {t('duplicatedPurchases.identical.message', {
          pets: pets.map((pet) => pet.PetName),
        })}
      </div>

      <ul className="Duplicated-purchases-modal-list">
        {pets.map((pet) => (
          <PetRow
            isCat={pet.IsCat}
            name={pet.PetName}
            policyNumber={pet.PolicyNumber}
          />
        ))}
      </ul>

      <div className="Duplicated-purchases-modal-text">
        {t('duplicatedPurchases.identical.action')}
      </div>

      <br aria-hidden />

      <div className="Duplicated-purchases-modal-text">
        <Trans
          components={{
            b: (
              <b />
            ),
          }}
          i18nKey="duplicatedPurchases.support"
          ns="enrollment"
          values={{
            phone: phoneNumber,
          }}
        />
      </div>

      <div className="Duplicated-purchase-modal-actions">
        <RoundButton onClick={onClose}>
          {t('duplicatedPurchases.back')}
        </RoundButton>

        <RoundButton onClick={handleContinue} primary>
          {t('duplicatedPurchases.petCloud')}
        </RoundButton>
      </div>
    </>
  );
};

const PartialIdenticalPurchases = ({
  onClose = () => { },
  duplicatedPets = [],
  notDuplicatedPets = [],
  phoneNumber = CUSTOMER_SERVICE_PHONE_NUMBER,
}) => {
  const { t } = useTranslation('enrollment');
  const store = useSelector(({ quoting }) => quoting);
  const dispatch = useDispatch();

  const {
    data: rateData,
    quoteSelectedValues,
    sessionInformation: { nopCommerceUser, userEmail },
    parameters,
    guidData,
  } = store;

  const handleContinue = useCallback(() => {
    if (store.petRemovedLoading) {
      return;
    }

    if (!duplicatedPets.length) {
      onClose();
      return;
    }

    const petQuotesToRemove = rateData.ebPetQuoteResponseList.filter((pet) => {
      const duplicatedCoincidence = duplicatedPets
        .find((duplicatedPet) => duplicatedPet.PetName === pet.petName
          && duplicatedPet.BreedName === pet.breedName
          && duplicatedPet.IsFemale
          === (pet.gender === PetGender.Female.value));

      return !!duplicatedCoincidence;
    });

    const newPetQuoteList = rateData.ebPetQuoteResponseList.filter((pet) => {
      const duplicatedCoincidence = duplicatedPets
        .find((duplicatedPet) => duplicatedPet.PetName === pet.petName
          && duplicatedPet.BreedName === pet.breedName
          && duplicatedPet.IsFemale
          === (pet.gender === PetGender.Female.value));

      return !duplicatedCoincidence;
    });

    const newQuoteSelectedValues = quoteSelectedValues
      .filter((qsv) => !petQuotesToRemove
        .map((pqtr) => pqtr.petQuoteId).includes(qsv.quoteId));

    const newPetQuoteSelected = newPetQuoteList[
      newPetQuoteList.length - 1].petQuoteId;

    const newPetSelected = newPetQuoteList
      .find((pet) => pet.petQuoteId === newPetQuoteSelected);

    dispatch(removePet({
      diamondClientId: nopCommerceUser.DiamonClientdId,
      ebGuID: guidData.GuID,
      effectiveDateCustom: store.effectiveDateCustom,
      eMail: userEmail || parameters.email,
      employerName: guidData.Name,
      guidPayload: guidData,
      modifiers: newPetSelected.modifiers,
      nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      parameters,
      petQuoteList: newPetQuoteList,
      promoCode: rateData.groupCode,
      quoteId: newPetQuoteSelected,
      quoteSelectedValues: newQuoteSelectedValues,
      removePetIds: petQuotesToRemove.map((pet) => pet.petQuoteId),
      showLoading: true,
    }));
    onClose();
    scrollToElement('payment-breakdown');
  }, [
    dispatch,
    duplicatedPets,
    nopCommerceUser,
    onClose,
    parameters,
    quoteSelectedValues,
    rateData,
    store.effectiveDateCustom,
    userEmail,
    store.petRemovedLoading,
    guidData,
  ]);

  return (
    <>
      <img alt="" src={alertIcon} />

      <h1>{t('duplicatedPurchases.partial.title')}</h1>

      <div className="Duplicated-purchases-modal-text">
        {t('duplicatedPurchases.partial.messageDuplicated', {
          pets: duplicatedPets.map((pet) => pet.PetName),
        })}
      </div>

      <ul
        className={'Duplicated-purchases-modal-list'
          + ' Duplicated-purchases-modal-list-duplicated'}
      >
        {duplicatedPets.map((pet, index) => (
          <PetRow
            key={`${String(index)}-${pet.petName}`}
            isCat={pet.IsCat}
            name={pet.PetName}
            policyNumber={pet.PolicyNumber}
          />
        ))}
      </ul>

      <div className="Duplicated-purchases-modal-text">
        {t('duplicatedPurchases.partial.messageNew', {
          pets: notDuplicatedPets.map((pet) => pet.PetName),
        })}
      </div>

      <ul
        className={'Duplicated-purchases-modal-list'
          + ' Duplicated-purchases-modal-list-new'}
      >
        {notDuplicatedPets.map((pet, index) => (
          <PetRow
            key={`${String(index)}-${pet.petName}`}
            isCat={pet.IsCat}
            name={pet.PetName}
            policyNumber={pet.PolicyNumber}
          />
        ))}
      </ul>

      <div className="Duplicated-purchases-modal-text">
        {t('duplicatedPurchases.partial.action', {
          pets: notDuplicatedPets.map((pet) => pet.PetName),
        })}
      </div>

      <br aria-hidden />

      <div className="Duplicated-purchases-modal-text">
        <Trans
          components={{
            b: (
              <b />
            ),
          }}
          i18nKey="duplicatedPurchases.support"
          ns="enrollment"
          values={{
            phone: phoneNumber,
          }}
        />
      </div>

      <div className="Duplicated-purchase-modal-actions">
        <RoundButton onClick={onClose}>
          {t('duplicatedPurchases.back')}
        </RoundButton>

        <RoundButton onClick={handleContinue} primary>
          {t('duplicatedPurchases.continue')}
        </RoundButton>
      </div>
    </>
  );
};

const DuplicatedPurchasesModal = () => {
  const {
    duplicatedPets,
    duplicatedPetsModalVisible,
    notDuplicatedPets,
    supportPhoneNumber,
    guidData,
  } = useSelector(({ quoting }) => ({
    duplicatedPets: quoting.purchase.duplicatedPets,
    duplicatedPetsModalVisible: quoting.purchase.duplicatedPetsModalVisible,
    guidData: quoting.guidData,
    notDuplicatedPets: quoting.purchase.notDuplicatedPets,
    supportPhoneNumber: quoting.supportPhoneNumber,
  }), shallowEqual);
  const dispatch = useDispatch();

  const { getSelectedRate } = useRateSummary();

  useCallbackAfterRate(() => {
    getSelectedRate();
  }, [AFTER_RATE_TAG.removePet]);

  const duplicated = useMemo(() => duplicatedPets
    .filter((pet) => pet.IsDuplicated && pet.PolicyNumber), [duplicatedPets]);

  const isIdenticalPurchase = useMemo(() => (duplicated.length
    && !notDuplicatedPets.length), [duplicated, notDuplicatedPets]);

  const handleClose = useCallback(() => {
    dispatch(closeDuplicatedPurchasesModal());
  }, [dispatch]);

  const phoneNumber = useMemo(() => {
    if (supportPhoneNumber) {
      return supportPhoneNumber;
    }

    if (guidData) {
      return guidData.PhoneNumber;
    }

    return CUSTOMER_SERVICE_PHONE_NUMBER;
  }, [guidData, supportPhoneNumber]);

  return (
    <ModalContainer
      handleOutClick={handleClose}
      show={duplicatedPetsModalVisible}
    >
      <div className="Duplicated-purchases-modal">
        {isIdenticalPurchase
          ? (
            <IdenticalPurchases
              onClose={handleClose}
              pets={duplicated}
              phoneNumber={phoneNumber}
            />
          )
          : (
            <PartialIdenticalPurchases
              duplicatedPets={duplicated}
              notDuplicatedPets={notDuplicatedPets}
              onClose={handleClose}
              phoneNumber={phoneNumber}
            />
          )}
      </div>
    </ModalContainer>
  );
};

export { DuplicatedPurchasesModal };
