import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createNewLiveVetAccount, resetLiveVetAccess } from '../../actions';
import chatIcon from '../../assets/chat-icon.svg';
import ModalContainer from '../common/ModalContainer';
import '../quoting/ModalLiveVetSubscription.css';

const descriptionText =
  'Good news! With your existing Figo account, '
  + 'you already have access to Live Vet for all your pets.';

const ModalHasLiveVetSubscription = ({ email = '', show = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  function handlePrimaryButtonClick() {
    history.goBack();
  }

  async function handleSecondaryButtonClick() {
    dispatch(resetLiveVetAccess());
    dispatch(createNewLiveVetAccount());
  }

  return (
    <ModalContainer
      disableClickOutside={false}
      idPrefix="live-vet"
      show={show}
    >
      <div className="Modal-live-vet-subscription-container">
        <img
          alt=""
          className="Modal-live-vet-subscription-bubbles-image"
          src={chatIcon}
        />

        <span className="Modal-live-vet-subscription-title">Live Vet 24/7</span>

        <span className="Modal-live-vet-subscription-subtitle">
          {email}
        </span>

        <p className="Modal-live-vet-subscription-description">
          {descriptionText}
        </p>

        <button
          className="Modal-live-vet-subscription-primary-button"
          onClick={handlePrimaryButtonClick}
          type="button"
        >
          <span className="Modal-live-vet-subscription-primary-button-text">
            Return to Quote
          </span>
        </button>

        <button
          className="Modal-live-vet-subscription-secondary-button"
          onClick={handleSecondaryButtonClick}
          type="button"
        >
          <span className="Modal-live-vet-subscription-secondary-button-text">
            Create New Account
          </span>
        </button>
      </div>
    </ModalContainer>
  );
};

export { ModalHasLiveVetSubscription };
