import { useEffect } from 'react';
import { isProdEnv, loadEmbeddedScript } from '../util';

const useOneIncScript = () => {
  const scriptEnv = isProdEnv ? 'portalone' : 'testportalone';
  // eslint-disable-next-line max-len
  const scriptSrc = `https://${scriptEnv}.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js`;
  const scriptId = `OneIncScript-${isProdEnv ? 'Prod' : 'Dev'}`;
  const scriptAsync = true;
  const scriptDefer = true;

  useEffect(() => {
    loadEmbeddedScript({
      scriptAsync,
      scriptDefer,
      scriptId,
      scriptSrc,
    });
  }, [scriptAsync, scriptDefer, scriptId, scriptSrc]);
};

export { useOneIncScript };
