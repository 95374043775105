import React from 'react';

import checkedIcon from '../../assets/check.svg';

import './FakeCheckbox.css';

const FakeCheckbox = ({ isChecked = false }) => {
  if (isChecked) {
    return (
      <img
        alt=""
        className="Fake-checkbox"
        src={checkedIcon}
      />
    );
  }

  return <div className="Fake-checkbox-placeholder" />;
};

export { FakeCheckbox };
