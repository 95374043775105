import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { loadSubIdSynonymsData, saveSubId } from '../actions';
import { formatParameters, validatePartnerIds } from '../util';

const usePartnerSubId = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ quoting }) => quoting);
  const [firstMount, setFirstMount] = useState(true);
  const location = useLocation();
  const parametersRaw = queryString.parse(location.search);
  const parameters = formatParameters(parametersRaw);

  useEffect(() => {
    if (!store.subIdSynonyms.length && firstMount) {
      dispatch(loadSubIdSynonymsData());
    }
  }, [dispatch, store.subIdSynonyms, firstMount]);

  useEffect(() => {
    if (store.subIdSynonyms
      && store.subIdSynonyms.length
      && parameters
      && firstMount
    ) {
      const partnerId = validatePartnerIds({
        parameters,
        synonyms: store.subIdSynonyms,
      });
      dispatch(saveSubId(partnerId));
      setFirstMount(false);
    }
  }, [store.subIdSynonyms, dispatch, parameters, firstMount]);
};

export { usePartnerSubId };
