import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  MULTIPET_DISCOUNT,
  ONE_INC_DEFAULT_TOKEN,
  ONE_INC_EMPTY_ACH,
  ONE_INC_EMPTY_CC,
  PAYMENT_TYPE,
  POWERUP_TYPE,
  PetGender,
  PetType,
  PowerUpsRelation,
} from '../constants';
import { appVersion, isProductFPI, phoneMask } from '../util';
import { doPurchaseMultiple, purchaseQuotes, startPurchase } from '../actions';
import { useSessionFooter } from './useSessionFooter';
import { useIndividualPurchases } from './useFeatureFlag';

export const usePurchase = () => {
  const dispatch = useDispatch();
  const sessionFooter = useSessionFooter();

  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const individualPurchases = useIndividualPurchases();

  const {
    data,
    employer,
    employerId, // EmployerGuid
    isMonthlyPlan,
    newCustomer,
    oneInc,
    parameters,
    paymentMethod,
    plans,
    secondParentFormVisible,
    selectedVeterinarian,
    subId,
    termsAndConditions,
    sessionInformation: {
      nopCommerceUser: { DiamonClientdId },
      isCustomer,
    },
  } = store;
  const getSelectedValues = useCallback((quoteId) => {
    const { quoteSelectedValues } = store;

    return quoteSelectedValues.find((item) => item.quoteId === quoteId) || {};
  }, [store]);

  const getMultiplePetId = useCallback(() => {
    const productId = store.data?.insuranceProductEB.Id;

    return isProductFPI(productId) ? 58 : 9;
  }, [store.data]);

  const clientIdToSend = DiamonClientdId;

  /**
     * DTO with quote pet list (InsurancePetQuotes)
     * @return QuotePurchaseDto model
     */
  const getQuotePurchaseDto = useCallback((insurancePetQuotes = []) => {
    const { address } = newCustomer;
    const { insuranceProductEB, ebPetQuoteResponseList } = data;
    const { stateAbbr, zipCode } = address;
    const { InsuranceProductFeeEB } = insuranceProductEB;

    /* eslint-disable max-len */
    /* eslint-disable sort-keys */
    const quotePurchaseDto = {
      QuotePurchaseDto: {
        GroupPartnerCode: null,
        EffectiveDate: null,
        CloudClientId: 0,
        EMail: null,
        PetState: stateAbbr,
        PetZip: zipCode,
        PaymentPlans: [
          {
            Id: 1,
            Description: 'Monthly',
          },
          {
            Id: 2,
            Description: 'Annual',
          },
        ],
        IsMultiplePets: ebPetQuoteResponseList.length > 0,
        IsRated: true,
        RatingFromComplete: true,
        ProductInfo: 'ProductId : 2 - Name IHC001 - Description : Underwritten by Independence American Insurance Group',
        Error: [],
        InsuranceProductId: 2,
        FooterText: sessionFooter,
        PetQuotes: [],
        QuotationId: store.saveQuote.savedQuoteId,
        InsurancePetQuotes: insurancePetQuotes,
        NeedToKnows: [
          {
            Title: 'What\'s Covered',
            NeedToKnowDetails: [
              {
                Text: 'New illnesses and accidents',
              },
              {
                Text: 'Diagnostic tests related to illnesses/accidents',
              },
              {
                Text: 'Prescription medications',
              },
              {
                Text: 'Surgeries, prosthetics, chronic conditions, emergency services, advanced treatments, and much more',
              },
              {
                Text: 'You may visit any licensed veterinarian in the US, Canada, and Puerto Rico.',
              },
            ],
          },
          {
            Title: 'What\'s Not Covered',
            NeedToKnowDetails: [
              {
                Text: 'Pre-existing conditions',
              },
              {
                Text: 'Routine wellness or preventative care',
              },
              {
                Text: 'Spaying or neutering',
              },
              {
                Text: 'Dental cleanings or procedures not related to an accident',
              },
              {
                Text: 'Prescription food',
              },
            ],
          },
          {
            Title: 'Deductible and Coinsurance',
            NeedToKnowDetails: [
              {
                Text: 'One annual deductible per policy',
              },
              {
                Text: 'Your coinsurance is the difference between your reimbursement percentage and 100%. (For example, a 90% reimbursement would have a 10% coinsurance.)',
              },
              {
                Text: 'The annual deductible will be applied and deducted from covered claims before benefits apply.',
              },
              {
                Text: 'Once the annual deductible has been met, you will be reimbursed at your selected reimbursement amount for the rest of your policy period.',
              },
            ],
          },
          {
            Title: 'Waiting Periods',
            NeedToKnowDetails: [
              {
                Text: 'Waiting periods begin on your policy effective date',
              },
              {
                Text: '3-day waiting period for accident (injury)',
              },
              {
                Text: '14-day waiting period for illness',
              },
              {
                Text: '6-month waiting period for knee conditions',
              },
            ],
          },
        ],
        ImportantDates: [
          {
            Title: '',
            ImportantDateDetails: [
              {
                Text: 'Pre-existing conditions are not covered. For the health.',
                IconRoute: null,
              },
            ],
          },
        ],
        Fees: InsuranceProductFeeEB.map((fee) => ({
          TypeId: fee.InsuranceFeeEBId,
          Name: fee.InsuranceFeeEB.DisplayName,
          Amount: fee.InsuranceFeeEB.Amount,
          DisplayText: fee.InsuranceFeeEB.DisplayText,
        })),
        GroupPartnerDisplay: null,
        IsZipCodeForQuote: false,
        IsEndorsement: false,
        TestEffectiveDate: store.effectiveDateCustom,
      },
    };

    return quotePurchaseDto;
  }, [data, newCustomer, sessionFooter, store.effectiveDateCustom, store.saveQuote.savedQuoteId]);

  /**
   * Payload with user info, address, etc.
   * @returns Purchase payload complete
   */
  const getQuoteRequestBase = useCallback(({ isRetryPurchase, insurancePetQuotes, includeMultiPetDiscount = false }) => {
    const { address } = newCustomer;
    const secondParent = secondParentFormVisible
      ? newCustomer.secondParent : {};
    const { insuranceProductEB } = data;
    const { stateAbbr, zipCode } = address;
    const { SelectedStateFactorEB } = insuranceProductEB;
    const {
      customCity,
      customStateId,
      isCustomVet,
      nameOfPractice,
    } = selectedVeterinarian;

    // when IsElectronicConsent, takes the user selection
    // otherwise, takes consentElectronicDeliveryCheck with default true
    const sendElectronicConsent = termsAndConditions.consentElectronicDeliveryCheck;

    let oneIncSrcEft = oneInc.bankAccount;
    let oneIncSrcCC = oneInc.creditCard;

    if (oneInc.tokenId === ONE_INC_DEFAULT_TOKEN) {
      oneIncSrcEft = ONE_INC_EMPTY_ACH;
      oneIncSrcCC = ONE_INC_EMPTY_CC;
    }

    const paymentDataToSend = {
      OneIncCreditCard: {
        CardExpirationMonth: oneIncSrcCC.cardExpirationMonth,
        CardExpirationYear: oneIncSrcCC.cardExpirationYear,
        CardholderName: oneIncSrcCC.customerName,
        CardType: oneIncSrcCC.cardType,
        LastFourDigits: oneIncSrcCC.lastFourDigits,
        TokenId: oneIncSrcCC.tokenId,
      },
      OneIncEft: {
        LastFourDigits: oneIncSrcEft.lastFourDigits,
        TokenId: oneIncSrcEft.tokenId,
      },
    };

    const secondaryBillingAddress = {
      MailAddressLine1: address.street,
      MailAddressLine2: address.street2,
      MailAddressCity: address.city,
      MailAddressState: stateAbbr,
      MailAddressZip: zipCode,
    };

    const quotePurchaseDto = getQuotePurchaseDto(insurancePetQuotes);

    const quoteRequestBase = {
      ...quotePurchaseDto,
      ...paymentDataToSend,
      ...secondaryBillingAddress,
      AgentId: parameters.agentId || '',
      AppVersion: appVersion,
      QuoteSubId: subId,
      PrimaryFirstName: newCustomer.name,
      PrimaryLastName: newCustomer.lastName,
      PrimaryMobilePhone: phoneMask(newCustomer.phoneNumber),
      PrimaryOtherPhone: null,
      PrimaryEMail: newCustomer.email,
      PrimaryEMailConfirm: newCustomer.email,
      PrimaryPassword: isCustomer ? '' : newCustomer.password,
      PrimaryPasswordConfirm: isCustomer ? '' : newCustomer.password,
      LoginPassword: isCustomer ? newCustomer.password : '',
      HasSecondaryOwner: secondParentFormVisible,
      SecondaryFirstName: secondParent.name || null,
      SecondaryLastName: secondParent.lastName || null,
      SecondaryMobilePhone: phoneMask(secondParent.phoneNumber),
      PetAddressLine1: address.street,
      PetAddressLine2: address.street2,
      PetAddressCity: address.city,
      PetAddressState: stateAbbr,
      PetAddressZip: zipCode,
      MailAddressSameAsPet: paymentMethod.mailAddressSameAsPet,
      HowDidYouHear: 'My Veterinarian',
      HasManualVet: isCustomVet,
      SelectedVetID: isCustomVet ? null : selectedVeterinarian.Id,
      VetCompany: isCustomVet ? nameOfPractice : null,
      VetCity: isCustomVet ? customCity : null,
      StateId: isCustomVet ? customStateId : String(SelectedStateFactorEB.StateId),
      SelectedVet: null,
      SelectedVetName: null,
      SelectedVetCode: null,
      VetZip: null,
      VetAddress: null,
      EffectiveDate: null,
      IncludeMultiPetDiscount: includeMultiPetDiscount,
      TestEffectiveDate: store.effectiveDateCustom,
      CloudClientId: 0, // customer ID, default 0
      Referrals: null,
      ElectronicOptOut: false,
      Comment1: 'By default, How Did You Hear Id is 15 = Other',
      HowDidYouHearId: 15,
      Comment2: 'Payment 0 is monthly, 1 is annual',
      PaymentPlanEnum: isMonthlyPlan ? 0 : 1,
      Comment3: 'ShouldProcessPayment, if false it only submits a Rate, if true we make the actual Issue/Purchase',
      ShouldProcessPayment: true,
      Comment4: 'Payment method 0 is ACH, 1 is CC',
      PaymentMethodEnum:
        paymentMethod.paymentMethodType.value === PAYMENT_TYPE.bank.value ? 0 : 1,
      Comment6: 'ACHAccountTypeEnum 0 checkings, 1 savings',
      Comment5: 'isEBProduct decides if we use EB Product'
        + ' configurations, vs regular Products configurations',
      isEBProduct: true,
      IsRetryPurchase: isRetryPurchase,
      EmployerId: employer.id || 1,
      EmployerGuid: employerId,
      EmployeeId: newCustomer.employeeID,
      SendElectronicConsent: sendElectronicConsent,
    };

    return quoteRequestBase;
  }, [
    data,
    employer.id,
    employerId,
    getQuotePurchaseDto,
    isCustomer,
    isMonthlyPlan,
    newCustomer,
    oneInc,
    parameters.agentId,
    paymentMethod,
    secondParentFormVisible,
    selectedVeterinarian,
    store.effectiveDateCustom,
    subId,
    termsAndConditions.consentElectronicDeliveryCheck,
  ]);

  /**
   * List with pet quote with power-up, plan, etc.
   * @returns InsurancePetQuotes list
   */
  const getInsurancePetQuotes = useCallback(() => {
    const { address } = newCustomer;
    const { insuranceProductEB, ebPetQuoteResponseList } = data;
    const { InsuranceModifiersEB } = insuranceProductEB;
    const { stateAbbr, zipCode } = address;
    const { SelectedStateFactorEB } = insuranceProductEB;

    // powerups hidden and seleted id's
    const hideModifiers = InsuranceModifiersEB
      .filter((insuranceModifer) => insuranceModifer
        .InsuranceModifierTypeEBId === POWERUP_TYPE
        && !insuranceModifer.IsVisible
        && insuranceModifer.IsSelected)
      .map((insuranceModifer) => ({
        id: insuranceModifer.Id,
        isSelected: true,
      }));

    const insurancePetQuotes = [];

    ebPetQuoteResponseList.forEach((petQuote) => {
      const { petQuoteId } = petQuote;
      const selectedValues = getSelectedValues(petQuoteId);

      if (!selectedValues.annualAmountPlan) {
        return;
      }

      // pet date of birth (own) default value
      const [m, d, y] = new Date().toLocaleDateString('en-US').split('/');
      const [annualDollar, annnualCents] =
        String(selectedValues.totalAnnual).split('.');
      const [monthlyDollar, monthlyCents] =
        String(selectedValues.totalMontly).split('.');
      const selectedModifiers = petQuote.modifiers || [];

      // powerup id's selected, hidden and powerups children
      let allModifiers = [...selectedModifiers, ...hideModifiers];
      const extraCarePack = InsuranceModifiersEB.find((item) => item
        .PMSModifierId === PowerUpsRelation.EXTRA_CARE_PACK_PSM_ID);

      if (extraCarePack) {
        const extraCarePackSelected = allModifiers
          .find((modifier) => modifier.id === extraCarePack.Id);

        const extraCarePackChildrens =
          extraCarePack.BundleInsuranceModifiersEB.map((child) => ({
            id: child.Id,
            isSelected: extraCarePackSelected
              && extraCarePackSelected.isSelected,
          }));

        allModifiers = [
          ...allModifiers,
          ...extraCarePackChildrens,
        ];
      }

      insurancePetQuotes.push({
        Id: petQuote.petQuoteId,
        PetName: petQuote.petName,
        IsCat: petQuote.petType === PetType.Cat.value,
        IsFemale: petQuote.gender === PetGender.Female.value,
        BreedName: petQuote.breedName,
        BreedId: petQuote.breedId,
        AgeString: petQuote.petAgeName,
        ActiveMilitary: false,
        DownPayment: 27.99,
        DownPaymentDscr: '1 month premium due today',
        AdminFee: 9.0,
        FullPayCCCharge: 10.0,
        Tax: 0.0,
        ActualDownPayment: 0.0,
        VersionId: 158,
        LobId: SelectedStateFactorEB.PMSLOBId,
        CompanyId: SelectedStateFactorEB.PMSCompanyId,
        DOB: '',
        ZipCode: zipCode,
        State: stateAbbr,
        AnnualPayPremium: selectedValues.annualAmountPlan,
        CloudOrderId: 0,
        GetAnnualPayAtRate: true,
        NeedsPaymentInfo: true,
        HasWaitingPeriodWaiver: false,
        DisableEndorsements: false,
        PayrollDeduct: false,
        SelectedDeductibleId: selectedValues.deductibleId,
        SelectedReimbursementId: selectedValues.reimbursementId,
        Plans: petQuote.Plans
          .map((plan) => ({
            ...plan,
            CoverageId: plan.Plan,
          })),
        Coverages: insuranceProductEB.InsuranceProductCoveragesEB,
        Deductibles: petQuote.Deductibles,
        Reimbursements: petQuote.Reimbursements,
        Modifiers: [
          {
            DisplayName: '',
            ModifierDetails: [
              {
                TitleText: 'Veterinary Exam Fees for Accident and Illness Visits',
                InputText: 'This benefit is to help cover exam or consultation fees associated with the diagnosis and treatment of your pet for an eligible illness or accident. It is not intended to cover exam fees related to routine, wellness, or preventative visits.',
                InformationText: 'This benefit is to help cover exam or consultation fees associated with the diagnosis and treatment of your pet for an eligible illness or accident. It is not intended to cover exam fees related to routine, wellness, or preventative visits.',
                IsSelected: false,
                PMSModifierId: 70422,
                QuestionType: 0,
                FeeAmount: 0.2148,
                IsVisible: true,
              },
              {
                TitleText: 'Take Home Prescriptions',
                InputText: 'Would you like to add Take Home Prescriptions to your policy?',
                InformationText: 'Boost your coverage!.',
                IsSelected: true,
                PMSModifierId: 70423,
                QuestionType: 0,
                FeeAmount: 0.199,
                IsVisible: false,
              },
              {
                TitleText: 'Rehabilitation',
                InputText: 'This benefit applies to covered accidents and illnesses and must be performed or supervised by a licensed veterinarian. Coverage includes underwater treadmill therapy, swimming, therapeutic exercises, range of motion exercises, gait analysis, laser therapy, cryotherapy, and heat therapy.',
                InformationText: 'This benefit applies to covered accidents and illnesses and must be performed or supervised by a licensed veterinarian. Coverage includes underwater treadmill therapy, swimming, therapeutic exercises, range of motion exercises, gait analysis, laser therapy, cryotherapy, and heat therapy.',
                IsSelected: true,
                PMSModifierId: 70424,
                QuestionType: 0,
                FeeAmount: 0.0349,
                IsVisible: false,
              },
              {
                TitleText: 'Holistic and Alternatives',
                InputText: 'Would you like to add Holistic and Alternative fees to your policy?',
                InformationText: 'Boost your coverage!',
                IsSelected: true,
                PMSModifierId: 70425,
                QuestionType: 0,
                FeeAmount: 0.0172,
                IsVisible: false,
              },
              {
                TitleText: 'Extra Care Pack',
                InputText: 'Optional coverage is available for final respects (up to $250 for cremation and burial), lost pet advertising/award, vacation cancellation due to pet emergency, boarding fees, pet theft or loss due to straying (up to $150), and third-party property damage liability (up to $10,000 per policy period and subject to a separate deductible).',
                InformationText: 'Optional coverage is available for final respects (up to $250 for cremation and burial), lost pet advertising/award, vacation cancellation due to pet emergency, boarding fees, pet theft or loss due to straying (up to $150), and third-party property damage liability (up to $10,000 per policy period and subject to a separate deductible).',
                IsSelected: false,
                PMSModifierId: 70427,
                QuestionType: 0,
                FeeAmount: 6.62,
                IsVisible: false,
              },
            ],
            ModifierType: 5,
          },
          {
            DisplayName: '',
            ModifierDetails: [
              {
                TitleText: '',
                InputText: 'Multiple-Pet Discount',
                InformationText: '',
                IsSelected: false,
                PMSModifierId: getMultiplePetId(),
                QuestionType: 0,
                FeeAmount: null,
                IsVisible: true,
              },
              {
                TitleText: '',
                InputText: 'Multi Policy Discount',
                InformationText: '',
                IsSelected: false,
                PMSModifierId: 20,
                QuestionType: 0,
                FeeAmount: null,
                IsVisible: false,
              },
              {
                TitleText: '',
                InputText: 'Group Discount',
                InformationText: '',
                IsSelected: false,
                PMSModifierId: 16,
                QuestionType: 0,
                FeeAmount: null,
                IsVisible: false,
              },
              {
                TitleText: '',
                InputText: 'Corporate Partner / Group Discount',
                InformationText: '',
                IsSelected: false,
                PMSModifierId: 5,
                QuestionType: 0,
                FeeAmount: null,
                IsVisible: true,
              },
              {
                TitleText: '',
                InputText: 'Annual Pay Discount',
                InformationText: '',
                IsSelected: false,
                PMSModifierId: 14,
                QuestionType: 0,
                FeeAmount: null,
                IsVisible: true,
              },
              {
                TitleText: '',
                InputText: 'Online Referral Partner',
                InformationText: '',
                IsSelected: false,
                PMSModifierId: 43,
                QuestionType: 0,
                FeeAmount: null,
                IsVisible: true,
              },
              {
                TitleText: '',
                InputText: 'Select Vet Clinic Customer Discount',
                InformationText: '',
                IsSelected: false,
                PMSModifierId: 44,
                QuestionType: 0,
                FeeAmount: null,
                IsVisible: true,
              },
              {
                TitleText: '',
                InputText: 'Vet Clinic Staff Discount',
                InformationText: '',
                IsSelected: false,
                PMSModifierId: 45,
                QuestionType: 0,
                FeeAmount: null,
                IsVisible: true,
              },
              {
                TitleText: '',
                InputText: 'Affinity Groups Discount',
                InformationText: '',
                IsSelected: false,
                PMSModifierId: 46,
                QuestionType: 0,
                FeeAmount: null,
                IsVisible: true,
              },
              {
                TitleText: '',
                InputText: 'IHC and Administrator Employee Discount',
                InformationText: '',
                IsSelected: false,
                PMSModifierId: 47,
                QuestionType: 0,
                FeeAmount: null,
                IsVisible: true,
              },
            ],
            ModifierType: 4,
          },
        ],
        Discounts: [],
        IsServiceDog: false,
        IsExamFees: false,
        IsSpayedNeutered: false,
        VetPast12Months: false,
        TagColorId: 55,
        DateOfBirthGuess: `${y - 1}-${m}-${d}T00:00:00`,
        InvalidCombinationsPlan: [
          {
            DeductibleId: 9,
            ReimbursementId: 1,
            PMSCoverageLimitId: 11,
          },
          {
            DeductibleId: 4,
            ReimbursementId: 1,
            PMSCoverageLimitId: 11,
          },
          {
            DeductibleId: 8,
            ReimbursementId: 1,
            PMSCoverageLimitId: 11,
          },
          {
            DeductibleId: 2,
            ReimbursementId: 1,
            PMSCoverageLimitId: 11,
          },
        ],
        SelectedPlanId: selectedValues.selectedPlan,
        SelectedPlan: plans[selectedValues.selectedPlanIndex]
          .name.toUpperCase(),
        MostPopularPlan: 'PREFERRED',
        MonthlyCost: selectedValues.monthlyAmountPlan,
        AnnualPremium: selectedValues.annualAmountPlan,
        PolicyNumber: null,
        DiamondPolicyId: 0,
        ImportantMessage: null,
        IsAnnual: !selectedValues.isMonthlyPlan,
        WebPreSelectedPlan: null,
        WebPreSelectedReimbursement:
          selectedValues.reimbursementPercentage.replace('%', ''),
        WebPreSelectedDeductible:
          selectedValues.deductibleQuantity.replace('$', ''),
        IsExamFeesAllowed: true,
        IsMilitaryAllowed: true,
        IsVetShelterHumaneDiscountAllowed: true,
        IsHumaneDiscountAllowed: true,
        TotalDue: selectedValues.totalAnnual,
        TotalDueDollars: parseInt(annualDollar, 10),
        TotalDueCents: parseInt(annnualCents || 0, 10),
        MonthlyCostDollars: parseInt(monthlyDollar, 10),
        MonthlyCostCents: parseInt(monthlyCents || 0, 10),
        SelectedDeductibleDscr: selectedValues.deductibleQuantity,
        SelectedReimbursementDscr: selectedValues.reimbursementPercentage,
        SelectedModifiers: allModifiers,
      });
    });

    return insurancePetQuotes;
  }, [data, getMultiplePetId, getSelectedValues, newCustomer, plans]);

  const doPurchase = useCallback(({ isRetryPurchase = false } = {}) => {
    if (store.purchase.loading) {
      return;
    }

    const insurancePetQuotes = getInsurancePetQuotes();
    const multiPetDiscount = data?.ebPetQuoteResponseList[0].InsuranceModifiersEB
      .find((modifier) => modifier.PMSModifierId === MULTIPET_DISCOUNT && modifier.IsSelected);

    if (!individualPurchases) {
      // FF turn off
      dispatch(startPurchase());
      const quoteRequestBase = getQuoteRequestBase({
        isRetryPurchase,
        insurancePetQuotes,
        includeMultiPetDiscount: !!multiPetDiscount,
      });

      dispatch(purchaseQuotes({ body: quoteRequestBase, clientId: clientIdToSend }));

      return;
    }

    if (insurancePetQuotes.length === 1) {
      dispatch(startPurchase());
      const quoteRequestBase = getQuoteRequestBase({
        isRetryPurchase,
        insurancePetQuotes,
        includeMultiPetDiscount: !!multiPetDiscount,
      });

      dispatch(purchaseQuotes({ body: quoteRequestBase, clientId: clientIdToSend }));
    } else {
      const multipleQuoteRequest = insurancePetQuotes.map((petQuote) => getQuoteRequestBase({
        isRetryPurchase,
        insurancePetQuotes: [petQuote],
        includeMultiPetDiscount: !!multiPetDiscount,
      }));

      dispatch(doPurchaseMultiple({
        quoteRequests: multipleQuoteRequest,
        clientId: clientIdToSend,
      }));
    }
  }, [
    dispatch,
    clientIdToSend,
    getInsurancePetQuotes,
    getQuoteRequestBase,
    individualPurchases,
    store.purchase.loading,
  ]);

  return { doPurchase };
};
