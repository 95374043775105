import { useCallback, useEffect, useState } from 'react';

const useResizeScreen = () => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = useCallback(() => {
    setScreenHeight(window.innerHeight);
    setScreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize',
      handleResize);

    return () => {
      window.removeEventListener('resize',
        handleResize);
    };
  }, [handleResize]);

  return ({
    screenHeight,
    screenWidth,
  });
};

export { useResizeScreen };
