import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getFeatureFlags } from '../actions';
import { FigoLoadingOverlay } from '../components/common/FigoLoadingOverlay';

const FeatureFlagContext = createContext();

export const FeatureFlagProvider = ({ children }) => {
  const {
    flags,
    loading,
  } = useSelector(({ settings }) => settings.featureFlags, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeatureFlags());
  }, [dispatch]);

  return (
    <FeatureFlagContext.Provider value={flags}>
      {loading ? <FigoLoadingOverlay visible /> : null}
      {children}
    </FeatureFlagContext.Provider>
  );
};

const useFeatureFlag = (featureKey) => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw Error('Feature Flag Context not found');
  }

  const featureFlags = context;
  const featureFlag = useMemo(
    () => !!featureFlags[featureKey],
    [featureFlags, featureKey],
  );
  return featureFlag;
};

const useShowFF = () => useFeatureFlag('QuoteSitesShowFF');
const useBeaconSalesforce = () => useFeatureFlag('QuoteSiteEBMCPBeacon');
const useIndividualPurchases =
  () => useFeatureFlag('QuoteSiteEBIndividualPurchases');
const useRedesign =
  () => useFeatureFlag('QuoteSiteEBRedesignE156301');

export {
  useBeaconSalesforce,
  useIndividualPurchases,
  useShowFF,
  useRedesign,
};
