import React from 'react';
import './Footer.css';

const Footer = ({ className = '' }) => (
  <div className={`Footer-Copyright ${className}`}>
    <span>
      Copyright © 2015–{new Date().getFullYear()}&nbsp;
      Figo Pet insurance LLC. All rights reserved.
    </span>
  </div>
);

export default Footer;
