import React from 'react';

import errorIcon from '../../../assets/purchase/error.svg';

import './PetPurchaseResult.css';

const PetPurchaseFailureCard = ({ error, petIcon, petName, t }) => (
  <div className="Pet-purchase-result-failure-card Pet-purchase-result-error">
    <div className={'Pet-purchase-result-summary'
      + `${error ? '' : ' Pet-purchase-result-summary-no-error '}`}
    >
      <div className="Pet-flex">
        <img alt="" aria-hidden src={petIcon} />

        <div className="Pet-purchase-result-failure-card-margin">
          <span className="Pet-purchase-result-name">{petName}</span>
        </div>
      </div>

      <div
        className={'Pet-purchase-result-description-container'
          + ' Pet-purchase-result-top'}
      >
        <img alt="" aria-hidden src={errorIcon} />

        <span className="Pet-purchase-result-description">
          <span className="Pet-purchase-result-description-bold">
            {t('purchaseError')}{`${error ? ': ' : ''}`}
          </span>

          {error}
        </span>
      </div>
    </div>
  </div>
);

export { PetPurchaseFailureCard };
