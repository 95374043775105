import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import {
  loadQuoteData,
  loadQuoteDataByStorage,
  saveURLParameters,
} from './actions';
import { QuotingHeader } from './components/quoting/header/QuotingHeader';
import QuotingFooter from './components/quoting/QuotingFooter';
import PageNotFound from './components/pageNotFound/PageNotFound';
import './App.css';
import StorageProvider from './components/StorageProvider';
import { formatParameters, getSessionStorage } from './util';
import { useGTM } from './hooks';
import OpenQuote from './components/quoting/OpenQuote';
import { LandingPage } from './components/landing';
import GenericErrorModalContainer
from './components/common/GenericErrorModalContainer';
import PurchaseError from './components/quoting/purchase/PurchaseError';
import PurchaseSuccess from './components/quoting/purchase/PurchaseSuccess';
import Logout from './components/common/Logout';
import { PageTabTitle } from './components/common/PageTabTitle';
import {
  LANDING_URL,
  URL_LIVE_VET_YOUR_INFO,
  URL_LOGIN_ACCOUNT,
  URL_LOGOUT,
  URL_OPEN_QUOTE_ERROR,
  URL_OPEN_QUOTE,
  URL_PURCHASE_ERROR,
  URL_PURCHASE,
  URL_ROOT_QUOTE,
  URL_YOUR_INFO,
  URL_PURCHASE_RETRY,
  URL_PURCHASE_SUCCESS_ERROR,
} from './routes';
import { SavedQuoteExpired } from './components/saveQuote/SaveQuoteExpired';
import { LoginAccountScreen } from './components/login/LoginAccountScreen';
import {
  PurchaseRetryScreen,
} from './components/quoting/purchase/PurchaseRetryScreen';
import {
  StickyBarHeightProvider,
} from './components/landing/StickyBarHeightProvider';
import {
  ModalMultiQuoteLoading,
} from './components/quoting/purchase/ModalMultiQuoteLoading';

const URLS_VALID = [
  URL_LIVE_VET_YOUR_INFO,
  URL_LOGIN_ACCOUNT,
  URL_PURCHASE,
  URL_PURCHASE_RETRY,
  URL_ROOT_QUOTE,
  URL_YOUR_INFO,
];

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  useGTM();
  const parametersRaw = queryString.parse(location.search);
  const parameters = formatParameters(parametersRaw);

  function isMounted() {
    const storageMounted = getSessionStorage('store.mounted');

    return !!storageMounted;
  }

  function validParams() {
    if (URLS_VALID.includes(location.pathname) && isMounted()) {
      return true;
    }

    return parameters && parameters.empGuid
      && parameters.petName && parameters.petType && parameters.petSex
      && parameters.petBreed && parameters.petAge && parameters.petZipCode
      && parameters.email;
  }

  function isValidUrl() {
    return URLS_VALID.includes(location.pathname.toLowerCase());
  }

  function validLoadCache({ storageData }) {
    if (
      storageData
      && location.search
      && storageData.searchParameters === location.search
    ) {
      return true;
    }

    return URLS_VALID.includes(location.pathname) && isMounted();
  }

  if (isValidUrl()) {
    if (validParams()) {
      const storageData = getSessionStorage('store.quoting');

      if (validLoadCache({ storageData })) {
        // Reset some props state to default
        const state = {
          ...storageData,
          oneInc: {
            ...storageData.oneInc,
            portalOneSessionKey: '',
          },
          powerUpLoadings: [],
          sessionInformation: {
            ...storageData.sessionInformation,
            loadingLogin: false,
            showLoginMenssage: false,
            showWelcomeMessage: false,
          },
        };

        dispatch(loadQuoteDataByStorage(state));
      } else {
        dispatch(loadQuoteData({ parameters }));
        dispatch(saveURLParameters({ parameters, search: location.search }));
      }
    } else {
      // dispatch(rateQuoteError('Cannot load quote. '
      //  + 'Missing/Incorrect URL parameters.'));
    }
  }

  const isLocalHost = Boolean(
    window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.0/8 are considered localhost for IPv4.
    || window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
  );

  return (
    <>
      <PageTabTitle />

      <Switch>
        <Route exact path="/">
          <Redirect
            to={isLocalHost ? '/getQuote?EmpId=a2bf4d7f1c0362c7' : LANDING_URL}
          />
        </Route>

        <Route exact path={URL_LOGIN_ACCOUNT}>
          <LoginAccountScreen />
        </Route>

        <Route exact path={URL_LOGOUT}>
          <Logout />
        </Route>

        <Route path={URL_ROOT_QUOTE}>
          <div className="App">
            <StickyBarHeightProvider>
              <QuotingHeader />

              <QuotingFooter />
            </StickyBarHeightProvider>
          </div>
        </Route>

        <Route path={URL_PURCHASE}>
          <PurchaseSuccess />
        </Route>

        <Route exact path={`${URL_PURCHASE_ERROR}/:liveVet?`}>
          <PurchaseError />
        </Route>

        <Route path={URL_PURCHASE_SUCCESS_ERROR}>
          <PurchaseSuccess />
        </Route>

        <Route path={URL_PURCHASE_RETRY}>
          <PurchaseRetryScreen />
        </Route>

        <Route path={LANDING_URL}>
          <LandingPage />
        </Route>

        <Route exact path={URL_OPEN_QUOTE}>
          <OpenQuote />
        </Route>

        <Route path={URL_OPEN_QUOTE_ERROR}>
          <SavedQuoteExpired />
        </Route>

        <Route component={PageNotFound}>
          <PageNotFound />
        </Route>
      </Switch>

      <ModalMultiQuoteLoading />

      <StorageProvider />

      <GenericErrorModalContainer />
    </>
  );
}

export default App;
