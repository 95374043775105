import React from 'react';

import CardPlanMain from './CardPlanMain';
import { QuestionsSection } from './QuestionsSection';

import './QuoteContent.css';

const QuoteContent = () => (
  <div className="Quote-content">
    <CardPlanMain />

    <QuestionsSection isQuote />
  </div>
);

export { QuoteContent };
