import React from 'react';
import './loading.css';

const Loading = ({ border = '8px', color = '#2fb3b4', size = '64px' }) => {
  const style = {
    border: `${border} solid ${color}`,
    borderColor: `${color} transparent transparent transparent`,
    height: size,
    width: size,
  };

  return (
    <div className="lds-ring">
      <div style={style} />

      <div style={style} />

      <div style={style} />

      <div style={style} />
    </div>
  );
};

export default Loading;
