import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import arrowRightIcon from '../../assets/arrow-right-citron.svg';
import ill14 from '../../assets/ills/ill-14.svg';
import {
  diamondClientEmailClean,
  forgetPasswordVisibleChanged,
  handleLiveVetSubscription,
} from '../../actions';
import { purchaseConfiguration } from '../../Purchase.config';
import { phoneMask } from '../../util';
import { PetType, PetGender, TAG_COLOR } from '../../constants';
import { useResponsive } from '../../hooks';
import { URL_PURCHASE_ERROR } from '../../routes';
import { LiveVetContext } from '../contexts/LiveVetContext';

import ModalForgetPassword from '../common/ModalForgetPassword';
import ModalForgetPasswordConfirm from '../common/ModalForgetPasswordConfirm';
import ModalCustomerWelcome from '../common/ModalCustomerWelcome';
import CardPlanMainHeaderCompany from '../quoting/CardPlanMainHeaderCompany';
import LiveVetPetParentInfo from './LiveVetPetParentInfo';
import LiveVetNewCustomer from './LiveVetNewCustomer';
import { ModalHasLiveVetSubscription } from './ModalHasLiveVetSubscription';
import ImportantDates from './LiveVetImportantDates';
import './LiveVetInfo.css';

const LiveVetInfoScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const context = useContext(LiveVetContext);
  const store = useSelector(({ quoting }) => quoting);
  const {
    data,
    employer,
    employerId,
    liveVetSubscription,
    newCustomer,
    plans,
    sessionInformation,
    liveVetData,
    validatingLiveVetAccess,
    liveVetValidations: { petParentIsValid, newCustomerIsValid },
  } = store;
  const [, setShowModalLogin] = useState(false);
  const { isMobile } = useResponsive();
  const { insuranceProductEB, ebPetQuoteResponseList } = data;
  const { address } = newCustomer;
  const { InsuranceProductFeeEB, SelectedStateFactorEB } = insuranceProductEB;
  const { isCustomer, nopCommerceUser } = sessionInformation;

  const shouldBeModalWelcome = location.state
    ? !location.state.isCustomerLogged : true;

  const shouldbeShowModalLogin = location.state
    ? location.state.isNewUser : false;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (liveVetSubscription.purchaseError) {
      history.push(`${URL_PURCHASE_ERROR}/${true}`);
    }
  }, [history, liveVetSubscription.purchaseError]);

  useEffect(() => {
    if (isCustomer && !nopCommerceUser.LoginSuccess) {
      setShowModalLogin(true);
      dispatch(forgetPasswordVisibleChanged({ visible: false }));
    } else if (shouldbeShowModalLogin
      && isCustomer
      && !sessionInformation.loadingLogin
      && !nopCommerceUser.LoginSuccess) {
      dispatch(diamondClientEmailClean());

      setShowModalLogin(false);
    }
  }, [
    dispatch,
    isCustomer,
    nopCommerceUser,
    shouldbeShowModalLogin,
    sessionInformation.loadingLogin,
  ]);

  function getPetTag(quoteId) {
    const { petTags } = store;

    return petTags.find((item) => item.petQuoteId === quoteId) || {};
  }

  function getSelectedValues(quoteId) {
    const { quoteSelectedValues } = store;

    return quoteSelectedValues.find((item) => item.quoteId === quoteId) || {};
  }

  function onForgetModalsClose() {
    setShowModalLogin(true);
  }

  function handleContinuePaymentMethod() {
    const { newCustomerValue, petParentValue } = context;

    if (!petParentIsValid) {
      petParentValue.forcePetParentValidation();

      return;
    }

    if (!newCustomerIsValid && !nopCommerceUser.LoginSuccess) {
      newCustomerValue.forceNewCustomerValidation();

      return;
    }

    const purchase = {
      ...purchaseConfiguration,
      EmployeeId: newCustomer.employeeID,
      EmployerGuid: employerId,
      EmployerId: employer.id || 1,
      LoginPassword: isCustomer ? newCustomer.password : '',
      PetAddressCity: address.city,
      PetAddressLine1: address.street,
      PetAddressLine2: address.street2,
      PetAddressState: address.stateAbbr,
      PetAddressZip: address.zipCode,
      PrimaryEMail: newCustomer.email,
      PrimaryEMailConfirm: newCustomer.email,
      PrimaryFirstName: newCustomer.name,
      PrimaryLastName: newCustomer.lastName,
      PrimaryMobilePhone: phoneMask(newCustomer.phoneNumber),
      PrimaryPassword: isCustomer ? '' : newCustomer.password,
      PrimaryPasswordConfirm: isCustomer ? '' : newCustomer.password,
      QuotePurchaseDto: {
        ...purchaseConfiguration.QuotePurchaseDto,
        EffectiveDate: null,
        Fees: InsuranceProductFeeEB.map((fee) => ({
          Amount: fee.InsuranceFeeEB.Amount,
          DisplayText: fee.InsuranceFeeEB.DisplayText,
          Name: fee.InsuranceFeeEB.DisplayName,
          TypeId: fee.InsuranceFeeEBId,
        })),
        InsurancePetQuotes: ebPetQuoteResponseList.map((petQuote) => {
          const { petQuoteId } = petQuote;
          const petTag = getPetTag(petQuoteId);
          const selectedValues = getSelectedValues(petQuoteId);
          const [m, d, y] = petTag.petDateOfBirth.split('/');
          const [annualDollar, annualCents] =
            String(selectedValues.totalAnnual).split('.');
          const [monthlyDollar, monthlyCents] =
            String(selectedValues.totalMontly).split('.');

          return {
            ...purchaseConfiguration.InsurancePetQuotes,
            AgeString: petQuote.petAgeName,
            AnnualPayPremium: selectedValues.annualAmountPlan,
            AnnualPremium: selectedValues.annualAmountPlan,
            BreedId: petQuote.breedId,
            BreedName: petQuote.breedName,
            CompanyId: SelectedStateFactorEB.PMSCompanyId,
            Coverages: insuranceProductEB.InsuranceProductCoveragesEB,
            DateOfBirthGuess: `${y}-${m}-${d}T00:00:00`,
            Deductibles: petQuote.Deductibles,
            IsAnnual: !selectedValues.isMonthlyPlan,
            IsCat: petQuote.petType === PetType.Cat.value,
            IsFemale: petQuote.gender === PetGender.Female.value,
            LobId: SelectedStateFactorEB.PMSLOBId,
            MonthlyCost: selectedValues.monthlyAmountPlan,
            MonthlyCostCents: parseInt(monthlyCents || 0, 10),
            MonthlyCostDollars: parseInt(monthlyDollar, 10),
            PetName: petQuote.petName,
            Plans: petQuote.Plans.map((plan) => ({
              ...plan,
              CoverageId: plan.Plan,
            })),
            Reimbursements: petQuote.Reimbursements,
            SelectedDeductibleDscr: selectedValues.deductibleQuantity,
            SelectedDeductibleId: selectedValues.deductibleId,
            SelectedModifiers: petQuote.modifiers || [],
            SelectedPlan: plans[selectedValues.selectedPlanIndex]
              .name.toUpperCase(),
            SelectedPlanId: selectedValues.selectedPlan,
            SelectedReimbursementDscr: selectedValues.reimbursementPercentage,
            SelectedReimbursementId: selectedValues.reimbursementId,
            State: address.stateAbbr,
            TagColorId: petTag.petTagColor === TAG_COLOR.mintColor ? 55 : 54,
            TotalDue: selectedValues.totalAnnual,
            TotalDueCents: parseInt(annualCents || 0, 10),
            TotalDueDollars: parseInt(annualDollar, 10),
            WebPreSelectedDeductible: selectedValues
              .deductibleQuantity.replace('$', ''),
            WebPreSelectedReimbursement: selectedValues
              .reimbursementPercentage.replace('%', ''),
            ZipCode: address.zipCode,
          };
        }),
        IsMultiplePets: ebPetQuoteResponseList.length > 0,
        PetState: address.stateAbbr,
        PetZip: address.zipCode,
        TestEffectiveDate: store.effectiveDateCustom,
      },
    };

    dispatch(handleLiveVetSubscription({ employeeGuid: employerId, purchase }));
  }

  return (
    <>
      {shouldBeModalWelcome && <ModalCustomerWelcome />}

      <ModalForgetPassword
        onModalClosed={onForgetModalsClose}
      />

      <ModalForgetPasswordConfirm
        onModalClosed={onForgetModalsClose}
      />

      <ModalHasLiveVetSubscription
        email={newCustomer.email}
        show={liveVetData?.HasAccess}
      />

      <div className="Your-info-ills-container Your-info-ills-justify">
        <div className="Your-info-ill-container">
          <img alt="" aria-hidden src={ill14} />
        </div>
      </div>

      <div className="Live-vet-info-main-container">
        {isMobile && (
          <CardPlanMainHeaderCompany />
        )}

        <LiveVetPetParentInfo />

        {!nopCommerceUser.LoginSuccess && <LiveVetNewCustomer />}

        <ImportantDates />

        <button
          aria-label="continue to payment method"
          className="Live-vet-purchase-button-container"
          disabled={validatingLiveVetAccess}
          onClick={handleContinuePaymentMethod}
          title="continue to payment method"
          type="button"
        >
          Continue to Payment Method

          <img
            alt="arrow right"
            className="Card-plan-footer-continue-button-icon"
            src={arrowRightIcon}
          />
        </button>
      </div>
    </>
  );
};

export default LiveVetInfoScreen;
