import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveMonthlyPlanSelected } from '../actions';

import { isPaycheck } from '../util';

const useCalculatePlanPrice = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ quoting }) => quoting);

  const {
    petQuoteSelected,
    quoteSelectedValues,
  } = store;

  const { petQuote, plans, plansPackaged } = useMemo(() => {
    if (!store.data) {
      return { plans: [], plansPackaged: store.plansPackaged };
    }

    const currentPetQuote = store.data.ebPetQuoteResponseList
      .find((item) => item.petQuoteId === petQuoteSelected);

    const plansQuote = currentPetQuote ? currentPetQuote.Plans : [];

    let plansPackagedSelected = [];

    if (currentPetQuote) {
      plansPackagedSelected = currentPetQuote.PrePackagedPlans?.map((item) => {
        const plan = currentPetQuote.Plans
          .find((planItem) => planItem.Plan === item.PlanId);
        const ratingOption = plan.RatingOptions
          .find((ratingItem) => ratingItem.DeductibleId
            === item.DeductibleId && ratingItem.ReimbursementId
            === item.ReimbursementId);
        return ({
          ...item,
          deductibleName: ratingOption.DeductibleName,
          maxAnnual: plan.MaxAnnual,
          monthlyPrice: ratingOption.MonthlyPremium,
          reimbursementName: ratingOption.ReimbursementName,
        });
      });
    }

    return {
      petQuote: currentPetQuote,
      plans: plansQuote,
      plansPackaged: plansPackagedSelected,
    };
  }, [petQuoteSelected, store.data, store.plansPackaged]);

  const quoteSelected = quoteSelectedValues
    .find((item) => item.quoteId === petQuoteSelected)
    || { petName: 'Pet Name' };

  const getCurrentPlan = useCallback(() => {
    const emptyPlan = {
      annualAmount: 0,
      getValue: () => 0,
      monthlyAmount: 0,
      partnerAnnualSaving: 0,
    };

    if (!petQuote) {
      return emptyPlan;
    }

    const currPlan = petQuote.Plans
      .find((plan) => plan.Plan === quoteSelected.selectedPlan);
    const price = currPlan.RatingOptions
      .find((rating) => rating.DeductibleId === quoteSelected.deductibleId
        && rating.ReimbursementId === quoteSelected.reimbursementId);

    if (!price) {
      return emptyPlan;
    }

    let getValue = () => price.MonthlyPremium;

    if (isPaycheck) {
      const priceAnnualFix = (price.MonthlyPremium * 12);
      getValue = () => (priceAnnualFix * 0.9) / 24;
    }

    return {
      annualAmount: price.AnnualPremium,
      getValue,
      monthlyAmount: isPaycheck ? getValue() : price.MonthlyPremium,
      partnerAnnualSaving: price.PartnerAnnualSaving,
    };
  }, [petQuote, quoteSelected]);

  useEffect(() => {
    const price = getCurrentPlan();

    if (quoteSelected.totalMontly !== undefined
      && quoteSelected.totalMontly !== price.getValue()) {
      dispatch(saveMonthlyPlanSelected(price));
    }
  }, [
    dispatch,
    getCurrentPlan,
    quoteSelected,
  ]);

  return {
    petQuote,
    plans,
    plansPackaged,
    quoteSelected,
  };
};

export { useCalculatePlanPrice };
