import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import { currencyFormat } from '../../../util';
import { BILLING_CYCLE_TYPE } from '../../../constants';
import calendarIcon from '../../../assets/yourInfo/calendar-icon.svg';

const YourFirstPayment = () => {
  const { t } = useTranslation('enrollment');
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const {
    data,
    isMonthlyPlan,
    paymentMethod: { paymentMethodType },
    rateSummary: { QuoteSummaries },
  } = store;
  const { effectiveDate } = data;
  const day = moment(effectiveDate).format('D');
  const ordinalDay = moment(effectiveDate).format('Do');

  function getTotal() {
    const billingCycle = isMonthlyPlan
      ? BILLING_CYCLE_TYPE.monthly : BILLING_CYCLE_TYPE.annualy;
    const paymentType = paymentMethodType.value;
    const summary = QuoteSummaries
      .find((item) => item.BillingCycle === billingCycle
        && item.PaymentType === paymentType)
      || { MonthlyPremiumTotal: 0, TodaysTotal: 0 };

    return {
      monthlyTotal: currencyFormat({ value: summary.MonthlyPremiumTotal }),
      todaysTotal: currencyFormat({ value: summary.TodaysTotal }),
    };
  }

  function getTotalText() {
    return isMonthlyPlan
      ? (
        <Trans
          components={{ b: <b /> }}
          i18nKey="todaysTotal.monthlyCharge"
          ns="enrollment"
          values={{
            amount: `$${getTotal().monthlyTotal}`,
            ordinalDay,
          }}
        />
      ) : t('todaysTotal.annualRenew');
  }

  function getCalendarClass() {
    let className = 'Your-info-calendar-number ';

    if (!isMonthlyPlan) {
      className += 'Your-info-calendar-three';
      return className;
    }

    className += day > 9
      ? 'Your-info-calendar-two' : 'Your-info-calendar-one';
    return className;
  }

  function getDaysCalendar() {
    return isMonthlyPlan ? day : 365;
  }

  return (
    <div className="Your-first-payment">
      <div className="Your-info-flex">
        <div aria-hidden="true" className="Your-info-calendar-container">
          <img alt="" src={calendarIcon} />

          <span className={getCalendarClass()}>
            {getDaysCalendar()}
          </span>
        </div>

        <p>
          {getTotalText()}
        </p>
      </div>

      <div className="Your-info-payment">
        <span>{t('todaysTotal.firstPayment')}</span>

        <span className="Your-info-total">${getTotal().todaysTotal}</span>
      </div>
    </div>
  );
};

export { YourFirstPayment };
