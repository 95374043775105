import React from 'react';
import { useTranslation } from 'react-i18next';

import { OneIncModal } from '../../common/OneIncModal';

import './PaymentMethod.css';

const PaymentMethod = ({
  containerRef = null,
  showError = false,
}) => {
  const { t } = useTranslation('enrollment');
  return (
    <div
      ref={containerRef}
      className="Payment-method-section"
      id="paymentMethod"
    >
      <h1 className="Payment-method-title">{t('paymentMethod.title')}</h1>

      <OneIncModal />

      {showError ? (
        <span className="Payment-method-error-label">
          {t('paymentMethod.required')}
        </span>
      ) : null}
    </div>
  );
};

export { PaymentMethod };
