import React, { useState, useEffect, useRef, useMemo } from 'react';
import { removeEmojis } from '../../util';
import arrowDown from '../../assets/arrow-down-gray.svg';
import ScrollableDiv from './ScrollableDiv';
import TouchableDiv from './TouchableDiv';
import { DropdownItemList } from './DropdownItemList';
import './Dropdown.css';
import { BROWSERS, useGetNavigator } from '../../hooks/useGetNavigator';

const SPACE_KEY = 32;
const DOWN_KEY = 40;
const LEFT_KEY = 37;
const RIGHT_KEY = 39;
const UP_KEY = 38;
const INPUT_NODE = 'INPUT';

const IGNORED_KEYS_BASE = [DOWN_KEY, LEFT_KEY, RIGHT_KEY, UP_KEY];

function handleKeyDown(event) {
  const ignoredKeys = [...IGNORED_KEYS_BASE];
  if (event.target.nodeName !== INPUT_NODE) {
    ignoredKeys.push(SPACE_KEY);
  }
  if (ignoredKeys.indexOf(event.keyCode) > -1) {
    event.preventDefault();
  }
}

const Dropdown = ({
  ariaLabelledBy = '',
  containerClassName = '',
  disableArrowAnimation = false,
  dropdownIcon,
  error = '',
  headerClassName = '',
  idPostfix = '',
  items = [],
  listClassName = '',
  noFoundItemsText = 'No matches found',
  onBlur = () => { },
  onFocus = () => { },
  onItemSelected = () => { },
  placeholder = '',
  title = '',
  valueSelected = { label: '', value: '' },
  withInput = false,
}) => {
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [itemSelectedId, setItemSelectedId] = useState(() => (valueSelected
    ? valueSelected.value : 1));
  const searchRef = useRef();
  const dropdownHeaderRef = useRef();
  const containerItemRef = useRef();
  const selectedItemRef = useRef(null);
  const pressedKeys = useRef('');
  const browser = useGetNavigator();

  const currentSelection = useMemo(() => valueSelected.label
    || 'None', [valueSelected]);

  useEffect(() => {
    setItemsFiltered(items);
  }, [items]);

  function setActiveDescendant(currentRef) {
    if (currentRef && selectedItemRef.current) {
      const selectedItemId = selectedItemRef.current.getAttribute('id');
      currentRef.setAttribute('aria-activedescendant', selectedItemId);
    }
  }

  useEffect(() => {
    const { current: dropdownCurrent } = dropdownHeaderRef;
    const { current: inputCurrent } = searchRef;
    if (open) {
      if (inputCurrent) {
        inputCurrent.focus();
        setActiveDescendant(inputCurrent);
      }

      window.addEventListener('keydown', handleKeyDown, true);
    }

    return () => {
      if (open) {
        window.removeEventListener('keydown', handleKeyDown, true);
        dropdownCurrent?.focus();
        pressedKeys.current = '';
      }
    };
  }, [dropdownHeaderRef, open]);

  useEffect(() => {
    if (withInput) {
      setSearchInput('');
    }
  }, [withInput]);

  function scrollItemListToView() {
    if (selectedItemRef.current) {
      containerItemRef.current.scrollTo(
        0, selectedItemRef.current.offsetTop - 5,
      );
    }
  }

  useEffect(() => {
    scrollItemListToView();
    setActiveDescendant(!withInput
      ? dropdownHeaderRef.current
      : searchRef.current);
  }, [dropdownHeaderRef, withInput, itemSelectedId]);

  async function filterItems(inputSearch) {
    const dataFiltered =
      items.filter((element) => element.label
        .toUpperCase().includes(inputSearch.toUpperCase()));

    setItemsFiltered(dataFiltered);

    if (dataFiltered[0]) {
      await setItemSelectedId(dataFiltered[0].value);
      if (containerItemRef.current) {
        containerItemRef.current.scrollTo(0, 0);
        scrollItemListToView();
      }
    }
  }

  function toggle() {
    setOpen(!open);
  }

  function handleDropdownClick(event) {
    if (event.target.id === `dropdown${idPostfix}`) {
      toggle();
    }
  }

  const handleOnClickItem = (item) => (event) => {
    if (item.disabled) {
      return;
    }

    if (event && event.type === 'click') {
      toggle();
    }

    setItemSelectedId(item.value);
    onItemSelected(item);
  };

  const handleOnKeyPressItem = (item) => (event) => {
    const { key } = event;
    if (key === 'Enter' || key === ' ') {
      handleOnClickItem(item)();
    }
  };

  function handleSearchInputChanged(event) {
    const cleanValue = removeEmojis(event.target.value);
    setSearchInput(cleanValue);
    if (withInput) {
      filterItems(cleanValue.trim());
      scrollItemListToView();
    }
  }

  function handleArrowKeyPressed(event) {
    const { keyCode, key } = event;
    const ENTER_KEY = 13;
    const ESC_KEY = 27;
    const TAB_KEY = 9;

    switch (keyCode) {
      case UP_KEY:
      case DOWN_KEY: {
        if (!open) {
          event.preventDefault();

          toggle();
          break;
        }

        const factor = keyCode === UP_KEY ? -1 : 1;
        const temp = itemsFiltered
          .findIndex((element) => element.value === itemSelectedId);
        if (itemsFiltered[temp + factor]) {
          const newId = itemsFiltered[temp + factor].value;
          setItemSelectedId(newId);
        }

        break;
      }

      case ENTER_KEY: {
        const item = itemsFiltered
          .find((element) => element.value === itemSelectedId);

        if (item && open) {
          handleOnClickItem(item)();
        }
        toggle();

        break;
      }

      case SPACE_KEY: {
        if (!withInput) {
          toggle();
          event.preventDefault();
        }
        break;
      }

      case ESC_KEY: {
        setOpen(false);
        if (dropdownHeaderRef.current) {
          dropdownHeaderRef.current.focus();
        }

        setItemSelectedId(valueSelected.value);
        break;
      }

      case TAB_KEY: {
        setOpen(false);

        break;
      }

      default: {
        if (open) {
          pressedKeys.current = pressedKeys.current.length >= 2
            ? key
            : pressedKeys.current + key;
          const newItem = itemsFiltered.find((item) => String(item.label)
            .toUpperCase().startsWith(pressedKeys.current.toUpperCase()));
          if (newItem) {
            setItemSelectedId(newItem.value);
          }
        }
        break;
      }
    }
  }

  function handleOutsideClick() {
    setOpen(false);
  }

  function handleInputFocus() {
    setOpen(true);
  }

  function renderSearchInput(valueToShow) {
    const isPlaceHolder = valueToShow === placeholder;

    return (
      <input
        ref={searchRef}
        aria-activedescendant=""
        aria-autocomplete="list"
        aria-controls="dd-options"
        aria-expanded={open}
        aria-haspopup="listbox"
        autoComplete="off"
        className="Dropdown-common-search-input"
        id={`${idPostfix}Dropdown`}
        onChange={handleSearchInputChanged}
        onClick={handleDropdownClick}
        onFocus={handleInputFocus}
        onKeyDown={handleArrowKeyPressed}
        placeholder={`${open ? 'Search' : title}`}
        role="combobox"
        style={{ color: isPlaceHolder ? '#6d7579' : '#3d484d' }}
        value={valueToShow || searchInput}
      />
    );
  }

  function renderHeaderDropdown() {
    if (!open) {
      let className = 'Dropdown-common-selected-item-placeholder';
      let valueToShow = placeholder || title;

      if (valueSelected.label) {
        className = 'Dropdown-common-selected-item-text';
        valueToShow = valueSelected.label;
      }

      if (withInput) {
        return renderSearchInput(valueToShow);
      }

      return <p className={className}>{valueToShow}</p>;
    }

    if (withInput) {
      return renderSearchInput();
    }

    return (
      <p>{valueSelected.label || title}</p>
    );
  }

  const isSelected = (item) => item.value === valueSelected.value;

  function getDropdownId(item) {
    return `dropdown-item-${idPostfix}-${item}`;
  }

  function renderItems() {
    const resultsNotFound = !itemsFiltered || itemsFiltered.length === 0;

    return (
      <ScrollableDiv
        ref={containerItemRef}
        ariaProps={{
          'aria-hidden': !open,
        }}
        className={`Dropdown-list ${!open ? 'Dropdown-list-hidden' : ''}`
          + ` ${listClassName}`}
      >
        <ul
          aria-labelledby={`dropdown${idPostfix}`}
          aria-live={resultsNotFound ? 'polite' : 'off'}
          id={`dd-options-${idPostfix}`}
          role="listbox"
        >
          {resultsNotFound ? (
            <li key="0" className="Dropdown-common-list-item-container">
              <span className="Dropdown-common-list-item">
                {noFoundItemsText}
              </span>
            </li>
          ) : itemsFiltered.map((item) => (
            <DropdownItemList
              key={item.value}
              ref={item.value === itemSelectedId ? selectedItemRef : null}
              disabled={item.disabled}
              id={getDropdownId(item.value)}
              item={item}
              listSize={itemsFiltered.length}
              onClick={handleOnClickItem(item)}
              onKeyDown={handleArrowKeyPressed}
              onKeyPress={handleOnKeyPressItem(item)}
              selected={isSelected(item)}
            />
          ))}
        </ul>
      </ScrollableDiv>
    );
  }

  function getAriaPropsForDiv() {
    let ariaProps = {
      'aria-expanded': open,
      'aria-labelledby': ariaLabelledBy,
      'aria-required': true,
      title: `${currentSelection} selected`,
    };

    if (!withInput) {
      if (browser === BROWSERS.safari) {
        ariaProps = {
          ...ariaProps,
          'aria-owns': `dd-options-${idPostfix}`,
        };
      }

      return {
        ...ariaProps,
        'aria-activedescendant': '',
        'aria-controls': `dd-options-${idPostfix}`,
        'aria-haspopup': 'listbox',
        role: 'combobox',
      };
    }
    return ariaProps;
  }

  return (
    <div
      className={'Dropdown-common-container '
        + ` ${containerClassName}`}
      data-open={open}
    >
      {open && (
        <TouchableDiv
          ariaProps={{
            'aria-hidden': 'true',
            tabIndex: -1,
          }}
          className="Dropdown-common-list-overlay-container"
          onClick={handleOutsideClick}
          onKeyDown={handleOutsideClick}
        />
      )}

      <div
        ref={dropdownHeaderRef}
        className={`Dropdown-common-header ${headerClassName}`}
        id={`dropdown${idPostfix}`}
        onBlur={onBlur}
        onClick={handleDropdownClick}
        onFocus={onFocus}
        onKeyDown={handleArrowKeyPressed}
        role="button"
        tabIndex={0}
        {...getAriaPropsForDiv()}
      >
        <div className="Dropdown-common-selected-item">
          {renderHeaderDropdown()}
        </div>

        <div
          className="Dropdown-common-arrow-container No-clicked"
          id="arrowContainer"
        >
          <img
            alt=""
            aria-hidden="true"
            className={'Dropdown-common-arrow-closed '
              + `${open && !disableArrowAnimation
                ? 'Dropdown-common-arrow-open' : ''}`}
            src={dropdownIcon || arrowDown}
          />
        </div>
      </div>

      {renderItems()}

      <span
        aria-label={error && error.replace('This', title)}
        aria-live="polite"
        className="Dropdown-common-header-error-message"
      >
        {error}
      </span>
    </div>
  );
};

export { Dropdown };
