import React from 'react';

import dogIcon from '../../../assets/dog-active.svg';
import dogInactiveIcon from '../../../assets/dog-inactive.svg';
import catIcon from '../../../assets/cat-active.svg';
import catInactiveIcon from '../../../assets/cat-inactive.svg';

import './ModalAddPet.css';

const SpecieSelectorItem = ({
  handleSpecieSelected = () => { },
  isSelected = false,
  label = '',
  petIcon = null,
  petInactiveIcon = null,
  petSpeciesRef = null,
}) => {
  function specieIcon() {
    if (isSelected) {
      return petIcon;
    }

    return petInactiveIcon;
  }

  return (
    <button
      ref={petSpeciesRef}
      aria-describedby="progressQueueId"
      aria-label={`Pet type ${label} ${isSelected ? 'selected' : 'unselected'}`}
      aria-live={isSelected ? 'assertive' : 'off'}
      className={`Modal-add-pet-specie-item-container${
        isSelected ? ' Modal-add-pet-specie-item-container-selected' : ''}`}
      onClick={handleSpecieSelected}
      type="button"
    >
      <img
        alt=""
        className="Modal-add-pet-specie-icon"
        src={specieIcon()}
      />
      {label}
    </button>
  );
};

const AddPetSpecieSelector = ({
  catSelected = {},
  dogSelected = {},
  dogSpeciesRef = null,
  error = '',
  handleCatSelected = () => { },
  handleDogSelected = () => { },
  InputError = () => { },
}) => (
  <div className="Modal-add-pet-species-wrapper">
    <span className="Modal-add-pet-species-title">Pet Type</span>

    <div className="Modal-add-pet-container-relative">
      <div
        aria-label="Pet type"
        className="Modal-add-pet-species-container"
        id="species-selector"
      >
        <SpecieSelectorItem
          handleSpecieSelected={handleDogSelected}
          isSelected={dogSelected.selected}
          label="Dog"
          petIcon={dogIcon}
          petInactiveIcon={dogInactiveIcon}
          petSpeciesRef={dogSpeciesRef}
        />

        <SpecieSelectorItem
          handleSpecieSelected={handleCatSelected}
          isSelected={catSelected.selected}
          label="Cat"
          petIcon={catIcon}
          petInactiveIcon={catInactiveIcon}
        />
      </div>

      <InputError
        className="Modal-add-pet-align-self-start"
        error={error}
      />
    </div>
  </div>
);

export { AddPetSpecieSelector };
