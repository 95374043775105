import {
  PowerUpsRelation,
  POWERUP_TYPE,
  BENEFIT_FEE_TYPE,
} from '../../../constants';
import { isPaycheck, isProductFPI } from '../../../util';

const { WELLNESS_PSM_ID } = PowerUpsRelation;

export function toTruncate(value) {
  return Math.trunc((value * 100)) / 100;
}

export function shouldDisplayInfoImage(powerUp) {
  return powerUp.PMSModifierId === WELLNESS_PSM_ID;
}

export function isWellnessFPI({ insuranceProductId, powerUp }) {
  return powerUp?.PMSModifierId === WELLNESS_PSM_ID
    && isProductFPI(insuranceProductId);
}

export function findSelectedModifier({ id, modifiers = [] }) {
  const contentModifierSelected = modifiers
    .find((element) => element.id === id);

  const isChecked = contentModifierSelected
    ? contentModifierSelected.isSelected : null;

  return isChecked;
}

export function getFeeAmountChildren({ benefitType, modifiers }) {
  return modifiers
    .filter((item) => item.InsuranceModifierTypeEBId === POWERUP_TYPE
      && item.BenefitFeeType === benefitType)
    .map((element) => {
      if (element.BundleInsuranceModifiersEB) {
        return element.BundleInsuranceModifiersEB
          .filter((filter) => filter.IsSelected)
          .reduce((prev, next) => prev + next.FeeAmount, 0);
      }

      return 0;
    }).reduce((prev, next) => prev + next, 0);
}

function getCoverageFee({ data, petQuoteSelected }) {
  const { ebPetQuoteResponseList } = data;

  const quote = ebPetQuoteResponseList
    .find((item) => item.petQuoteId === petQuoteSelected)
    || { modifier: [] };
  const insuranceModifiersEB = quote.InsuranceModifiersEB;

  const modifiers = quote.modifiers
    ? quote.modifiers.filter((modifier) => modifier.isSelected)
    : [];
  const modifierIds = modifiers.map((modifier) => modifier.id);

  const powerUpsHidden = insuranceModifiersEB
    .filter((modifier) => modifier.InsuranceModifierTypeEBId === POWERUP_TYPE
      && modifier.IsSelected && !modifier.IsVisible);
  const powerUpsSelected = insuranceModifiersEB
    .filter((modifier) => modifierIds.includes(modifier.Id));
  const powerUps = [...powerUpsHidden, ...powerUpsSelected];

  const feeFactor = powerUps
    .filter((item) => item.BenefitFeeType === BENEFIT_FEE_TYPE.ftpFactor)
    .reduce((a, b) => a + b.FeeAmount, 0);
  const feeFixed = powerUps
    .filter((item) => item.BenefitFeeType === BENEFIT_FEE_TYPE.fixedFee
      || item.BenefitFeeType === BENEFIT_FEE_TYPE.dynamic)
    .reduce((a, b) => a + b.FeeAmount, 0);

  const feeFactorChildren = getFeeAmountChildren({
    benefitType: BENEFIT_FEE_TYPE.ftpFactor,
    modifiers: powerUps,
  });

  const feeFixedChildren = getFeeAmountChildren({
    benefitType: BENEFIT_FEE_TYPE.fixedFee,
    modifiers: powerUps,
  });

  return {
    feeFactor: feeFactor + feeFactorChildren,
    feeFixed: feeFixed + feeFixedChildren,
  };
}

function getPriceWithoutFees({ data, petQuoteSelected, quoteSelected }) {
  const { annualAmountPlan, monthlyAmountPlan } = quoteSelected;
  const coverageFeeValue = getCoverageFee({ data, petQuoteSelected });
  const pricePaycheck = (annualAmountPlan * 0.9) / 24;
  const price = isPaycheck ? pricePaycheck : monthlyAmountPlan;
  const priceWithoutFees = (price - coverageFeeValue.feeFixed)
    / (1 + coverageFeeValue.feeFactor);

  return priceWithoutFees;
}

function getCoverageByBenefitFeeType({
  data,
  petQuoteSelected,
  powerUp,
  quoteSelected,
}) {
  const { BenefitFeeType, FeeAmount } = powerUp;
  const priceWithoutFees = getPriceWithoutFees({
    data,
    petQuoteSelected,
    quoteSelected,
  });

  if (BenefitFeeType === BENEFIT_FEE_TYPE.ftpFactor) {
    const coverageFee = FeeAmount * priceWithoutFees;

    return coverageFee;
  }

  return FeeAmount;
}

export function calculateCoverageFee({
  data,
  petQuote,
  petQuoteSelected,
  powerUp,
  quoteSelected,
}) {
  if (isWellnessFPI({
    insuranceProductId: data.insuranceProductEB.Id,
    powerUp,
  })) {
    const wellnessArrayValues =
      powerUp.BundleInsuranceModifiersEB
        .map((item) => {
          const contentModifierSelected = (petQuote.modifiers || [])
            .find((element) => element.id === item.Id);
          const isSelected = contentModifierSelected
            ? contentModifierSelected.isSelected : false;

          return isSelected && item.FeeAmount
            ? item.FeeAmount : 0;
        });

    const wellnessPrice = wellnessArrayValues
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      || powerUp.BundleInsuranceModifiersEB[0].FeeAmount;

    return toTruncate(wellnessPrice).toFixed(2);
  }

  const coverageFee = getCoverageByBenefitFeeType({
    data,
    petQuoteSelected,
    powerUp,
    quoteSelected,
  });
  return toTruncate(coverageFee).toFixed(2);
}
