import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  NEW_CUSTOMER_CITY_CHANGED,
  NEW_CUSTOMER_EMPLOYEE_ID_CHANGED,
  NEW_CUSTOMER_LAST_NAME_CHANGED,
  NEW_CUSTOMER_NAME_CHANGED,
  NEW_CUSTOMER_PHONE_CHANGED,
  NEW_CUSTOMER_SECOND_PARENT_EMAIL_CHANGED,
  NEW_CUSTOMER_SECOND_PARENT_LAST_NAME_CHANGED,
  NEW_CUSTOMER_SECOND_PARENT_NAME_CHANGED,
  NEW_CUSTOMER_SECOND_PARENT_NUMBER_CHANGED,
  NEW_CUSTOMER_STATE_CHANGED,
  NEW_CUSTOMER_STREET_CHANGED,
  NEW_CUSTOMER_STREET2_CHANGED,
  NEW_CUSTOMER_ZIP_CODE_CHANGED,
} from '../../actions/types';
import {
  changeIsPetParentValid,
  loadAddressByZipCode,
  newCustomerChanged,
  setCurrentQuoteStep,
  toggleYourInfoScrollToTop,
} from '../../actions';
import TouchableDiv from '../common/TouchableDiv';
import Shimmer from '../common/Shimmer';
import IconTooltip from '../common/IconTooltip';
import lockIcon from '../../assets/lock-icon.svg';
import './LiveVetPetParentInfo.css';
import { QuotingSteps } from '../../constants';
import showPasswordIcon from '../../assets/yourInfo/show-password.svg';
import showingPasswordIcon from '../../assets/yourInfo/showing-password.svg';
import {
  isValidDiamondStreet,
  scrollToElement,
  testContainNumber,
  testOnlyNumber,
} from '../../util';
import { LiveVetContext } from '../contexts/LiveVetContext';

const TYPES = {
  employeeID: NEW_CUSTOMER_EMPLOYEE_ID_CHANGED,
  petParenStreetAddress: NEW_CUSTOMER_STREET_CHANGED,
  petParenStreetAddress2: NEW_CUSTOMER_STREET2_CHANGED,
  petParentCity: NEW_CUSTOMER_CITY_CHANGED,
  petParentFirstName: NEW_CUSTOMER_NAME_CHANGED,
  petParentLastName: NEW_CUSTOMER_LAST_NAME_CHANGED,
  petParentPhoneNumber: NEW_CUSTOMER_PHONE_CHANGED,
  petParentState: NEW_CUSTOMER_STATE_CHANGED,
  petParentZipCode: NEW_CUSTOMER_ZIP_CODE_CHANGED,
  secondaryPetParentEmail: NEW_CUSTOMER_SECOND_PARENT_EMAIL_CHANGED,
  secondaryPetParentLastName: NEW_CUSTOMER_SECOND_PARENT_LAST_NAME_CHANGED,
  secondaryPetParentMobilePhone: NEW_CUSTOMER_SECOND_PARENT_NUMBER_CHANGED,
  secondaryPetParentName: NEW_CUSTOMER_SECOND_PARENT_NAME_CHANGED,
};

const ERROR_INPUT = 'This is a required field.';
const TEN_DIGIT_NUMBER = /^\d{10}$/;

const LiveVetPetParentInfo = () => {
  const store = useSelector(({ quoting }) => (quoting), shallowEqual);
  const dispatch = useDispatch();
  const { petParentValue } = useContext(LiveVetContext);
  const { newCustomer, sessionInformation: { nopCommerceUser } } = store;
  const [isMounted, setIsMounted] = useState(false);
  const [nameError, setNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [streetError, setStreetError] = useState('');
  const [cityError, setCityError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [employeeError, setEmployeeError] = useState('');
  const [showEmployeeID, setShowEmployeeID] = useState(false);

  const isPetParentValid = useMemo(() => (
    !!newCustomer.name.trim().length
    && !!newCustomer.lastName.trim().length
    && !!newCustomer.address.city.trim().length
    && !!newCustomer.address.street.trim().length
    && isValidDiamondStreet(newCustomer.address.street)
    && TEN_DIGIT_NUMBER.test(newCustomer.phoneNumber)
    && !!newCustomer.employeeID.trim().length
  ), [newCustomer]);

  const isNopComercedDisabled = useMemo(() => nopCommerceUser.LoginSuccess
    && nopCommerceUser.FirstName, [nopCommerceUser]);

  const forcePetParentCallback = useCallback(() => {
    scrollToElement('pet-parent-section');

    if (!newCustomer.name.trim().length) {
      setNameError(ERROR_INPUT);
    }

    if (!newCustomer.lastName.trim().length) {
      setLastNameError(ERROR_INPUT);
    }

    if (!newCustomer.address.city.trim().length) {
      setCityError(ERROR_INPUT);
    }

    if (!newCustomer.address.street.trim().length
      || !isValidDiamondStreet(newCustomer.address.street)) {
      setStreetError(ERROR_INPUT);
    }

    if (!TEN_DIGIT_NUMBER.test(newCustomer.phoneNumber)) {
      setPhoneNumberError(ERROR_INPUT);
    }

    if (!newCustomer.employeeID.trim().length) {
      setEmployeeError(ERROR_INPUT);
    }
  }, [
    newCustomer.address.city,
    newCustomer.address.street,
    newCustomer.employeeID,
    newCustomer.lastName,
    newCustomer.name,
    newCustomer.phoneNumber,
  ]);

  useEffect(() => {
    petParentValue.setForcePetParent(forcePetParentCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcePetParentCallback]);

  useEffect(() => {
    dispatch(changeIsPetParentValid(isPetParentValid));
  }, [dispatch, isPetParentValid]);

  useEffect(() => {
    if (store.customerZipCode !== newCustomer.address.zipCode
      || !newCustomer.address.city) {
      dispatch(loadAddressByZipCode(store.customerZipCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    newCustomer.address.zipCode,
    store.customerZipCode,
  ]);

  useEffect(() => {
    if (store.scrollYourInfoToTop) {
      dispatch(toggleYourInfoScrollToTop(false));
      window.scrollTo(0, 0);
    }
  }, [dispatch, store.scrollYourInfoToTop]);

  useEffect(() => {
    if (!isMounted) {
      dispatch(setCurrentQuoteStep(QuotingSteps.YourInfo.value));
      setIsMounted(true);
    }
  }, [isMounted, dispatch]);

  const InputError = ({ error }) => (
    <span className="Live-vet-parent-label-error">
      {error}
    </span>
  );

  function onValueChanged(event) {
    const { id, value } = event.target;
    const containsNumber = testContainNumber(value);

    if (TYPES[id] === NEW_CUSTOMER_CITY_CHANGED && containsNumber && value) {
      return;
    }

    dispatch(newCustomerChanged({ type: TYPES[id], value }));
  }

  const onInputFocus = (callbackState) => () => {
    callbackState('');
  };

  const onRequiredInputBlur = (setErrorCallBack) => (event) => {
    const { value } = event.target;

    setErrorCallBack(value.trim() ? '' : 'This is a required field.');
  };

  function onStreetInputBlur(event) {
    const { value } = event.target;

    let error = value ? '' : 'This is a required field.';

    if (value && !isValidDiamondStreet(value)) {
      error = 'Invalid street address';
    }

    setStreetError(error);
  }

  function onStreetValueChanged(event) {
    const { id, value } = event.target;

    dispatch(newCustomerChanged({ type: TYPES[id], value }));

    setStreetError(isValidDiamondStreet(value)
      ? '' : 'Invalid street address');
  }

  function onPhoneNumberBlur(event) {
    const { value } = event.target;

    let error = value ? '' : 'This is a required field.';

    if (value) {
      error = TEN_DIGIT_NUMBER.test(value) ? '' : 'Invalid phone number.';
    }

    setPhoneNumberError(error);
  }

  function onPhoneNumberChanged(event) {
    const { id, value } = event.target;

    if (!testOnlyNumber(value)) {
      return;
    }

    dispatch(newCustomerChanged({ type: TYPES[id], value }));
  }

  function onEmployeeIDBlur(event) {
    const { value } = event.target;
    const error = value ? '' : 'This is a required field.';

    setEmployeeError(error);
  }

  function onEmployeeChanged(event) {
    const { id, value } = event.target;

    dispatch(newCustomerChanged({ type: TYPES[id], value }));
  }

  function toogleShowEmployeeID() {
    setShowEmployeeID(!showEmployeeID);
  }

  return (
    <section className="Live-vet-parent-container" id="pet-parent-section">
      <p className="Live-vet-parent-title">Pet Parent Info</p>

      <div className="Live-vet-parent-input-row">
        <div className="Live-vet-parent-input-container">
          <span
            className="Live-vet-parent-input-label"
            htmlFor="petParentFirstName"
          >
            First Name
          </span>

          <input
            className={'Live-vet-parent-input '
              + `${nameError ? 'Live-vet-parent-input-error' : ''}`}
            disabled={isNopComercedDisabled}
            id="petParentFirstName"
            name="firstName"
            onBlur={onRequiredInputBlur(setNameError)}
            onChange={onValueChanged}
            onFocus={onInputFocus(setNameError)}
            placeholder="Required"
            type="text"
            value={newCustomer.name}
          />

          <InputError error={nameError} />
        </div>

        <div className="Live-vet-parent-input-container">
          <span
            className="Live-vet-parent-input-label"
            htmlFor="petParentLastName"
          >
            Last Name
          </span>

          <input
            className={'Live-vet-parent-input '
              + `${lastNameError ? 'Live-vet-parent-input-error' : ''}`}
            disabled={isNopComercedDisabled}
            id="petParentLastName"
            name="lastName"
            onBlur={onRequiredInputBlur(setLastNameError)}
            onChange={onValueChanged}
            onFocus={onInputFocus(setLastNameError)}
            placeholder="Required"
            type="text"
            value={newCustomer.lastName}
          />

          <InputError error={lastNameError} />
        </div>
      </div>

      <div className="Live-vet-parent-input-row">
        <div className="Live-vet-parent-input-container">
          <span
            className="Live-vet-parent-input-label"
            htmlFor="petParenStreetAddress"
          >
            Street Address
          </span>

          <input
            className={'Live-vet-parent-input '
              + `${streetError ? 'Live-vet-parent-input-error' : ''}`}
            disabled={isNopComercedDisabled}
            id="petParenStreetAddress"
            name="streetAddress"
            onBlur={onStreetInputBlur}
            onChange={onStreetValueChanged}
            onFocus={onInputFocus(setStreetError)}
            placeholder="Required"
            type="text"
            value={newCustomer.address.street}
          />

          <InputError error={streetError} />
        </div>

        <div className="Live-vet-parent-input-container">
          <span
            className="Live-vet-parent-input-label"
            htmlFor="petParenStreetAddress2"
          >
            Street Address 2
          </span>

          <input
            className="Live-vet-parent-input"
            disabled={isNopComercedDisabled}
            id="petParenStreetAddress2"
            name="streetAddress2"
            onChange={onValueChanged}
            placeholder="Optional"
            type="text"
            value={newCustomer.address.street2}
          />
        </div>
      </div>

      <div className="Live-vet-parent-input-row">
        <Shimmer
          className="Live-vet-parent-input-container"
          visible={store.isLoadingNewAddress}
          width={282}
        >
          <span
            className="Live-vet-parent-input-label"
            htmlFor="petParentCity"
          >
            City
          </span>

          <input
            className={'Live-vet-parent-input '
              + `${cityError ? 'Live-vet-parent-input-error' : ''}`}
            disabled={isNopComercedDisabled}
            id="petParentCity"
            name="city"
            onBlur={onRequiredInputBlur(setCityError)}
            onChange={onValueChanged}
            onFocus={onInputFocus(setCityError)}
            placeholder="Enter your City"
            type="text"
            value={newCustomer.address.city}
          />

          <InputError error={cityError} />
        </Shimmer>

        <div className="Live-vet-parent-zip-state-container">
          <Shimmer
            className="Live-vet-parent-state-input-container"
            visible={store.isLoadingNewAddress}
          >
            <span
              className="Live-vet-parent-input-label"
              htmlFor="petParentState"
            >
              State
            </span>

            <div className="Live-vet-parent-input-icon-container">
              <input
                className="Live-vet-parent-input-with-icon"
                disabled
                id="petParentState"
                name="state"
                type="text"
                value={newCustomer.address.state}
              />

              <img
                alt="lock"
                className="Live-vet-parent-lock-icon"
                src={lockIcon}
              />
            </div>
          </Shimmer>

          <Shimmer
            className="Live-vet-parent-zip-input-container"
            visible={store.isLoadingNewAddress}
          >
            <span
              className="Live-vet-parent-input-label"
              htmlFor="petParentZipCode"
            >
              Zip
            </span>

            <div className="Live-vet-parent-input-icon-container">
              <input
                className="Live-vet-parent-input-with-icon"
                disabled
                id="petParentZipCode"
                name="zipCode"
                type="text"
                value={newCustomer.address.zipCode}
              />

              <img
                alt="lock"
                className="Live-vet-parent-lock-icon"
                src={lockIcon}
              />
            </div>
          </Shimmer>
        </div>
      </div>

      <div className="Live-vet-parent-input-row">
        <div className="Live-vet-parent-input-container">
          <span
            className="Live-vet-parent-input-label"
            htmlFor="petParentPhoneNumber"
          >
            Phone Number
          </span>

          <input
            className={'Live-vet-parent-input '
              + `${phoneNumberError ? 'Live-vet-parent-input-error' : ''}`}
            id="petParentPhoneNumber"
            maxLength={10}
            name="phoneNumber"
            onBlur={onPhoneNumberBlur}
            onChange={onPhoneNumberChanged}
            onFocus={onInputFocus(setPhoneNumberError)}
            placeholder="Required"
            type="text"
            value={newCustomer.phoneNumber}
          />

          <InputError error={phoneNumberError} />
        </div>

        <div className="Live-vet-parent-input-container">
          <span
            className="Live-vet-parent-input-label"
            htmlFor="employeeID"
          >
            Employee/Member ID

            {store.employeeIdHelpText
              ? (
                <span className="Employee-id-info-live-vet">
                  <IconTooltip description={store.employeeIdHelpText} />
                </span>
              )
              : null}
          </span>

          <input
            className={'Live-vet-parent-input '
              + `${employeeError ? 'Live-vet-parent-input-error' : ''}`}
            id="employeeID"
            maxLength={25}
            name="employeeID"
            onBlur={onEmployeeIDBlur}
            onChange={onEmployeeChanged}
            onFocus={onInputFocus(setEmployeeError)}
            placeholder="Required"
            type={showEmployeeID ? 'text' : 'password'}
            value={newCustomer.employeeID}
          />

          <TouchableDiv
            className="Employee-id-info-button"
            id="employeeID"
            onClick={toogleShowEmployeeID}
          >
            <img
              alt="employee id info"
              src={showEmployeeID ? showingPasswordIcon : showPasswordIcon}
            />
          </TouchableDiv>

          <InputError error={employeeError} />
        </div>
      </div>
    </section>
  );
};

export default React.memo(LiveVetPetParentInfo);
