import React from 'react';
import { useSelector } from 'react-redux';

import './css/HeaderIAIC.css';

const HeaderIAIC = ({ classContainer = '' }) => {
  const store = useSelector(({ quoting }) => quoting);
  const { data } = store;
  const HEADER_ID = 4;
  const HEADER_TEXT = 'Pet Insurance policies are underwritten '
    + 'by Independence American Insurance Company.';

  function renderText() {
    const headerInfo = data && data
      .insuranceProductEB.InsuranceInformationSectionsEB
      .find((section) => (section
        .InsuranceInformationTypeEBId === HEADER_ID));

    if (headerInfo) {
      const [info] = headerInfo.InsuranceInformationDetailsEB;

      return info ? info.Text : HEADER_TEXT;
    }

    return HEADER_TEXT;
  }

  return (
    <div className={`Header-container ${classContainer}`}>
      <span>{renderText()}</span>
    </div>
  );
};

export { HeaderIAIC };
