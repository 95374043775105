import { useEffect, useCallback } from 'react';

const ESC_KEY = 27;

const useOnEscEvent = (callback = () => {}, dependencies = []) => {
  const handleKeyDown = useCallback(({ keyCode }) => {
    if (keyCode === ESC_KEY) {
      callback();
    }
  }, [...dependencies, callback]);

  useEffect(() => {
    window.removeEventListener('keydown', handleKeyDown, true);
    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [...dependencies]);
};

export { useOnEscEvent };
