import React, { useCallback, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import closeIcon from '../../assets/close-gray.svg';
import './ModalContainer.css';
import { sleep } from '../../util';

const ModalContainer = ({
  cancelable = true,
  classCloseIcon,
  disableClickOutside,
  displayTitleDivider,
  handleOutClick = () => { },
  idPrefix = '',
  idTitle = '',
  isMultiModal,
  show,
  title,
  ...rest
}) => {
  const [bottom, setBottom] = useState(isMultiModal ? '-100vh' : '0');
  const modalDisappear = 'modalDisappear';
  const modalAppear = 'modalAppear';
  const [animationName, setAnimationName] = useState(modalAppear);

  const idOuter = `${idPrefix}modalOuter`;
  const dialogId = `${idPrefix}alert_dialog`;
  const closeId = `${idPrefix}closeModal`;
  const containerId = `${idPrefix}modalContainer`;

  const resetModalState = useCallback(() => {
    setAnimationName(modalAppear);
    handleOutClick();
  }, [handleOutClick]);

  async function handleCloseModal(event) {
    if (disableClickOutside && event.target.id === idOuter) {
      return;
    }

    setAnimationName(modalDisappear);

    await sleep(400);
    resetModalState();
  }

  function handleAnimationEnd() {
    if (animationName === modalDisappear) {
      resetModalState();
    } else {
      setBottom('0');
    }
  }

  return (
    <>
      {show
        && (
          <div className="Modal-wrapper" id={containerId}>
            <div
              aria-hidden="true"
              aria-labelledby={containerId}
              className="Modal-backdrop"
              id={idOuter}
              onClick={handleCloseModal}
              role="button"
              tabIndex={-1}
            />

            <FocusTrap active>
              <div
                aria-labelledby={idTitle}
                aria-modal="true"
                className={'Card-container'
                  + `${isMultiModal ? ' Multi-modal-card-container' : ''}`}
                id={dialogId}
                onAnimationEnd={handleAnimationEnd}
                role="alertdialog"
                style={{
                  animationName,
                  bottom,
                }}
              >
                <div
                  className={`Modal-card-title-header ${title ? '' : 'Gone'}`}
                >
                  <span
                    className="Modal-card-title"
                    id={title && idTitle ? idTitle : ''}
                  >
                    {title}
                  </span>

                  {displayTitleDivider
                    && <span className="Modal-card-title-divider" />}
                </div>

                <>
                  {cancelable
                    && (
                      <button
                        aria-label="Close modal"
                        className="Modal-card-button-close"
                        id={closeId}
                        onClick={handleCloseModal}
                        type="button"
                      >
                        <img
                          alt="close modal"
                          className={classCloseIcon
                            || 'Modal-card-icon-close'}
                          src={closeIcon}
                        />
                      </button>
                    )}
                </>

                {rest.children}
              </div>
            </FocusTrap>
          </div>
        )}
    </>
  );
};

export default ModalContainer;
