import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslations } from '../../../hooks';

import ModalContainer from '../../common/ModalContainer';

import ActiveCopayImg
from '../../../assets/powerUps/pet-protect-copay-active.svg';

import './PowerUpCoPayModal.css';

const PowerUpCoPayModal = ({ handleContinue, handleNeverMind }) => {
  const { t } = useTranslations('quoting', 'powerUpsSection.petCoPay');
  const store = useSelector(({ quoting }) => quoting);
  const { togglePetCoPayModalVisible } = store;

  const onConfirmCallback = useCallback(() => {
    handleNeverMind();
    handleContinue();
  }, [handleNeverMind, handleContinue]);

  return (
    <ModalContainer
      cancelable={false}
      show={togglePetCoPayModalVisible}
    >
      <div className="Pet-protect-copay-modal">
        <img alt="" src={ActiveCopayImg} />

        <span className="Pet-protect-copay-title">{t('title')}</span>

        <span className="Pet-protect-copay-subtitle">{t('subtitle')}</span>

        <p>{t('text')}</p>

        <button
          className="Pet-protect-modal-confirm"
          onClick={onConfirmCallback}
          type="button"
        >
          {t('continue')}
        </button>

        <button
          className="Pet-protect-modal-nevermind Like-span"
          onClick={handleNeverMind}
          type="button"
        >
          {t('cancel')}
        </button>
      </div>
    </ModalContainer>
  );
};

export { PowerUpCoPayModal };
