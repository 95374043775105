import React from 'react';
import { useTranslations } from '../../hooks/useTranslations';
import { PURCHASE_CUSTOMER_SERVICE_MSG } from '../../constants';
import plantPersonCat from '../../assets/purchase/plant-person-cat.svg';
import './QuestionsInfo.css';

const QuestionsInfo = ({
  isPurchase = false,
}) => {
  const { t } = useTranslations('quoting', 'questionsSection');
  const title = 'Questions?';
  const supportSchedule = t('supportSchedule');
  const supportDesc = PURCHASE_CUSTOMER_SERVICE_MSG;

  return (
    <div className={'Questions-sidebar-container Questions-innerent'
      + `${isPurchase ? ' Questions-purchase' : ' '}`}
    >
      <div className="Questions-sidebar-header">
        <img
          alt=""
          className="Questions-sidebar-help-desk-icon"
          src={plantPersonCat}
        />

        <h3
          className={`${isPurchase ? 'Questions-sidebar-title-purchase'
            : 'Questions-sidebar-title '}`}
        >
          {title}
        </h3>
      </div>

      <div className="Question-sidebar-text-mobile">
        <p className="Questions-sidebar-questions-text">
          {supportDesc}
        </p>

        <p className={'Questions-sidebar-questions-text '
          + 'Questions-sidebar-questions-text-subtitle'}
        >
          {supportSchedule}
        </p>
      </div>
    </div>
  );
};

export { QuestionsInfo };
