import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { HeaderIAIC } from '../quoting/header/HeaderIAIC';
import InputWithError from '../common/InputWithError';
import FigoLottie from '../common/FigoLottie';

import arrow from '../../assets/arrow-down-navy.svg';
import figoLogo from '../../assets/login/figo-logo-new-design.png';

import { useTranslations } from '../../hooks/useTranslations';
import { validateEmail } from '../../util';
import { FOOTER_TEXT_BASE } from '../../constants';

import { QuestionsSection } from '../quoting/QuestionsSection';
import QuotingFooter from '../quoting/QuotingFooter';
import ModalForgetPassword from '../common/ModalForgetPassword';

import {
  forgetPasswordVisibleChanged,
  continueWithoutLogin,
  setAsHasPassword,
} from '../../actions/quoting';

import './LoginAccountScreen.css';
import { useB2CInfo } from '../../hooks';

const LoginAccountScreen = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslations('login');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { query } = queryString.parseUrl(location.search);
  const { loginB2CROPC, ropcError, setRopcError } = useB2CInfo();
  const { state } = location;

  useEffect(() => { setEmail(query.email); }, [query.email]);

  const logginMessageResponse = useMemo(() => {
    const message = store.sessionInformation.nopCommerceUser?.LoginMessage;

    if (message?.toLowerCase().includes('successful')) {
      return '';
    }

    return message;
  }, [store.sessionInformation.nopCommerceUser]);

  const onEmailChanged = useCallback(({ target: { value } }) => {
    setEmail(value);
    setEmailError('');
  }, []);

  const onPasswordChanged = useCallback(({ target: { value } }) => {
    setPassword(value);
    setPasswordError('');
    setRopcError('');
  }, [setRopcError]);

  useEffect(() => {
    if (ropcError) {
      setPasswordError(ropcError);
    } else {
      setPasswordError('');
    }
  }, [ropcError]);

  useEffect(() => {
    const userLoggedIn = store.sessionInformation?.isCustomer;
    if (!userLoggedIn) {
      return;
    }

    let params = location.search;

    if (query.email !== email) {
      const newQuery = { ...query, email };
      params = `?${queryString.stringify(newQuery)}`;
    }

    history.push(`quoting/getQuote${params}`);
  }, [
    history,
    location.search,
    query,
    email,
    store.sessionInformation,
  ]);

  const onSubmitLogin = (event) => {
    event.preventDefault();

    if (!email) {
      setEmailError(t('form.required'));
    }

    if (!password) {
      setPasswordError(t('form.required'));
    }

    if (email && password) {
      const isValidEmail = validateEmail(email);
      setEmailError(isValidEmail ? '' : t('form.emailInvalidFormat'));

      if (isValidEmail && !store.sessionInformation.loadingLogin) {
        loginB2CROPC({ password, username: email });
      }
    }
  };

  const onContinueWithoutLoginClick = () => {
    dispatch(continueWithoutLogin());
    history.push(`quoting/getQuote${location.search}`);
  };

  const onResetPasswordClick = useCallback(() => {
    dispatch(forgetPasswordVisibleChanged({ visible: true }));
  }, [dispatch]);

  useEffect(() => {
    const hasPassword = state?.hasPassword ?? true;
    if (!hasPassword) {
      onResetPasswordClick();
      dispatch(setAsHasPassword(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onResetPasswordClick]);

  return (
    <div className="Login-account-screen-main-container">
      <header className="Login-account-screen-header">
        <HeaderIAIC classContainer="Login-account-screen-background-color" />

        <img
          alt=""
          className="Login-account-screen-figo-logo"
          src={figoLogo}
        />
      </header>

      <ModalForgetPassword />

      <section className="Login-account-screen-main-content">
        <div className="Login-account-screen-card">
          <h3>{t('loginAccount')}</h3>

          <span>{t('description')}</span>

          <form className="Login-account-screen-form" onSubmit={onSubmitLogin}>
            <InputWithError
              containerClass="Login-account-screen-input-container"
              inputClass="Login-account-screen-input"
              label={t('form.emailLabel')}
              labelClass="Login-account-screen-input-title"
              labelError={emailError}
              onChange={onEmailChanged}
              placeholder={t('form.emailLabel')}
              required
              showError={!!emailError}
              value={email}
            />

            <InputWithError
              absoluteButtonContent={t('form.forgotPassword')}
              containerClass="Login-account-screen-input-container-margin-top"
              inputClass="Login-account-screen-input"
              label={t('form.passwordLabel')}
              labelClass="Login-account-screen-input-title"
              labelError={passwordError}
              onAbsoluteBtnClick={onResetPasswordClick}
              onChange={onPasswordChanged}
              placeholder={t('form.required')}
              required
              showError={!!passwordError}
              type="password"
              value={password}
            />

            <span
              aria-live="polite"
              className="Input-whit-error-label-fail"
              title={logginMessageResponse}
            >
              {logginMessageResponse}
            </span>

            <button
              aria-label={store.sessionInformation.loadingLogin
                ? t('loading') : t('login')}
              aria-live={store.sessionInformation.loadingLogin
                ? 'polite' : 'off'}
              className="Login-account-screen-form-submit-button"
              type="submit"
            >
              {store.sessionInformation.loadingLogin ? (
                <FigoLottie height={40} width={40} />
              ) : t('login')}
            </button>

            <button
              className="Login-account-screen-form-continue"
              onClick={onContinueWithoutLoginClick}
              type="button"
            >
              {t('continueWithoutLogin')}

              <img alt="" className="Login-account-screen-arrow" src={arrow} />
            </button>
          </form>
        </div>

        <QuestionsSection isLogin />
      </section>

      <QuotingFooter customFooterText={FOOTER_TEXT_BASE} />
    </div>
  );
};

export { LoginAccountScreen };
