import React from 'react';
import './PageNotFound.css';

const PageNotFound = () => {
  function renderContent() {
    return (
      <div>Page not found.</div>
    );
  }

  return (
    <div className="Page-not-found-container">
      {renderContent()}
    </div>
  );
};

export default PageNotFound;
