import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  multiModalCustomContenChanged,
  powerUpSelected,
  removePowerupIdError,
  resetPowerUpAriaLabel,
  updatePowerUp,
  wellnessVisibleChanged,
} from '../../../actions';

import Checkbox from '../../common/Checkbox';
import { WhatsCoveredLink } from '../WhatsCoveredLink';
import { PowerUpCard } from './PowerUpCard';
import { PowerUpCoPay } from './PowerUpCoPay';
import { HtmlContent } from '../../common';

import {
  useCalculatePlanPrice,
  useResponsive,
  useTranslations,
} from '../../../hooks';
import {
  currencyFormat,
  scrollToElement,
  sleep,
  useCustomPlanPills,
  usePowerupsRadioButtons,
} from '../../../util';

import {
  calculateCoverageFee,
  findSelectedModifier,
  isWellnessFPI,
} from './utils';
import {
  POWERUP_TYPE,
  PowerUpsRelation,
  WELLNESS_COVERAGE_LIMIT_FPI,
  WELLNESS_MULTIMODAL_ID,
  MODAL_RELATION_LIST,
} from '../../../constants';

import infoSelected from '../../../assets/info-blue-light.svg';
import info from '../../../assets/info-white.svg';

import './PowerUpSection.css';

const PowerUpSection = ({
  petQuote,
  quoteSelected,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslations('quoting', 'powerUpsSection');
  const { isMobile } = useResponsive();
  const store = useSelector(({ quoting }) => quoting);
  const {
    data,
    guidData,
    multiModalData,
    parameters,
    petQuoteSelected,
    rateSummary,
    sessionInformation: { nopCommerceUser, userEmail },
    powerUpErrors,
    powerUpErrorsScroll,
  } = store;
  let wellnessText = t('startingPrice.wellness');

  const { WELLNESS_MODAL_ID } = MODAL_RELATION_LIST;

  const {
    PER_INCIDENT_CO_PAY,
    WELLNESS_DENTAL_PSM_ID,
    WELLNESS_PSM_ID,
  } = PowerUpsRelation;

  const perIncidentCoPayPowerup = useMemo(() => {
    if (data) {
      const { insuranceProductEB: { InsuranceModifiersEB } } = data;

      return InsuranceModifiersEB
        .find((modifier) => modifier.InsuranceModifierTypeEBId === POWERUP_TYPE
          && modifier.IsVisible
          && modifier.PMSModifierId === PER_INCIDENT_CO_PAY);
    }

    return null;
  }, [data, PER_INCIDENT_CO_PAY]);

  useEffect(() => {
    if (powerUpErrors.length > 0 && powerUpErrorsScroll) {
      const [powerupIdToScroll] = powerUpErrors;
      scrollToElement(`Powerup-card-section-${powerupIdToScroll}`);
    }
  }, [powerUpErrors, powerUpErrorsScroll]);

  function getPetName() {
    if (quoteSelected) {
      const petNameFactor = 27;
      if (quoteSelected.petName.length < petNameFactor
        || quoteSelected.petName.includes(' ')) {
        return `${quoteSelected.petName}${t('titlePlan')}`;
      }

      return `${quoteSelected.petName
        .slice(0, petNameFactor)}...${t('titlePlan')}`;
    }

    return t('namePlaceHolder');
  }

  const addOrUpdatePowerUp = ({ base = [], toAdd = [] }) => {
    if (toAdd.length === 0) {
      return base;
    }

    let newBase = [...base];
    const [add, ...rest] = toAdd;

    const findItem = newBase.find((item) => item.id === add.id);

    if (findItem) {
      newBase = base.map((item) => {
        if (item.id === add.id) {
          return add;
        }

        return item;
      });
    } else {
      newBase.push(add);
    }

    return addOrUpdatePowerUp({ base: newBase, toAdd: rest });
  };

  const handleAddPowerup = ({ isSelected = null, powerUp }) => () => {
    const { Id } = powerUp;
    const { insuranceProductEB: { InsuranceModifiersEB } } = data;
    const previosQuoteModifiers = (petQuote.modifiers || []);

    dispatch(powerUpSelected({
      id: Id,
      isSelected,
      quoteId: petQuoteSelected,
    }));
    if (powerUp.BenefitParentId) {
      dispatch(removePowerupIdError(powerUp.BenefitParentId));
    }

    let allModifiers = InsuranceModifiersEB
      .filter((modifier) => modifier.InsuranceModifierTypeEBId === POWERUP_TYPE
        && modifier.IsVisible)
      .map((item) => ({ id: item.Id, isSelected: null }));

    previosQuoteModifiers.forEach((quoteModifier) => {
      const findItem = allModifiers
        .find((item) => item.id === quoteModifier.id);

      if (!findItem) {
        allModifiers = [
          ...allModifiers,
          { id: quoteModifier.id, isSelected: null },
        ];
      }
    });

    let modifiers = allModifiers.map((item) => {
      if (item.id === Id) {
        return { id: Id, isSelected };
      }

      const currentModifier = previosQuoteModifiers
        .find((petModifier) => petModifier.id === item.id)
        || { isSelected: null };

      return {
        ...item,
        isSelected: currentModifier.isSelected,
      };
    });

    const wellnessPowerup = InsuranceModifiersEB
      .find((modifier) => modifier.InsuranceModifierTypeEBId === POWERUP_TYPE
        && modifier.IsVisible && modifier.PMSModifierId === WELLNESS_PSM_ID);
    const isFPI = wellnessPowerup && isWellnessFPI({
      insuranceProductId: data.insuranceProductEB.Id,
      powerUp: wellnessPowerup,
    });

    if (isFPI) {
      const wellnessfather = { id: wellnessPowerup.Id, isSelected };
      const wellnessChildrens = wellnessPowerup.BundleInsuranceModifiersEB
        .map((child) => {
          if (child.PMSModifierId === WELLNESS_DENTAL_PSM_ID
            || child.Id === Id) {
            return { id: child.Id, isSelected };
          }

          return { id: child.Id, isSelected: null };
        });

      if (wellnessChildrens.find((child) => child.id === Id)) {
        modifiers = modifiers
          .filter((element) => element.id !== wellnessPowerup.Id);
        // any wellness clidren is selected
        modifiers = addOrUpdatePowerUp({
          base: modifiers,
          toAdd: [...wellnessChildrens, wellnessfather],
        });
      } else {
        // add previous wellness powerups selected
        const wellnessChildrensIds = wellnessChildrens.map((child) => child.id);
        const previousWellness = previosQuoteModifiers
          .filter((prevModifier) => wellnessChildrensIds
            .includes(prevModifier.id));

        modifiers = addOrUpdatePowerUp({ base: modifiers, previousWellness });
      }
    }

    if (powerUp.PMSModifierId === WELLNESS_PSM_ID) {
      // Wellness IHC
      const childsWellness = wellnessPowerup.BundleInsuranceModifiersEB
        .map((element) => ({ id: element.Id, isSelected }));

      modifiers = addOrUpdatePowerUp({
        base: modifiers,
        toAdd: childsWellness,
      });
    }

    if (perIncidentCoPayPowerup) {
      const { isSelected: isPicpSelected } =
        modifiers.find((item) => item.id === perIncidentCoPayPowerup.Id);

      const chidlrenPICP = perIncidentCoPayPowerup.BundleInsuranceModifiersEB
        .map((element) => ({ id: element.Id, isSelected: isPicpSelected }));

      modifiers = addOrUpdatePowerUp({ base: modifiers, toAdd: chidlrenPICP });
    }

    dispatch(updatePowerUp({
      diamondClientId: nopCommerceUser.DiamonClientdId,
      ebGuID: guidData.GuID,
      effectiveDateCustom: store.effectiveDateCustom,
      eMail: userEmail || parameters.email,
      employerName: guidData.Name,
      guidPayload: guidData,
      id: Id,
      isSelected,
      modifiers,
      nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      parameters,
      petQuoteList: data.ebPetQuoteResponseList,
      promoCode: store.data.groupCode,
      quoteId: store.petQuoteSelected,
      showLoading: true,
    }));
  };

  function getPowerUpPrice(powerUp) {
    return calculateCoverageFee({
      data,
      petQuote,
      petQuoteSelected,
      powerUp,
      quoteSelected,
    });
  }

  const powerUpSelectedHandle = (id) => {
    const { modifiers } = data.ebPetQuoteResponseList
      .find((item) => item.petQuoteId === petQuoteSelected) || {};

    if (!modifiers || !modifiers.length) {
      return null;
    }

    const modifier = modifiers.find((item) => item.id === id);

    return modifier && modifier.isSelected;
  };

  function getFirstCategoryId(menuId, subnMenuId = null) {
    let categoryId = 1;

    if (multiModalData.InsuranceMultiModalSectionDtoList) {
      const categoryList = multiModalData
        .InsuranceMultiModalSectionDtoList
        .find((element) => element.Id === menuId);

      if (categoryList) {
        categoryId = categoryList
          .InsuranceMultiModalCategoryDtoList
          .find((sub) => sub.Id === subnMenuId || !subnMenuId).Id;
      }
    }

    return categoryId;
  }

  const handleSeeWhatsCovered = (powerup) => () => {
    const wellnessTypeFromEvent =
      WELLNESS_COVERAGE_LIMIT_FPI.BASIC.includes(powerup.CoverageLimitId)
        ? WELLNESS_MULTIMODAL_ID.BASIC
        : WELLNESS_MULTIMODAL_ID.PLUS;

    const visible = true;
    const categoryId =
      getFirstCategoryId(WELLNESS_MODAL_ID, wellnessTypeFromEvent);
    dispatch(wellnessVisibleChanged({ categoryId, visible }));
  };

  const onClickWhatsCovered = (powerUp) => () => {
    const multiModalContent = powerUp.OptionalBenefitsDetailsItem;

    if (multiModalContent && multiModalContent.length) {
      dispatch(multiModalCustomContenChanged({
        category: powerUp.TitleText,
        content: multiModalContent,
        title: powerUp.AppText,
        visible: true,
      }));

      return;
    }

    const contentModifiers = powerUp.BundleInsuranceModifiersEB
      .filter((modifier) => modifier.IsVisible && modifier.IsActive);
    const selectedModifier = contentModifiers
      .find((item) => item.IsSelected);
    const defaultModifier = contentModifiers[0];

    const powerupInfo = selectedModifier || defaultModifier;
    if (!powerupInfo) {
      return;
    }

    handleSeeWhatsCovered(selectedModifier || defaultModifier)();
  };

  const checkboxWellnessPrefix = 'checkboxWellness';
  const { quoteSelected: quoteValues } = useCalculatePlanPrice();

  const ariaLabel = useMemo(() => {
    const { petName, total } = quoteValues;

    if (!rateSummary.pricesChanged && !rateSummary.powerUpChanged) {
      return '';
    }

    let label = '';

    if (rateSummary.pricesChanged && rateSummary.powerUpChanged) {
      label = `Updated premium for ${petName} is
        $${total} per month`;
    }

    return label;
  }, [quoteValues, rateSummary.powerUpChanged, rateSummary.pricesChanged]);

  useEffect(() => {
    async function resetAriaLabel() {
      if (ariaLabel !== '') {
        await sleep(5000);
        dispatch(resetPowerUpAriaLabel());
      }
    }

    resetAriaLabel();
  }, [ariaLabel, dispatch]);

  function renderContent(powerUp) {
    if (!usePowerupsRadioButtons && isWellnessFPI({
      insuranceProductId: data.insuranceProductEB.Id,
      powerUp,
    })) {
      const contentModifiers = powerUp.BundleInsuranceModifiersEB
        .filter((modifier) => modifier.IsVisible && modifier.IsActive);

      if (!contentModifiers || !contentModifiers.length
        || contentModifiers.length === 0) {
        return null;
      }

      return (
        <section aria-label={t('PowerupInformation')}>
          <div>
            <span>{powerUp.InformationText}</span>

            {contentModifiers.map((item, index) => {
              const contentModifierSelected = (petQuote.modifiers || [])
                .find((element) => element.id === item.Id);
              const isSelected = contentModifierSelected
                ? contentModifierSelected.isSelected : false;
              const elementId = `${checkboxWellnessPrefix}${item.Id}`;

              if (isSelected) {
                wellnessText = t('startingPrice.plus');
              }

              return (
                <section
                  key={String(index)}
                  className="Powerup-wellness-checkbox-row-container"
                  id={`${elementId}Content`}
                >
                  <Checkbox
                    checked={powerUpSelectedHandle(item.Id)}
                    className={'Powerup-wellness-checkbox-container'
                      + `${index === 0 ? '-first' : ''}`}
                    id={elementId}
                    isRounded
                    onClick={handleAddPowerup(
                      { isSelected: !isSelected, powerUp: item },
                    )}
                  >
                    <span className="Powerup-wellness-price-checkbox">
                      <span className="Powerup-wellness-title">
                        {item.TitleText}
                      </span>
                      &nbsp;- ${currencyFormat(
                        { value: item.FeeAmount },
                      )}{t('paymentCycle.monthly')}&nbsp;
                      {item.InputText}&nbsp;${item.MaximumAnnualBenefit}
                    </span>
                  </Checkbox>

                  <WhatsCoveredLink
                    className="Powerup-wellness-link-content"
                    icon={powerUpSelectedHandle(powerUp.Id)
                      ? infoSelected : info}
                    id={`whatsCovered${item.Id}`}
                    onClick={handleSeeWhatsCovered(item)}
                  />
                </section>
              );
            })}
          </div>
        </section>
      );
    }

    return (
      <HtmlContent
        classNameContainer={usePowerupsRadioButtons
          ? 'Powerup-card-information' : ''}
        content={powerUp.InformationText}
        elementType="p"
      />
    );
  }

  function handleToggle({ isSelected = null, powerUp }) {
    if (isWellnessFPI({
      insuranceProductId: data.insuranceProductEB.Id,
      powerUp,
    })) {
      const contentModifiers = powerUp.BundleInsuranceModifiersEB
        .filter((modifier) => modifier.IsVisible && modifier.IsActive);
      const selectedModifier = contentModifiers.find((item) => item.IsSelected);
      const defaultModifier = contentModifiers[0];

      if (!selectedModifier) {
        const isChecked = findSelectedModifier({
          id: defaultModifier.Id,
          modifiers: petQuote.modifiers || [],
        });

        return handleAddPowerup({
          isSelected: isSelected === false && isChecked === null
            ? false : !isChecked,
          powerUp: defaultModifier || [],
        });
      }

      const isChecked = findSelectedModifier({
        id: selectedModifier.Id,
        modifiers: petQuote.modifiers,
      });

      return handleAddPowerup({
        isSelected: !isChecked,
        powerUp: selectedModifier,
      });
    }

    return handleAddPowerup({ isSelected, powerUp });
  }

  function renderPowerUps() {
    if (!store.data) {
      return null;
    }

    const quote = store.data.ebPetQuoteResponseList
      .find((pet) => pet.petQuoteId === quoteSelected.quoteId);

    if (!quote) {
      return null;
    }

    const powerUps = quote.InsuranceModifiersEB.filter((modifier) => modifier
      .InsuranceModifierTypeEBId === POWERUP_TYPE && modifier.IsVisible
      && modifier.PMSModifierId !== PER_INCIDENT_CO_PAY);

    return (
      <>
        {powerUps.map((powerUp) => {
          const isWellness = isWellnessFPI({
            insuranceProductId: data.insuranceProductEB.Id,
            powerUp,
          });

          return (
            <PowerUpCard
              key={String(powerUp.Id)}
              ariaLabel={useCustomPlanPills ? '' : ariaLabel}
              bundleCoverageClick={handleSeeWhatsCovered}
              bundleModifierAdd={handleAddPowerup}
              bundleModifierCheckSelection={powerUpSelectedHandle}
              content={renderContent(powerUp)}
              error={powerUpErrors.includes(powerUp.Id)}
              handleAddPowerup={handleToggle({ isSelected: true, powerUp })}
              handleRemovePowerup={handleToggle({ isSelected: false, powerUp })}
              hideInfoImage={isMobile && isWellness}
              onClickWhatsCovered={onClickWhatsCovered(powerUp)}
              petQuoteSelected={petQuoteSelected}
              powerUpInfo={powerUp}
              price={getPowerUpPrice(powerUp)}
              selected={powerUpSelectedHandle(powerUp.Id)}
              startingText={isWellness
                ? wellnessText
                : t('startingPrice.plus')}
              title={powerUp.TitleText}
            />
          );
        })}
      </>
    );
  }

  const Title = () => (
    <section aria-label={t('PowerupTitleInfo')}>
      <div className="Powerup-title-info">
        <h1 className="Powerup-header-title">
          {`${t('powerUp')} ${getPetName()}`}
        </h1>

        <p className="Powerup-title-description">
          {t('descriptionPt1')}

          <br aria-hidden="true" />

          {` ${t('descriptionPt2')}`}
        </p>
      </div>
    </section>
  );

  function renderCoPay() {
    if (!store.data || !perIncidentCoPayPowerup) {
      return null;
    }

    return (
      <PowerUpCoPay
        handleAddPowerup={handleToggle({
          isSelected: true,
          powerUp: perIncidentCoPayPowerup,
        })}
        handleRemovePowerup={handleToggle({
          isSelected: false,
          powerUp: perIncidentCoPayPowerup,
        })}
        multiModalData={multiModalData}
        powerUp={perIncidentCoPayPowerup}
        selected={powerUpSelectedHandle(perIncidentCoPayPowerup.Id)}
      />
    );
  }

  return (
    <div className="Powerup-cards-container">
      {renderCoPay()}

      <Title />

      {renderPowerUps()}
    </div>
  );
};

export { PowerUpSection };
