import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const useQuery = () => {
  const [queries, setQueries] = useState({});
  const location = useLocation();

  useEffect(() => {
    const parametersRaw = queryString.parse(location.search);

    setQueries(parametersRaw);
  }, [location.search]);

  return queries;
};
