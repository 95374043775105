import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FocusTrap from 'focus-trap-react';
import queryString from 'query-string';

import { AddPetForm } from './AddPetForm';

import {
  useB2CInfo,
  useOnEscEvent,
  useTranslations,
} from '../../../hooks';

import {
  addPet,
  loadAgeData,
  loadBreedData,
  noDiamondClientIdError,
  saveNewPetEmail,
  saveNewPetName,
  saveNewPetZipCode,
  saveSelectedEditPetModal,
  saveUpdatedPet,
  updatePet,
  validateCustomerExistsByEmail,
  validZipCode,
} from '../../../actions/quoting';
import {
  removeEmojis,
  testOnlyNumber,
  useROPC,
  validateEmail,
} from '../../../util';
import {
  MAX_PET_NAME_LENGTH,
  PetGender,
  PetType,
  POWERUP_TYPE,
  USER_ROLL_LIST,
} from '../../../constants';

import './ModalAddPet.css';

const ModalAddPet = ({
  getQuote = false,
  handleCloseModalAddPet = () => { },
  isEditing = false,
  show = false,
}) => {
  const location = useLocation();
  const [firstMount, setFirstMount] = useState(true);
  const [petNameFocusFlag, setPetNameFocusFlag] = useState(false);
  const [zipcodeFocusFlag, setZipcodeFocusFlag] = useState(false);
  const [emailFocusFlag, setEmailFocusFlag] = useState(false);
  const [petTypeError, setPetTypeError] = useState('');
  const [petGenderError, setPetGenderError] = useState('');
  const [petNameError, setPetNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [zipCodeError, setZipcodeError] = useState('');
  const [ageError, setAgeError] = useState(false);
  const [breedError, setBreedError] = useState(false);
  const [validating, setValidating] = useState(false);
  const [termsPolicyError, setTermsPolicyError] = useState(false);
  const [isFocusTrapActive, setIsFocusTrapActive] = useState(false);
  const { loginB2C } = useB2CInfo();

  function handleKeyDown(event) {
    const TAB_KEY = 9;

    if (event.keyCode === TAB_KEY && !getQuote) {
      setIsFocusTrapActive(true);
    }
  }

  const store = useSelector((stores) => stores.quoting);
  const {
    acceptedTermsAndPolicy,
    ageData,
    data,
    effectiveDateCustom,
    employerId,
    employerPromoCode,
    guidData,
    parameters,
    petQuoteSelected,
    sessionInformation,
    subId,
    newPet: {
      email,
      petAge,
      petBreed,
      petBreedId,
      petName,
      petSex,
      petType,
      zipcode,
    },
    petUpdated,
  } = store;

  const { t } = useTranslations('landing');

  const dispatch = useDispatch();
  const dogSpeciesRef = useRef(null);
  const maleGenderRef = useRef(null);
  const petNameRef = useRef(null);
  const petAgeRef = useRef(null);
  const petBreedRef = useRef(null);
  const petZipcodeRef = useRef(null);
  const petEmailRef = useRef(null);

  useEffect(() => {
    if (firstMount && show) {
      dispatch(loadBreedData());
      dispatch(loadAgeData());
      setFirstMount(false);
    }
  }, [dispatch, firstMount, show]);

  function getQuteButtonEnabled() {
    if (!ageData) {
      return false;
    }

    return (parameters.email || email) && zipcode && petName
      && petBreed && petBreedId && petAge && petAge !== ageData[0].Description
      && !petNameError && !emailError && !zipCodeError
      && !petTypeError && petType.value !== PetType.None.value
      && !petGenderError && petSex.value !== PetGender.None.value;
  }

  const getBodyForQuote = useCallback((isEditingPet) => {
    const { ebPetQuoteResponseList } = data;

    // pets from rate quote response (now including URL pet)
    const currentPets = ebPetQuoteResponseList
      .map((petQuote) => (
        {
          Id: petQuote.petQuoteId,
          Modifiers: petQuote.modifiers,
          PetAge: petQuote.petAgeName,
          PetBreed: petQuote.breedName,
          PetBreedId: petQuote.breedId,
          PetName: petQuote.petName,
          PetSex: petQuote.genderName,
          PetType: petQuote.petType === PetType.Dog.value
            ? PetType.Dog.name : PetType.Cat.name,
        }
      ));

    const newPetIdAdded = ebPetQuoteResponseList[
      ebPetQuoteResponseList.length - 1].petQuoteId + 1;

    if (!isEditingPet) {
      // current pet for add
      const { insuranceProductEB: { InsuranceModifiersEB } } = data;
      const modifiers = InsuranceModifiersEB
        .filter(
          (modifier) => modifier.InsuranceModifierTypeEBId === POWERUP_TYPE
            && modifier.IsVisible,
        )
        .map((item) => ({
          id: item.Id,
          isSelected: null,
        }));

      const anyModifierSelected = modifiers.some((item) => item.isSelected);

      currentPets.push({
        Id: newPetIdAdded,
        // ECP = Extra Care Pack Or Enrique Peña Nieto
        // Send empty array if no power up selected (is neccesary for ECP)
        Modifiers: anyModifierSelected ? modifiers : [],
        PetAge: petAge,
        PetBreed: petBreed,
        PetBreedId: petBreedId,
        PetName: petName,
        PetSex: petSex.name,
        PetType: petType.name,
      });
    }

    const quoteId = isEditingPet ? petQuoteSelected : newPetIdAdded;

    const body = {
      EffectiveDate: null,
      GroupCode: employerPromoCode,
      GroupCodeDscr: parameters.groupCodeDscr,
      petQuotes: currentPets,
      quoteId,
      QuoteSubId: subId,
      TestEffectiveDate: effectiveDateCustom,
      ZipCode: parameters.petZipCode,
    };

    return body;
  }, [
    data,
    effectiveDateCustom,
    employerPromoCode,
    parameters.groupCodeDscr,
    parameters.petZipCode,
    petAge,
    petBreed,
    petBreedId,
    petName,
    petQuoteSelected,
    petSex.name,
    petType.name,
    subId,
  ]);
  const history = useHistory();

  useEffect(() => {
    const { nopCommerceUser, userEmail } = sessionInformation;

    if (petUpdated) {
      const body = {
        ...getBodyForQuote(true),
        DiamondClientId: nopCommerceUser.DiamonClientdId,
        ebGuID: guidData.GuID,
        eMail: userEmail || parameters.email,
        employerName: guidData.Name,
        nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      };

      dispatch(updatePet({
        body,
        update: isEditing,
      }));
      handleCloseModalAddPet();
      setFirstMount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getBodyForQuote, petUpdated, sessionInformation]);

  useOnEscEvent(() => {
    handleCloseModalAddPet();
  }, []);

  useEffect(() => {
    setAgeError(false);
  }, [petAge]);

  useEffect(() => {
    setBreedError(false);
  }, [petBreed]);

  const handlePetNameChange = useCallback((event) => {
    const petNameTyped = removeEmojis(event.target.value);
    if (petNameTyped.length <= MAX_PET_NAME_LENGTH) {
      dispatch(saveNewPetName(petNameTyped));
      setPetNameError(!petNameTyped);
    }
  }, [dispatch]);

  const handleZipcodeChange = useCallback((event) => {
    const { value } = event.target;
    const newValue = testOnlyNumber(value) ? value : zipcode;

    dispatch(saveNewPetZipCode(newValue));
  }, [dispatch, zipcode]);

  const handleEmail = useCallback((event) => {
    const { value } = event.target;

    dispatch(saveNewPetEmail(removeEmojis(value)));
  }, [dispatch]);

  const handleOnCancelClick = useCallback(() => {
    handleCloseModalAddPet();
    dispatch(saveSelectedEditPetModal(false));
  }, [dispatch, handleCloseModalAddPet]);

  const isValidZipCode = useCallback(async () => {
    const zipValid = await validZipCode(zipcode)();

    return zipValid;
  }, [zipcode]);

  const onFocusEmail = useCallback(() => {
    setEmailError('');
    setEmailFocusFlag(true);
  }, []);

  const onBlurEmail = useCallback(() => {
    let message = 'Email is required';

    if (email) {
      message = validateEmail(email) ? '' : 'Invalid email';
    }

    setEmailError(message);
    setEmailFocusFlag(false);
  }, [email]);

  const areTermsRejected = useCallback(() => {
    setTermsPolicyError(!acceptedTermsAndPolicy);
    return !acceptedTermsAndPolicy;
  }, [acceptedTermsAndPolicy]);

  const handleEnterPress = useCallback((event) => {
    if (event.key === 'Enter') {
      onBlurEmail();
    }
  }, [onBlurEmail]);

  const onFocusZipcode = useCallback(() => {
    setZipcodeError('');
    setZipcodeFocusFlag(true);
  }, []);

  const onBlurZipcode = useCallback(async () => {
    let message = 'Zip code is required';

    if (zipcode) {
      if (zipcode.length < 5) {
        message = 'Invalid zip code';
      } else {
        const zipCodeValidate = await isValidZipCode();

        message = zipCodeValidate ? '' : 'Invalid zip code';
      }
    }

    setZipcodeError(message);
    setZipcodeFocusFlag(false);
  }, [isValidZipCode, zipcode]);

  const onFocusPetName = useCallback(() => {
    setPetNameError('');
    setPetNameFocusFlag(true);
  }, []);

  const onBlurPetName = useCallback(() => {
    const newPetName = petName.split(' ').filter((item) => !!item).join(' ');
    dispatch(saveNewPetName(newPetName));
    const message = petName.length ? '' : 'Pet\'s name is required';
    setPetNameError(message);
    setPetNameFocusFlag(false);
  }, [dispatch, petName]);

  function setFocusOnErrorField() {
    if (petType.value === PetType.None.value && dogSpeciesRef.current) {
      dogSpeciesRef.current.focus();
      return;
    }

    if (petSex.value === PetGender.None.value && maleGenderRef.current) {
      maleGenderRef.current.focus();
      return;
    }

    if (!petName && petNameRef.current) {
      petNameRef.current.focus();
      return;
    }

    if (!petAge && petAgeRef.current) {
      petAgeRef.current.focus();
      return;
    }

    if (!petBreed && petBreedRef.current) {
      petBreedRef.current.focus();
      return;
    }

    if ((!zipcode || zipCodeError) && petZipcodeRef.current) {
      petZipcodeRef.current.focus();
      return;
    }

    if (!email && petEmailRef.current) {
      petEmailRef.current.focus();
    }
  }

  function doFormValidations() {
    setAgeError(!petAge);
    setBreedError(!petBreed);
    onBlurPetName();
    onBlurZipcode();
    onBlurEmail();
    setPetTypeError(petType.value === PetType.None.value
      ? 'Pet type must be selected in order to proceed' : '');
    setPetGenderError(petSex.value === PetGender.None.value
      ? 'Pet gender must be selected in order to proceed' : '');
    areTermsRejected();

    setFocusOnErrorField();
  }

  function validateFields() {
    return petName
      && (petSex.value !== PetType.None.value)
      && petBreed
      && petBreedId
      && petAge
      && (zipcode && !zipCodeError)
      && (ageData && petAge !== ageData[0].Description);
  }

  async function handleOnSaveClick() {
    const { sessionInformation: { nopCommerceUser, userEmail } } = store;

    if (handleCloseModalAddPet) {
      doFormValidations();

      if (validateFields()) {
        window.scrollTo(0, 0);

        if (isEditing) {
          const zipValid = await isValidZipCode();

          if (!zipValid) {
            setZipcodeError('Invalid zip code');

            return;
          }

          dispatch(saveUpdatedPet());
        } else {
          const body = getBodyForQuote(isEditing);
          const bodyWithDiamondClientId = {
            ...body,
            DiamondClientId: nopCommerceUser.DiamonClientdId,
            ebGuID: guidData.GuID,
            eMail: userEmail || parameters.email,
            employerName: guidData.Name,
            nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
          };
          dispatch(addPet(bodyWithDiamondClientId));
          handleCloseModalAddPet();
          setFirstMount(true);
        }
      }
    }
  }

  const onContinueWithoutLoginClick = ({ urlQuoting = '' }) => () => {
    history.push(urlQuoting);
  };

  async function onClickGetQuote() {
    if (validating) {
      return;
    }

    setValidating(true);

    if (!getQuteButtonEnabled()) {
      doFormValidations();
      setValidating(false);
      return;
    }

    const zipValid = await isValidZipCode();

    if (!zipValid) {
      setZipcodeError('Invalid zip code');

      setValidating(false);
      return;
    }

    if (areTermsRejected()) {
      setValidating(false);
      return;
    }

    const { query } = queryString.parseUrl(location.search);
    const agentIdParam = query.agentId ? { agentId: query.agentId } : {};

    const quote = {
      ...agentIdParam,
      email,
      empGuid: employerId,
      id: '1',
      petAge,
      petBreed,
      petBreedId,
      petName,
      petSex: petSex.name,
      petType: petType.name,
      petZipCode: zipcode,
    };

    if (subId && subId.Name && subId.Value) {
      quote[subId.Name] = subId.Value;
    }

    const payload = await validateCustomerExistsByEmail(email);
    const { IsValid, Data } = payload;
    const userAlreadyExists = IsValid && !!Data;
    let hasCustomerRoll = false;

    if (IsValid && typeof Data === 'object') {
      hasCustomerRoll = !!Data.CustomerRoles
        .find((roll) => USER_ROLL_LIST.includes(roll));
    }

    if (userAlreadyExists && !hasCustomerRoll) {
      // users with no roll or diamondCliendId: 0 need call customer service.
      // WPI: Petclout Free user?
      dispatch(noDiamondClientIdError('User without customer roll'));
      setValidating(false);
      return;
    }

    const quoteBase = queryString.stringify(quote);
    const urlQuoting = `/quoting/getQuote?${quoteBase}`;

    setValidating(false);

    if (userAlreadyExists) {
      if (useROPC) {
        const urlLogin = `/login?${quoteBase}`;
        history.push(urlLogin, { hasPassword: Data?.HasPassword ?? true });
      } else {
        loginB2C({
          continueWithoutLogin: onContinueWithoutLoginClick({ urlQuoting }),
          existingEmail: email,
          urlState: urlQuoting,
        });
      }
    } else {
      history.push(urlQuoting);
    }
  }

  const handleSubmitPetForm = (event) => {
    event.preventDefault();

    if (getQuote) {
      onClickGetQuote();
      return;
    }

    window.scrollTo(0, 0);
    handleOnSaveClick();
  };

  const InputError = useCallback(({ error, className = '' }) => (
    <span
      aria-live="polite"
      className={`Modal-add-pet-input-error-label ${className}`}
    >
      {error}
    </span>
  ), []);

  function getTitle() {
    if (getQuote) {
      return (
        <div className="Modal-add-pet-title-container">
          <span>{t('modalAddPet.getQuote')}</span>
        </div>
      );
    }

    return isEditing ? t('modalAddPet.editPet') : t('modalAddPet.addPet');
  }

  useEffect(() => {
    if (!getQuote) {
      window.addEventListener('keydown', handleKeyDown, true);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
      setIsFocusTrapActive(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuote]);

  return (
    <FocusTrap active={isFocusTrapActive}>
      <div
        className={'Modal-add-pet-content'
          + ` ${getQuote ? 'Modal-add-pet-content-landing' : ''}`}
      >
        <h1 className="Modal-add-pet-title">
          {getTitle()}
        </h1>

        <hr aria-hidden className="Modal-add-pet-title-separator" />

        <span
          className={'Modal-add-pet-title-description'
            + `${isEditing ? ' Gone' : ''}`}
        >
          Tell us a little bit about your pet
        </span>

        <AddPetForm
          ageError={ageError}
          breedError={breedError}
          emailError={emailError}
          emailFocusFlag={emailFocusFlag}
          getQuote={getQuote}
          handleEmail={handleEmail}
          handleOnCancelClick={handleOnCancelClick}
          handlePetNameChange={handlePetNameChange}
          handleSubmitPetForm={handleSubmitPetForm}
          handleZipcodeChange={handleZipcodeChange}
          InputError={InputError}
          isEditing={isEditing}
          onBlurEmail={onBlurEmail}
          onBlurPetName={onBlurPetName}
          onBlurZipcode={onBlurZipcode}
          onFocusEmail={onFocusEmail}
          onFocusPetName={onFocusPetName}
          onFocusZipcode={onFocusZipcode}
          onKeyPress={handleEnterPress}
          petAgeRef={petAgeRef}
          petBreedRef={petBreedRef}
          petEmailRef={petEmailRef}
          petGenderError={petGenderError}
          petGenderRef={dogSpeciesRef}
          petNameError={petNameError}
          petNameFocusFlag={petNameFocusFlag}
          petNameRef={petNameRef}
          petTypeError={petTypeError}
          petTypeRef={maleGenderRef}
          petZipcodeRef={petZipcodeRef}
          setPetGenderError={setPetGenderError}
          setPetTypeError={setPetTypeError}
          setTermsPolicyError={setTermsPolicyError}
          show={show}
          termsPolicyError={termsPolicyError}
          zipCodeError={zipCodeError}
          zipcodeFocusFlag={zipcodeFocusFlag}
        />
      </div>
    </FocusTrap>
  );
};

export { ModalAddPet };
