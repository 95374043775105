import React from 'react';
import './Toggle.css';

const Toggle = ({
  ariaProps,
  checked = false,
  children,
  className = '',
  id = '',
  labelClassName = '',
  name = '',
  onChange = () => { },
  readOnly = false,
}) => (
  <label
    className={`Toggle-wrapper ${className}`}
    htmlFor={id}
  >
    <span className="Toggle">
      <input
        {...ariaProps}
        aria-labelledby={`${id}-label`}
        checked={checked}
        className="Toggle-input"
        id={id}
        name={name}
        onChange={onChange}
        readOnly={readOnly}
        role="switch"
        type="checkbox"
      />

      <span className="Toggle-slidebar" />

      <span className="Toggle-slider" />
    </span>

    <p className={`Toggle-content ${labelClassName}`} id={`${id}-label`}>
      {children}
    </p>
  </label>
);

export default Toggle;
