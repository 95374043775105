import { useEffect, useMemo, useState } from 'react';
import { loadEmbeddedScript } from '../util';

const useInjectScript = ({ id, src, force = false }) => {
  const [isLoaded, setLoaded] = useState(false);
  const scriptSrc = useMemo(() => (src), [src]);
  const scriptId = useMemo(() => (id), [id]);
  const scriptAsync = true;
  const scriptDefer = true;

  function onLoad() {
    setLoaded(true);
  }

  useEffect(() => {
    loadEmbeddedScript({
      onLoadCallback: onLoad,
      scriptAsync,
      scriptDefer,
      scriptId,
      scriptSrc,
    });

    return () => {
      if (force) {
        document.getElementById(scriptId).remove();
      }
    };
  }, [scriptAsync, scriptDefer, scriptId, scriptSrc]);

  return isLoaded;
};

export { useInjectScript };
