import React, { useMemo } from 'react';
import errorIcon from '../../assets/error.svg';
import succesIcon from '../../assets/done-success.svg';
import infoIcon from '../../assets/info-i.svg';
import './FigoInputMessage.css';

const TYPE = {
  error: 'error',
  info: 'info',
  success: 'success',
};

const FigoInputMessage = ({ message = '', type = TYPE.error }) => {
  const className = useMemo(() => `Figo-input-message-${type
    || 'error'}`, [type]);
  const icon = useMemo(() => {
    switch (type) {
      case TYPE.info:
        return infoIcon;
      case TYPE.success:
        return succesIcon;
      case TYPE.error:
      default:
        return errorIcon;
    }
  }, [type]);
  return (
    <div aria-live="polite" className={className}>
      {message ? (
        <>
          <img alt="" src={icon} />

          {message}
        </>
      ) : null}
    </div>
  );
};

export { FigoInputMessage };
