import React, { useState, useEffect } from 'react';
import { useCalculatePlanPrice, usePrevious } from '../../hooks';
import { delay } from '../../util';

import './PriceLog.css';

const PriceLog = React.memo(() => {
  const { quoteSelected } = useCalculatePlanPrice();

  const { petName, totalMontly } = quoteSelected;
  const [label, setLabel] = useState('');
  const prevTotal = usePrevious(totalMontly);

  useEffect(() => {
    const prevPetName = petName;
    if (!prevTotal) {
      setLabel('');
    } else if (petName !== prevPetName || prevTotal !== totalMontly) {
      setLabel(`Updated premium for ${quoteSelected.petName}
          is ${totalMontly} per month`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalMontly, petName]);

  useEffect(() => {
    const cleanLog = async () => {
      await delay(3000);
      setLabel('');
    };
    if (label.length) {
      cleanLog();
    }
  }, [label]);

  return (
    <div aria-live="polite" className="Price-log" role="log">
      {label}
    </div>
  );
});

export { PriceLog };
