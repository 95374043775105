import React from 'react';
import './Shimmer.css';
import TouchableDiv from './TouchableDiv';

const Shimmer = (props = { visible: true }) => {
  function isVisible() {
    if (props.visible) {
      return `${props.className} Shimmer`;
    }

    return props.className || '';
  }

  function styles() {
    if (!props.visible) {
      return {};
    }

    const style = { borderRadius: props.borderRadius };

    if (props.height) {
      style.height = props.height;
    }

    if (props.width) {
      style.width = props.width;
    }

    return style;
  }

  switch (props.element) {
    case 'span': {
      return (
        <span
          aria-busy={props.visible}
          className={isVisible()}
          style={styles()}
          {...props.ariaProps}
        >
          {props.children}
        </span>
      );
    }

    case 'touchableDiv': {
      return (
        <TouchableDiv
          ariaProps={{
            ...props.ariaProps,
            'aria-busy': props.visible,
          }}
          className={isVisible()}
          compRef={props.compRef}
          id={props.id}
          onClick={props.onClick}
          onKeyDown={props.onKeyDown}
          onKeyPress={props.onKeyPress}
          style={styles()}
        >
          {props.children}
        </TouchableDiv>
      );
    }

    default: return (
      <div
        aria-busy={props.visible}
        className={isVisible()}
        id={props.id}
        style={styles()}
        {...props.ariaProps}
      >
        {props.children}
      </div>
    );
  }
};

Shimmer.defaultProps = {
  borderRadius: 0,
  height: 0,
  visible: true,
  width: 0,
};

export default Shimmer;
