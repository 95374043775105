import React from 'react';
import selectedRadio from '../../assets/radio-selected.svg';
import deselectedRadio from '../../assets/radio-deselected.svg';
import './RadioButton.css';

const RadioButton = ({
  children,
  name = '',
  checked = false,
  onChange = () => { },
}) => (
  // Disabled rule 'cause this structure replace the htmlFor attr
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className="Radio-button" id={`${name}-label`}>
    <img
      alt=""
      className="Radio-button-img"
      src={checked ? selectedRadio : deselectedRadio}
    />

    <input
      checked={checked}
      id={name}
      name={name}
      onChange={onChange}
      type="radio"
    />

    {children}
  </label>
);

export { RadioButton };
