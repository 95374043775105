import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Feature } from './Feature';
import './AppPresentation.css';
import { Phone } from './Phone';

const AppPresentation = () => {
  const { t } = useTranslation('landing');
  return (
    <div className="App-presentation">
      <div className="App-presentation-intro">
        <h2 className="Section-title App-presentation-title">
          <Trans
            components={[<br aria-hidden />]}
            i18nKey="app.title"
            ns="landing"
          />
        </h2>

        <p className="App-presentation-text">
          {t('app.intro')}
        </p>
      </div>

      <div className="App-presentation-phone">
        <Phone />
      </div>

      <div className="App-presentation-images-container">
        {t('app.features', { returnObjects: true }).map((feature) => (
          <Feature
            key={feature.name}
            text={feature.text}
            title={feature.name}
          />
        ))}
      </div>
    </div>
  );
};

export { AppPresentation };
