import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useCustomPlanPills } from '../../../util';
import {
  changeDeductibleLegend,
  saveDeductibleSelected,
  saveSelectedReimbursement,
  selectedPlan,
} from '../../../actions';

import { SelectorCombo } from './SelectorCombo';
import './DeductibleReimbursement.css';

const ULTIMATE_PLAN = 2;

export const DeductibleReimbursement = ({
  containerClass = '',
  isEssentialSelected = false,
  plans,
  quoteSelected,
}) => {
  const dispatch = useDispatch();
  const store = useSelector(({ quoting }) => quoting);
  const { t } = useTranslation('quoting');

  const [deductibles, setDeductibles] = useState([]);
  const [reimbursements, setReimbursements] = useState([]);

  const plansItems = useMemo(
    () => (plans.map((item) => ({
      ...item,
      id: item.Plan,
      value: item.MaxAnnual,
    }))),
    [plans],
  );

  const isUltimateSelected = quoteSelected.selectedPlanIndex
    === ULTIMATE_PLAN;

  const updateDeductible = useCallback(({ id, value }) => {
    dispatch(saveDeductibleSelected({ description: value, id }));
  }, [dispatch]);

  const updateReimbursement = useCallback(() => {
    const plan = plans.find((item) => item.Plan === quoteSelected.selectedPlan);

    const ratingOptions = plan.RatingOptions
      .filter((rating) => rating.DeductibleId === quoteSelected.deductibleId);
    const reimbursementsFilter = ratingOptions
      .filter((item) => item.DeductibleId === quoteSelected.deductibleId)
      .map((item) => ({
        available: !item.Invalid,
        description: item.ReimbursementName,
        id: item.ReimbursementId,
      }));
    const reimbursementAvailable = reimbursementsFilter
      .reverse().find((item) => item.available);
    const { description, id } = reimbursementAvailable;

    dispatch(saveSelectedReimbursement({ description, id }));
  }, [dispatch, plans, quoteSelected.selectedPlan, quoteSelected.deductibleId]);

  const onReimbursementChange = useCallback(({ id, label }) => {
    dispatch(saveSelectedReimbursement({ description: label, id }));
  }, [dispatch]);

  const onDeductibleChange = useCallback(({ id, label }) => {
    updateDeductible({ id, value: label });
  }, [updateDeductible]);

  const onCoverageChange = useCallback(({ MaxAnnual, Plan }) => {
    dispatch(selectedPlan({
      planAmount: MaxAnnual,
      planId: Plan,
      planPackagedId: -1,
    }));
  }, [dispatch]);

  useEffect(() => {
    if (plans && !plans.length) {
      return;
    }

    const {
      deductibleId,
      reimbursementId,
      reimbursementPercentage,
    } = quoteSelected;

    const plan = plans.find((item) => item.Plan === quoteSelected.selectedPlan);

    const ratingOptions = plan.RatingOptions
      .filter((rating) => rating.ReimbursementId === reimbursementId);
    const deductiblesFilter = ratingOptions
      .filter((item) => item.ReimbursementId === reimbursementId)
      .map((item) => ({
        available: !item.Invalid,
        disabled: item.Invalid,
        id: item.DeductibleId,
        value: item.DeductibleName,
      }));
    const deductiblesAvailables =
      deductiblesFilter.filter((item) => item.available);
    const currentDeductible =
      deductiblesFilter.find((item) => item.id === deductibleId) || {};
    let deductibleLegend = '';

    if (!deductiblesAvailables.length) {
      updateReimbursement();
      return;
    }

    if (deductiblesAvailables.length < deductiblesFilter.length) {
      const names = deductiblesAvailables.map((item) => item.value);

      let nameLegend = '';

      if (names.length === 1) {
        nameLegend = names.join(t(' and '));
      } else {
        names.forEach((item, index) => {
          const concat = names.length - 1 === index ? ' and ' : ', ';

          nameLegend = nameLegend
            ? `${nameLegend}${concat}${item}` : ` ${item}`;
        });
      }

      deductibleLegend = `${reimbursementPercentage} `
        + 'reimbursement is available with deductibles of'
        + ` ${nameLegend}`;
    }

    dispatch(changeDeductibleLegend(deductibleLegend));

    if (!currentDeductible.available) {
      updateDeductible(
        [...deductiblesFilter].reverse().find((item) => item.available),
      );
    }

    setDeductibles(deductiblesFilter);
  }, [
    dispatch,
    plans,
    quoteSelected,
    t,
    updateDeductible,
    updateReimbursement,
  ]);

  useEffect(() => {
    if (plans && !plans.length) {
      return;
    }

    const plan = plans.find((item) => item.Plan === quoteSelected.selectedPlan);

    const ratingOptions = plan.RatingOptions
      .filter((rating) => rating.DeductibleId === quoteSelected.deductibleId);
    const reimbursementsFilter = ratingOptions
      .filter((item) => item.DeductibleId === quoteSelected.deductibleId)
      .map((item) => ({
        disabled: item.Invalid,
        id: item.ReimbursementId,
        value: item.ReimbursementName,
      }));

    // FIX ME, fix some plan (ultimate), blocking 100% reimbursement
    // const lastReimbursement =
    // reimbursementsFilter[reimbursementsFilter.length - 1] || {};
    // const reimbursement100Locked = plan.RatingOptions
    //   .filter((rating) => rating.ReimbursementId === lastReimbursement.id)
    //   .reduce((prev, next) => prev && next.Invalid, true);

    // setValidateSelector(!reimbursement100Locked);
    // FIX ME, fix some plan (ultimate), blocking 100% reimbursement

    setReimbursements(reimbursementsFilter);
  }, [plans, quoteSelected.deductibleId, quoteSelected.selectedPlan]);

  const headsUpClass = useCustomPlanPills
    ? 'Deductible-legend' : 'Prepackaged-plan-hands-up';

  if (!quoteSelected.selectedPlanPackaged
    || quoteSelected.selectedPlanPackaged > 0) {
    return isEssentialSelected ? (
      <p aria-live="polite" className={headsUpClass}>
        {t('prepackagedPlans.headsUp')}
      </p>
    ) : null;
  }

  const deductibleLegendClass = useCustomPlanPills
    ? 'Deductible-legend' : 'Deductible-legend-text';

  const deductibleLegendContainer = useCustomPlanPills
    ? 'Deductible-legend-div' : 'Deductible-legend-text-div';

  return (
    <>
      <div
        className={useCustomPlanPills
          ? 'Deductible-reimbursement-container'
          : 'DeductibleReimbursement-container'}
      >
        <div
          className={'DeductibleReimbursement-container-div'
            + `${useCustomPlanPills
              ? ' Deductible-reimbursement-wrapper' : ''} ${containerClass}`}
        >
          <SelectorCombo
            items={plansItems}
            onChange={onCoverageChange}
            title={t('prepackagedPlans.annualCoverage')}
            tooltipDescription={t('prepackagedPlans.annualCoverageToooltip')}
            value={{
              label: quoteSelected.selectedPlanAmount,
              value: quoteSelected.selectedPlan,
            }}
          />

          {useCustomPlanPills && (
            <p aria-live="polite" className={headsUpClass}>
              {isEssentialSelected && t('prepackagedPlans.headsUp')}
            </p>
          )}

          <SelectorCombo
            items={deductibles}
            onChange={onDeductibleChange}
            title={t('prepackagedPlans.deductible')}
            tooltipDescription={t('prepackagedPlans.deductibleTooltip')}
            value={{
              label: quoteSelected.deductibleQuantity,
              value: quoteSelected.deductibleId,
            }}
          />

          {useCustomPlanPills && (
            <span aria-live="polite" className={deductibleLegendClass}>
              {store.deductibleLegend}
            </span>
          )}

          <SelectorCombo
            items={reimbursements}
            onChange={onReimbursementChange}
            title={t('prepackagedPlans.reimbursement')}
            tooltipDescription={t('prepackagedPlans.reimbursementTooltip')}
            value={{
              label: quoteSelected.reimbursementPercentage,
              value: quoteSelected.reimbursementId,
            }}
          />

          {useCustomPlanPills && (
            <span aria-live="polite" className={deductibleLegendClass}>
              {isUltimateSelected
                && t('prepackagedPlans.100ReimbursementNoAvailable')}
            </span>
          )}
        </div>
      </div>

      {!useCustomPlanPills && (
        <>
          <p aria-live="polite" className={headsUpClass}>
            {isEssentialSelected && t('prepackagedPlans.headsUp')}
          </p>

          <div className={deductibleLegendContainer}>
            <span aria-live="polite" className={deductibleLegendClass}>
              {store.deductibleLegend}
            </span>

            <span aria-live="polite" className={deductibleLegendClass}>
              {isUltimateSelected
                && t('prepackagedPlans.100ReimbursementNoAvailable')}
            </span>
          </div>
        </>
      )}
    </>
  );
};
