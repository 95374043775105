import React from 'react';
import './Step.css';

const Step = ({
  icon,
  text = '',
  title = '',
}) => (
  <div className="Step">
    <img alt="" src={icon} />

    <div>
      <h3>{title}</h3>

      <p>{text}</p>
    </div>
  </div>
);

export { Step };
