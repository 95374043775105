import { useEffect, useState } from 'react';

const useOnBodyScrollEvent = () => {
  const [verticalScrollValue, setVerticalScrollValue] = useState(0);

  useEffect(() => {
    if (!document.body.onscroll) {
      document.body.onscroll = () => {
        const newValue = document.body.getBoundingClientRect().top;
        setVerticalScrollValue(newValue);
      };
    }

    return () => {
      document.body.onscroll = null;
    };
  }, []);

  return {
    isOnTopScreen: verticalScrollValue === 0,
    verticalScrollValue,
  };
};

export { useOnBodyScrollEvent };
