import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createNewLiveVetAccount,
  resetLiveVetAccess,
  validateLiveVetGetQuote,
} from '../../actions';
import chatIcon from '../../assets/chat-icon.svg';
import './ModalLiveVetSubscription.css';
import FigoLottie from '../common/FigoLottie';
import { URL_LIVE_VET_YOUR_INFO } from '../../routes';

const ModalLiveVetSubscription = ({ email, onDismiss }) => {
  const history = useHistory();
  const location = useLocation();
  const store = useSelector(({ quoting }) => quoting);
  const [buyLiveVet, setCanBuyLiveVet] = useState(false);
  const {
    liveVetData,
    validatingLiveVetAccess,
  } = store;
  const dispatch = useDispatch();
  const isYourInfoLiveVet = useMemo(() => location.pathname
    .includes(URL_LIVE_VET_YOUR_INFO), [location]);

  const canBuyLiveVet = useMemo(() => (!liveVetData || buyLiveVet),
    [liveVetData, buyLiveVet]);

  function handleDissmiss() {
    dispatch(resetLiveVetAccess());
    onDismiss();
  }

  function navigateToYourInfo() {
    if (!isYourInfoLiveVet) {
      handleDissmiss();
      history.push(URL_LIVE_VET_YOUR_INFO);
    }
  }

  const navigateToBuyLiveVet = useCallback(navigateToYourInfo, []);

  useEffect(() => {
    if (!liveVetData) {
      return;
    }

    setCanBuyLiveVet(!liveVetData.HasAccess);
  }, [liveVetData]);

  useEffect(() => {
    if (!liveVetData || !buyLiveVet) {
      return;
    }

    navigateToBuyLiveVet();
  }, [buyLiveVet, canBuyLiveVet, liveVetData, navigateToBuyLiveVet]);

  function handlePrimaryButtonClick() {
    if (!canBuyLiveVet) {
      handleDissmiss();
      return;
    }

    if (!email) {
      navigateToBuyLiveVet();
    }

    dispatch(validateLiveVetGetQuote(email));
  }

  async function handleSecondaryButtonClick() {
    if (!canBuyLiveVet) {
      await dispatch(createNewLiveVetAccount());
    } else {
      handleDissmiss();
    }
  }

  function renderDescriptionText() {
    if (canBuyLiveVet) {
      return 'This service is included with all Figo policies, '
        + 'or it can be purchased without pet insurance.';
    }

    return 'Good news! With your existing Figo account, '
      + 'you already have access to Live Vet for all your pets.';
  }

  function renderPrimaryButtonText() {
    if (!canBuyLiveVet) {
      return 'Return to Quote';
    }

    if (validatingLiveVetAccess) {
      return (
        <FigoLottie
          height={30}
          width={30}
        />
      );
    }

    return 'Continue without Insurance';
  }

  function renderSecondaryButtonText() {
    if (!canBuyLiveVet) {
      return 'Create New Account';
    }

    return '↵ Return to Quote';
  }

  function renderSubtitleText() {
    if (!canBuyLiveVet) {
      return email;
    }

    return 'Waive Insurance Coverage';
  }

  return (
    <div className="Modal-live-vet-subscription-container">
      <img
        alt=""
        className="Modal-live-vet-subscription-bubbles-image"
        src={chatIcon}
      />

      <span className="Modal-live-vet-subscription-title">Live Vet 24/7</span>

      <span className="Modal-live-vet-subscription-subtitle">
        {renderSubtitleText()}
      </span>

      <p className="Modal-live-vet-subscription-description">
        {renderDescriptionText()}
      </p>

      <button
        aria-label="validate livet access"
        className="Modal-live-vet-subscription-primary-button"
        disabled={validatingLiveVetAccess}
        onClick={handlePrimaryButtonClick}
        title="validate livet access"
        type="button"
      >
        <span className="Modal-live-vet-subscription-primary-button-text">
          {renderPrimaryButtonText()}
        </span>
      </button>

      <button
        aria-label="livevet secondary"
        className="Modal-live-vet-subscription-secondary-button"
        disabled={validatingLiveVetAccess}
        onClick={handleSecondaryButtonClick}
        title="livevet secondary"
        type="button"
      >
        <span className="Modal-live-vet-subscription-secondary-button-text">
          {renderSecondaryButtonText()}
        </span>
      </button>
    </div>
  );
};

export default ModalLiveVetSubscription;
