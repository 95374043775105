import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { isProdEnv } from '../../util';

import Footer from '../common/conversion/Footer';
import './LandingFooter.css';
import { FooterLinks } from '../common/FooterLinks';

const LandingFooter = () => {
  const { t } = useTranslation('landing');

  return (
    <footer className="Landing-footer-container">
      <Footer className="Landing-footer-copy-right" />

      <div className="Landing-footer">
        <FooterLinks />

        <div className="Landing-footer-app-logo-container">
          <p className="Landing-footer-app-logo-text">
            <Trans
              components={{
                a: (
                  <a
                    aria-label={t('disclaimerLink')}
                    href="https://figopetinsurance.com/sample-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                ),
              }}
              i18nKey="disclaimer"
              ns="landing"
            />
          </p>
        </div>
      </div>

      <span aria-hidden="true" className={isProdEnv ? 'No-display' : ''}>
        {process.env.REACT_APP_NAME}
      </span>
    </footer>
  );
};

export default LandingFooter;
