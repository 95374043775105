import { apiCall } from '../util';
import {
  FEATURE_FLAGS_FAILED,
  FEATURE_FLAGS_LOADED,
  FEATURE_FLAGS_REQUESTED,
} from './types';

export function getFeatureFlags() {
  return async (dispatch) => {
    try {
      dispatch({ type: FEATURE_FLAGS_REQUESTED });
      const response = await apiCall('customer/FeatureFlag');
      const payload = await response.json();
      const d2cFlags = Object.entries(payload)
        .filter(([key]) => key.startsWith('QuoteSites')
          || key.startsWith('QuoteSiteEB'))
        .reduce((p, [k, v]) => ({ ...p, [k]: v }), {});
      dispatch({ payload: d2cFlags, type: FEATURE_FLAGS_LOADED });
    } catch (error) {
      dispatch({ payload: error, type: FEATURE_FLAGS_FAILED });
    }
  };
}
