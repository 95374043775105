import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetQuoteButton } from '../common';
import './LandingHeader.css';

const LandingHeader = ({ onGetQuote }) => {
  const store = useSelector((stores) => stores.quoting);
  const { t } = useTranslation('landing');
  let logoStyle = 'Landing-logo-image-common';
  const YUM_BRANDS_ID = 'd340149af5a14e19';
  const location = useLocation();
  const buttonRef = useRef(null);

  useEffect(() => {
    if (location.state && location.state.focus) {
      buttonRef.current.focus();
    }
  }, [location]);

  if (store.employerId === YUM_BRANDS_ID) {
    logoStyle = 'Landing-logo-image';
  }

  return (
    <>
      <div className="Landing-underwritted">
        {t('header.underwritten')}
      </div>

      <header className="Landing-employee-header">
        <img
          alt="Figo - United Health Group logo"
          className={logoStyle}
          src={store.landingLogo}
        />

        <div aria-hidden="true" className="Landing-get-quote-container">
          <GetQuoteButton
            onClick={onGetQuote}
            quoteRef={buttonRef}
            tabIndex={-1}
          />

          <span className="Landing-employee-text">
            {store.discountMessage}
          </span>
        </div>
      </header>
    </>
  );
};

export { LandingHeader };
