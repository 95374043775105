import React, { useEffect, useRef } from 'react';
import Player from '@vimeo/player';

const VIMEO_PLAYER_URL = 'https://player.vimeo.com/video';

const VimeoVideo = ({
  autoplay = false,
  controls = false,
  hash = '',
  id = '',
  loop = false,
  muted = false,
  width = 100,
}) => {
  const videoPlayerRef = useRef();
  const containerId = `vimeo-${id}`;

  useEffect(() => {
    const video = hash
      ? { url: `${VIMEO_PLAYER_URL}/${id}?h=${hash}` }
      : { id };
    const options = {
      ...video,
      autoplay,
      byline: false,
      controls,
      loop,
      muted,
      portrait: false,
      responsive: true,
      title: false,
      width,
    };
    videoPlayerRef.current = new Player(containerId, options);
  }, [id, width, muted, autoplay, containerId, hash, controls, loop]);

  return (
    <div id={containerId} />
  );
};

export { VimeoVideo };
