import { apiCall } from '../util';
import {
  ONE_INC_PAYMENT_BANK_SAVED,
  ONE_INC_PAYMENT_CREDIT_CARD_SAVED,
  ONE_INC_PAYMENT_SAVED,
  ONE_INC_PAYMENT_UPDATED,
  ONE_INC_SESSION_CREATED,
  ONE_INC_SESSION_REMOVED,
  ONE_INC_PET_PAYMENT_METHOD_SAVED,
  ONE_INC_PET_PAYMENT_UPDATED,
  ONE_INC_PAYMENT_DEFAULTS_SAVED,
  ONE_INC_RESET_ALL,
} from './types';
import { ONE_INC_PAYMENT_CATEGORY } from '../constants';

export function removeOneIncSession() {
  return { type: ONE_INC_SESSION_REMOVED };
}

export function createOneIncSession() {
  return async (dispatch) => {
    const request = await apiCall('api/Payments/getOneIncSessionId');
    const response = await request.json();

    if (response.IsValid) {
      dispatch({
        portalOneSessionKey: response.Data.Result.portalOneSessionKey,
        type: ONE_INC_SESSION_CREATED,
      });
    }
  };
}

export function savePaymentMethod(data) {
  const isCreditCard =
    data.paymentCategory === ONE_INC_PAYMENT_CATEGORY.creditCard;
  const type = isCreditCard
    ? ONE_INC_PAYMENT_CREDIT_CARD_SAVED : ONE_INC_PAYMENT_BANK_SAVED;

  return {
    data: {
      ...data,
      paymentCategory: isCreditCard
        ? ONE_INC_PAYMENT_CATEGORY.creditCard
        : ONE_INC_PAYMENT_CATEGORY.bankAccount,
    },
    type,
  };
}

export function savePaymentMethods(data) {
  return { data, type: ONE_INC_PAYMENT_SAVED };
}

export function updatePaymentMethods(data) {
  return { data, type: ONE_INC_PAYMENT_UPDATED };
}

export function saveOneIncPetPaymentMethod(data) {
  return { data, type: ONE_INC_PET_PAYMENT_METHOD_SAVED };
}

export function changeOneIncPaymentUpdate(data) {
  return { data, type: ONE_INC_PET_PAYMENT_UPDATED };
}

export function savePaymentDefaultMethods(data) {
  return { data, type: ONE_INC_PAYMENT_DEFAULTS_SAVED };
}

export function resetOneInInformation() {
  return { type: ONE_INC_RESET_ALL };
}
