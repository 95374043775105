import React from 'react';
import { useTranslation } from 'react-i18next';

import susansCat from '../../../assets/landing/susans-cat.png';
import quotesIcon from '../../../assets/landing/double-quote.svg';
import { SectionTitle } from '../common';
import './ParentsReview.css';

const ParentsReview = () => {
  const { t } = useTranslation('landing');

  return (
    <>
      <SectionTitle
        className="Parents-review-title"
        title={t('parentsReview.title')}
      />

      <div className="Parents-review-container">
        <img alt="" className="" src={susansCat} />

        <div className="Parent-review">
          <img alt="" src={quotesIcon} />

          <p className="Parent-review-quote">
            {t('parentsReview.review')}
          </p>

          <p className="Parent-review-parent">
            {t('parentsReview.parent')}
          </p>
        </div>
      </div>
    </>
  );
};

export { ParentsReview };
