import { useCallback, useEffect, useState } from 'react';

const TABLET_WIDTH = 1200;
const MOBILE_WIDTH = 568;

const useResponsive = () => {
  const [isTablet, setIsTablet] = useState(window.outerWidth > MOBILE_WIDTH
    && window.outerWidth <= TABLET_WIDTH);
  const [isMobile, setIsMobile] = useState(window.outerWidth <= MOBILE_WIDTH);

  const handleResize = useCallback(() => {
    setIsTablet(window.outerWidth > MOBILE_WIDTH
      && window.outerWidth <= TABLET_WIDTH);
    setIsMobile(window.outerWidth <= MOBILE_WIDTH);
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize',
      handleResize);

    return () => {
      window.removeEventListener('resize',
        handleResize);
    };
  }, [handleResize]);

  return ({
    isMobile,
    isTablet,
  });
};

export { useResponsive };
