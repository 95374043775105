import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { closeErrorModal } from '../../actions';
import ModalContainer from './ModalContainer';
import ModalError from './ModalError';
import { useB2CInfo } from '../../hooks';

const GenericErrorModalContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { logoutB2C } = useB2CInfo();
  const store = useSelector(({ quoting }) => quoting);
  const { message, parameters } = store;

  function handleErrorModalOutClick() {
    dispatch(closeErrorModal());

    if (!parameters?.empGuid) {
      window.location.reload(false);
    }

    const landingUrl = `/getQuote?EmpId=${parameters?.empGuid}`;

    history.push(landingUrl, {
      focus: true,
    });

    logoutB2C({ logoutUrl: landingUrl });
  }

  return (
    <ModalContainer
      cancellable
      handleOutClick={handleErrorModalOutClick}
      idTitle="genericError"
      show={message}
    >
      <ModalError
        buttonText="Got It"
        onButtonClick={handleErrorModalOutClick}
      />
    </ModalContainer>
  );
};

export default GenericErrorModalContainer;
