import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';

import collapsableIcon from '../../../assets/landing/collapsable-icon.svg';
import { useResponsive } from '../../../hooks';
import { useResizeScreen } from '../../../hooks/useResizeScreen';

import './CollapsibleQuestion.css';

const CollapsibleQuestion = ({
  children,
  collapsed = false,
  id = 'question',
  onClick = () => { },
  question = '',
}) => {
  const [height, setHeight] = useState(0);
  const ref = useRef();
  const { isMobile } = useResponsive();

  const { screenHeight, screenWidth } = useResizeScreen();

  useEffect(() => {
    setHeight(ref.current.scrollHeight);
  }, [screenHeight, screenWidth]);

  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  const newHeight = useMemo(() => (isMobile
    ? height + 44 : height), [isMobile, height]);

  return (
    <div className="Question-container">
      <button
        aria-controls={id}
        aria-expanded={!collapsed}
        className="Question"
        onClick={handleClick}
        type="button"
      >
        {question}

        <img alt="" src={collapsableIcon} />
      </button>

      <div
        ref={ref}
        aria-hidden={collapsed}
        className="Question-answer"
        data-collapsed={collapsed}
        id={id}
        style={collapsed ? { height: 0 } : { height: newHeight }}
      >
        {children}
      </div>
    </div>
  );
};

export { CollapsibleQuestion };
