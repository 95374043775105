import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ContinueToPaymentsArrow from '../../../assets/arrow-right-citron.svg';
import ContinueStep from '../../../assets/arrow-down-white.svg';

import TouchableDiv from '../../common/TouchableDiv';
import FigoLottie from '../../common/FigoLottie';
import { PurchaseStickyBar } from './PurchaseStickyBar';

import {
  forceValdationDiamonUser,
  validateDiamondUser,
  validateRecaptchaToken,
} from '../../../actions';
import { resetOneInInformation } from '../../../actions/oneInc';
import {
  URL_PURCHASE,
  URL_PURCHASE_ERROR,
  URL_PURCHASE_SUCCESS_ERROR,
  URL_ROOT_QUOTE,
  URL_YOUR_INFO,
} from '../../../routes';
import { useB2CInfo, useInjectScript, usePurchase } from '../../../hooks';

import './YourInfoFooter.css';
import { useROPC } from '../../../util';

const ORIGIN_EB = 2;
// https://developers.google.com/recaptcha/docs/v3
const RECAPTCHA_SRC = 'https://www.google.com/recaptcha/api.js';

const YourInfoFooter = ({
  handleContinuePurchaseClick = () => { },
  isPurchaseValid = false,
  isSecondStep = false,
  petCloudLoginSuccess,
}) => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();
  const { doPurchase } = usePurchase();
  const {
    customerValidationLoading,
    purchase,
    recaptcha,
    searchParameters,
    sessionInformation,
  } = store;
  const { isCustomer, nopCommerceUser, userEmail } = sessionInformation;
  const { loginB2C } = useB2CInfo();

  const scriptRecaptchaLoaded = useInjectScript({
    force: true,
    id: 'reCAPTCHAv3',
    src: RECAPTCHA_SRC,
  });

  useEffect(() => {
    if (purchase.error && !purchase.success) {
      history.push(URL_PURCHASE_ERROR);
    }

    if (!purchase.error && purchase.success) {
      const goToPurchaseScreen = async () => {
        const url = purchase.incompletePurchases?.length > 0
          ? URL_PURCHASE_SUCCESS_ERROR : URL_PURCHASE;

        await dispatch(resetOneInInformation());
        history.push(url);
      };

      goToPurchaseScreen();
    }
  }, [
    dispatch,
    history,
    purchase.error,
    purchase.success,
    purchase.incompletePurchases,
  ]);

  const handleReturnToPlansClick = () => {
    const url = `${URL_ROOT_QUOTE}${searchParameters}`;
    history.push(url);
  };

  function renderContentButton() {
    const title = isSecondStep ? 'Purchase' : 'Continue';
    const className = isSecondStep ? 'Your-info-continue-to-payments-arrow'
      : 'Your-info-continue-to-step-arrow';
    const iconStep = isSecondStep ? ContinueToPaymentsArrow : ContinueStep;

    if (customerValidationLoading || recaptcha.loading) {
      return (
        <FigoLottie
          height={30}
          width={30}
        />
      );
    }

    return (
      <>
        <span className="Your-info-continue-to-payments-label">{title}</span>

        <img
          alt=""
          className={className}
          src={iconStep}
        />
      </>
    );
  }

  function forceNopCommerUser() {
    dispatch(forceValdationDiamonUser(userEmail));
  }

  const onContinueWithoutLoginClick = ({ url = '' }) => () => {
    history.push(url);
  };

  function handleOnPurchaseQuote() {
    const {
      newCustomer,
    } = store;
    const { email, emailValidated } = newCustomer;

    if (!emailValidated) {
      dispatch(validateDiamondUser({
        email,
      }));
      return;
    }

    if (handleContinuePurchaseClick && !customerValidationLoading) {
      handleContinuePurchaseClick();
    }

    if (!isSecondStep && !petCloudLoginSuccess) {
      return;
    }

    if (!isSecondStep || !isPurchaseValid) {
      return;
    }

    if (!nopCommerceUser.LoginSuccess && isCustomer && !useROPC) {
      forceNopCommerUser();

      loginB2C({
        continueWithoutLogin:
          onContinueWithoutLoginClick({ url: URL_YOUR_INFO }),
        existingEmail: userEmail,
        urlState: URL_YOUR_INFO,
      });

      return;
    }

    if (!isPurchaseValid || store.purchase.loading) {
      return;
    }

    doPurchase();
  }

  async function onSubmit(token) {
    const { loading } = recaptcha;

    if (loading) {
      return;
    }

    const isValidToken = await dispatch(validateRecaptchaToken({
      origin: ORIGIN_EB,
      token,
      user: userEmail,
    }));

    if (isValidToken) {
      handleOnPurchaseQuote();
    }
  }

  useEffect(() => {
    window.onSubmit = onSubmit;
  }, [store]);

  function renderContinuePurchasePaymentButton() {
    if (isSecondStep && !store.oneInc.tokenId) {
      return null;
    }

    if (!scriptRecaptchaLoaded) {
      return null;
    }

    return (
      <>
        {isSecondStep
          ? (
            <button
              className="Your-info-continue-to-payments-button"
              id="continueToPaymentsBtn"
              onClick={handleOnPurchaseQuote}
              type="button"
            >
              {renderContentButton()}
            </button>
          )
          : (
            <button
              className="g-recaptcha Your-info-continue-to-payments-button"
              data-action="submit"
              data-callback="onSubmit"
              data-sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              id="continueToPaymentsBtn"
              type="submit"
            >
              {renderContentButton()}
            </button>
          )}
      </>
    );
  }

  if (isSecondStep) {
    return (
      <PurchaseStickyBar
        disabled={!isPurchaseValid}
        onPurchaseClick={isSecondStep
          ? handleOnPurchaseQuote : handleContinuePurchaseClick}
      />
    );
  }

  return (
    <>
      <div
        className={'Your-info-footer-container'
          + ' Your-info-footer-container-enrollment'}
      >
        <div className="Your-info-footer-buttons-container">
          <TouchableDiv
            className={'Your-info-return-to-plans-button'
              + ' Your-info-return-to-plans-button-enrollment'}
            id="returnToPlansBtn"
            onClick={handleReturnToPlansClick}
          >
            <span
              aria-hidden="true"
              className="Your-info-return-to-plans-label"
            >
              ↵ &nbsp;
            </span>

            <span className="Your-info-return-to-plans-label">
              Back to Customize
            </span>
          </TouchableDiv>

          {renderContinuePurchasePaymentButton()}
        </div>
      </div>
    </>
  );
};

export { YourInfoFooter };
