import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import loadingSpinner from '../../../assets/spinner.json';
import ios from '../../../assets/purchase/ios-navy.svg';
import google from '../../../assets/purchase/google-navy.svg';
import qr from '../../../assets/purchase/qr.svg';

import { sendSMSAppsLinks } from '../../../actions';
import { phoneMask, testOnlyNumber } from '../../../util';
import { APP_STORE_URL, GOOGLE_PLAY_URL } from '../../../constants';
import FigoLottie from '../../common/FigoLottie';
import './PetCloudSection.css';
import { useQuery } from '../../../hooks';

const PetCloudSection = ({ isFullySuccess = true, isCardFooter = false }) => {
  const store = useSelector(({ quoting }) => quoting);
  const {
    newCustomer: { phoneNumber },
    purchase: {
      sendingMessage,
    },
  } = store;

  const { liveVet, phone } = useQuery();
  const [messageSent, setMessageSent] = useState(false);
  const [
    inputPhoneValue,
    setInputPhoneValue] = useState(() => phoneMask(phoneNumber));
  const { t } = useTranslation('purchaseResult');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!liveVet) {
      return;
    }

    setInputPhoneValue(phoneMask(phone || phoneNumber));
  }, [liveVet, phone, phoneNumber]);

  function sendAppsLinks() {
    if (messageSent) {
      return;
    }

    const toPhone = phoneMask(inputPhoneValue);
    dispatch(sendSMSAppsLinks({ toPhone }));
    setMessageSent(true);
  }

  function onChangeHandlePhone(event) {
    const { value } = event.target;
    const sanitizeText = value ? value.replace(/-/g, '') : '';
    const onlyNumbersValue = testOnlyNumber(sanitizeText)
      ? sanitizeText
      : sanitizeText.replace(/\D/g, '');

    setInputPhoneValue(phoneMask(onlyNumbersValue));
  }

  const getAriaLabelSendMessage = () => {
    let label = t('textMe');

    if (sendingMessage) {
      label = t('loading');
    } else if (messageSent) {
      label = t('messageSent');
    }

    return label;
  };

  const renderLoadingOrButton = () => {
    if (sendingMessage) {
      return (
        <div className="Pet-cloud-section-text-me-a-link-complete">
          <FigoLottie animationData={loadingSpinner} height={60} width={60} />
        </div>
      );
    }

    if (messageSent) {
      return (
        <span
          className="Pet-cloud-section-text-me-a-link-complete"
          id="sendMeLink"
        >
          {t('messageSent')}
        </span>
      );
    }

    return (
      <span className="Pet-cloud-section-text-me-a-link" id="sendMeLink">
        {t('textMe')}
      </span>
    );
  };

  return (
    <div
      className={'Pet-cloud-section '
        + `${isCardFooter ? 'Pet-cloud-section-as-card' : ''}`}
    >
      <h3>{t('petCloudApp')}</h3>

      <div
        className="Pet-cloud-section-content"
        style={{ flexDirection: isFullySuccess ? 'column' : 'row' }}
      >
        <div className="Pet-cloud-section-sms">
          <p className="Pet-cloud-section-setup">{t('setup')}</p>

          <div
            className={'Pet-cloud-section-text-me'
              + `${!isCardFooter ? ' Pet-cloud-text-me-as-card' : ''}`}
          >
            <input
              aria-label="Phone Number"
              className="Pet-cloud-section-input-phone"
              disabled={messageSent}
              maxLength={12}
              onChange={onChangeHandlePhone}
              type="text"
              value={inputPhoneValue}
            />

            <button
              aria-disabled={messageSent}
              aria-label={getAriaLabelSendMessage()}
              aria-live="assertive"
              className="Pet-cloud-section-button-no-style"
              onClick={sendAppsLinks}
              type="button"
            >
              {renderLoadingOrButton()}
            </button>
          </div>
        </div>

        <div className="Pet-cloud-section-options">
          <div
            className={'Pet-cloud-section-desktop-login'
              + `${isCardFooter ? ' Pet-cloud-login-as-footer' : ''}`}
          >
            <span className="Pet-cloud-section-desktop-text">
              {t('loginAt')}&nbsp;
            </span>

            <a
              className="Pet-cloud-section-desktop-link"
              href={process.env.REACT_APP_PETCLOUD_URL}
            >
              mypetcloud.com
            </a>
          </div>

          <div
            className={'Pet-cloud-section-apps '
              + `${!isCardFooter ? 'Pet-cloud-apps-as-card' : ''}`}
            style={{ flexDirection: isFullySuccess ? '' : 'row-reverse' }}
          >
            <div className="Pet-cloud-section-stores">
              <div className="Pet-cloud-section-google-play">
                <a
                  aria-label={t('downloadApp', { store: 'Google play Store' })}
                  href={GOOGLE_PLAY_URL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    alt=""
                    src={google}
                  />
                </a>
              </div>

              <div className="Pet-cloud-section-app-store">
                <a
                  aria-label={t('downloadApp', { store: 'Apple Store' })}
                  href={APP_STORE_URL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    alt=""
                    src={ios}
                  />
                </a>
              </div>
            </div>

            <img alt="" src={qr} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { PetCloudSection };
