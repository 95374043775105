import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  INSURED_DISCLOSURES_LINK,
  PRIVACY_POLICY_LINK,
  SAMPLE_POLICY_LINK,
  TERMS_OF_USE_LINK_GENERAL,
  UNDERWRITING_LINK,
  WAITING_PERIODS_LINK,
} from '../../constants';
import './FooterLinks.css';

const FooterLink = ({
  ariaLabel = '',
  href = '',
  rel = 'noopener noreferrer',
  target = '_blank',
  title = '',
}) => (
  <a
    aria-label={`${ariaLabel || `${title} opens in a new window`}`}
    className="Footer-link"
    href={href}
    rel={rel}
    target={target}
  >
    {title}
  </a>
);

const FooterLinks = ({ className = '', extraLinks = [] }) => {
  const { t } = useTranslation('common');
  const links = useMemo(() => {
    const defaultLinks = [
      {
        href: INSURED_DISCLOSURES_LINK,
        title: t('footer.insurerDisclosures'),
      },
      {
        href: PRIVACY_POLICY_LINK,
        title: t('footer.privacy'),
      },
      {
        ariaLabel: t('footer.termsAria'),
        href: TERMS_OF_USE_LINK_GENERAL,
        title: t('footer.terms'),
      },
      {
        href: UNDERWRITING_LINK,
        title: t('footer.underwriting'),
      },
      {
        href: WAITING_PERIODS_LINK,
        title: t('footer.waitingPeriod'),
      },
      {
        href: SAMPLE_POLICY_LINK,
        title: t('footer.samplePolicy'),
      },
    ];
    return defaultLinks.concat(extraLinks);
  }, [extraLinks, t]);

  return (
    <span className={`Footer-links-container ${className}`}>
      {links.map((link, index) => (
        <React.Fragment key={link.href}>
          <FooterLink
            ariaLabel={link.ariaLabel}
            href={link.href}
            title={link.title}
          />

          {index < links.length - 1 ? (
            <span aria-hidden="true" className="Footer-link-separator">
              &nbsp;|&nbsp;
            </span>
          ) : null}
        </React.Fragment>
      ))}
    </span>
  );
};

export { FooterLinks };
