import popularActive from '../../../assets/plans/popular-active.svg';
import popularInactive from '../../../assets/plans/popular-inactive.svg';
import higherActive from '../../../assets/plans/higher-active.svg';
import higherInactive from '../../../assets/plans/higher-inactive.svg';
import valuePlusActive from '../../../assets/plans/value-plus-active.svg';
import valuePlusInactive from '../../../assets/plans/value-plus-inactive.svg';

export const prepackagedPlansIcons = [
  { active: popularActive, inactive: popularInactive },
  { active: higherActive, inactive: higherInactive },
  { active: valuePlusActive, inactive: valuePlusInactive },
];

export const CUSTOM_PLAN_ID = -1;

export const MOST_POPULAR_ID = 1;
export const HIGHER_COVERAGE_ID = 2;
export const VALUE_PLUS_ID = 3;

export const WHATS_COVERED_LINK_ID = 'whatsCoveredLinkPlanSection';
