import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GetQuoteButton } from '../common';
import './LandingWelcome.css';

const LandingWelcome = ({ onGetQuote }) => {
  const { t } = useTranslation('landing');
  const store = useSelector((stores) => ({
    companyName: stores.quoting.companyName,
    exlusiveOfferMessage: stores.quoting.exlusiveOfferMessage,
  }));

  return (
    <main className="Landing-welcome">
      <div className="Landing-welcome-image-container">
        <div className="Landing-welcome-background-big" />

        <div className="Landing-welcome-overlay" />
      </div>

      <div className="Landing-welcome-content">
        <h1 className="Landing-welcome-company">
          {t('welcome.welcome', { company: store.companyName })}
        </h1>

        <p className="Landing-welcome-text">
          {t('welcome.text')}
        </p>

        <div className="Landing-welcome-offer">
          <span>
            {t('welcome.exclusiveOffer')}
          </span>

          &nbsp;

          <span>
            {store.exlusiveOfferMessage || t('welcome.discounted')}
          </span>
        </div>

        <div className="Landing-welcome-get-quote">
          <GetQuoteButton
            className="Landing-welcome-button"
            onClick={onGetQuote}
          />
        </div>
      </div>
    </main>
  );
};

export { LandingWelcome };
