import React, { useMemo } from 'react';
import './RoundButton.css';

const RoundButton = ({
  children,
  onClick = () => { },
  submit = false,
  primary = false,
}) => {
  const className = useMemo(() => {
    let cn = 'Round-button';
    if (primary) {
      cn = cn.concat(' Round-button-primary');
    }

    return cn;
  }, [primary]);

  return (
    <button
      className={className}
      onClick={onClick}
      type={submit ? 'submit' : 'button'}
    >
      <span>{children}</span>
    </button>
  );
};

export { RoundButton };
