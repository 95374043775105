/* eslint-disable max-len */
export const purchaseConfiguration = {
  CloudClientId: 0,
  Comment1: 'By default, How Did You Hear Id is 15 = Other',
  Comment2: 'Payment 0 is monthly, 1 is annual',
  Comment3: 'ShouldProcessPayment, if false it only submits a Rate, if true we make the actual Issue/Purchase',
  Comment4: 'Payment method 0 is ACH, 1 is CC',
  Comment5: 'isEBProduct decides if we use EB Product configurations, vs regular Products configurations',
  EffectiveDate: '2020-08-30',
  ElectronicOptOut: false,
  EmployeeId: '', // Editable
  EmployerId: 1, // Editable
  FooterText: 'The information contained in this website is for illustrative purposes only and coverage under any pet insurance policy is expressly subject to the conditions, restrictions, limitations, exclusions and terms of the policy documentation\r\n issued by the insurer. Availability of this program is subject to each state’s approval and coverage may vary by state. Pet insurance policies are underwritten by Independence American Insurance Company, a Delaware insurance company.\r\n Plans are administered by Figo Pet Insurance, LLC.',
  HasManualVet: false,
  HasSecondaryOwner: false,
  HowDidYouHear: 'My Veterinarian',
  HowDidYouHearId: 15,
  isEBProduct: true,
  MailAddressCity: null,
  MailAddressLine1: null,
  MailAddressLine2: null,
  MailAddressSameAsPet: true,
  MailAddressState: null,
  MailAddressZip: null,
  PaymentPlanEnum: 1,
  PetAddressCity: '', // Editable
  PetAddressLine1: '', // Editable
  PetAddressLine2: '', // Editable
  PetAddressState: '', // Editable
  PetAddressZip: '', // Editable
  PrimaryEMail: '', // Editable
  PrimaryEMailConfirm: '', // Editable
  PrimaryFirstName: '', // Editable
  PrimaryLastName: '', // Editable
  PrimaryMobilePhone: '', // Editable
  PrimaryOtherPhone: null, // Editable
  PrimaryPassword: '', // Editable
  PrimaryPasswordConfirm: '', // Editable
  QuotePurchaseDto: {
    CloudClientId: 0,
    EffectiveDate: '', // Editable
    EMail: null,
    Error: [],
    Fees: [ // Editable
      {
        Amount: 2,
        DisplayText: 'Monthly installment fee applies.',
        Name: 'Monthly Installment Fee',
        TypeId: 4,
      },
      {
        Amount: 2,
        DisplayText: 'Monthly installment fee applies.',
        Name: 'Monthly Installment Fee',
        TypeId: 5,
      },
    ],
    GroupPartnerCode: null,
    GroupPartnerDisplay: null,
    ImportantDates: [
      {
        ImportantDateDetails: [
          {
            IconRoute: null,
            Text: 'Pre-existing conditions are not covered. For the health.',
          },
        ],
        Title: '',
      },
    ],
    InsurancePetQuotes: [
      {
        ActiveMilitary: false,
        ActualDownPayment: 0.0,
        AdminFee: 9.0,
        AgeString: '2 years',
        AnnualPayPremium: 778.8,
        AnnualPremium: 778.8,
        BreedName: 'Dogo Argentino',
        CloudOrderId: 0,
        CompanyId: 2,
        Coverages: [
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'We will pay, up to the maximum annual benefit for this coverage, for the actual cost(s) of any medically necessary treatment your pet receives during the policy period for a covered illness or injury.',
            Id: 25,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 73,
                InsuranceProductCoverageEBId: 25,
                InsuranceProductPlanEBId: 4,
                Value: '5K',
              },
              {
                Id: 97,
                InsuranceProductCoverageEBId: 25,
                InsuranceProductPlanEBId: 5,
                Value: '10K',
              },
              {
                Id: 121,
                InsuranceProductCoverageEBId: 25,
                InsuranceProductPlanEBId: 6,
                Value: '20K',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Annual Veterinary Benefits',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Figo Pet Cloud',
            Id: 26,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 74,
                InsuranceProductCoverageEBId: 26,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 98,
                InsuranceProductCoverageEBId: 26,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 122,
                InsuranceProductCoverageEBId: 26,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Figo Pet Cloud',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'No Per Incident Cap on Claims',
            Id: 27,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 75,
                InsuranceProductCoverageEBId: 27,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 99,
                InsuranceProductCoverageEBId: 27,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 123,
                InsuranceProductCoverageEBId: 27,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'No Per Incident Cap on Claims',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'No Upper Age Limits on Pets',
            Id: 28,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 76,
                InsuranceProductCoverageEBId: 28,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 100,
                InsuranceProductCoverageEBId: 28,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 124,
                InsuranceProductCoverageEBId: 28,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'No Upper Age Limits on Pets',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'A condition requiring ongoing treatment throughout the life of your pet.',
            Id: 29,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 77,
                InsuranceProductCoverageEBId: 29,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 101,
                InsuranceProductCoverageEBId: 29,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 125,
                InsuranceProductCoverageEBId: 29,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Chronic Conditions',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'A covered condition that may be inherited or acquired at birth.',
            Id: 30,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 78,
                InsuranceProductCoverageEBId: 30,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 102,
                InsuranceProductCoverageEBId: 30,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 126,
                InsuranceProductCoverageEBId: 30,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Hereditary & Congenital Conditions',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Knee condition coverage is included and begins after the 6-month waiting period.',
            Id: 31,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 79,
                InsuranceProductCoverageEBId: 31,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 103,
                InsuranceProductCoverageEBId: 31,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 127,
                InsuranceProductCoverageEBId: 31,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Knee Conditions (including ACL)',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Some companies exclude hip dysplasia; Figo covers it without restrictions.',
            Id: 32,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 80,
                InsuranceProductCoverageEBId: 32,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 104,
                InsuranceProductCoverageEBId: 32,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 128,
                InsuranceProductCoverageEBId: 32,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Hip Dysplasia',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Visit any licensed 24-hour emergency animal hospital in the US, Canada & Puerto Rico for urgent care.',
            Id: 33,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 81,
                InsuranceProductCoverageEBId: 33,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 105,
                InsuranceProductCoverageEBId: 33,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 129,
                InsuranceProductCoverageEBId: 33,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Emergency Services',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Medically necessary prolonged observation or treatment at the veterinary clinic/hospital related to a covered illness or injury.',
            Id: 34,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 82,
                InsuranceProductCoverageEBId: 34,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 106,
                InsuranceProductCoverageEBId: 34,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 130,
                InsuranceProductCoverageEBId: 34,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Hospitalization',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Any surgery considered medically necessary by a licensed veterinarian for a covered accident or illness.',
            Id: 35,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 83,
                InsuranceProductCoverageEBId: 35,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 107,
                InsuranceProductCoverageEBId: 35,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 131,
                InsuranceProductCoverageEBId: 35,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Surgeries',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Our coverage for cancer includes the most advanced, cutting-edge treatments, such as CyberKnifeTM and radiosurgery.',
            Id: 36,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 84,
                InsuranceProductCoverageEBId: 36,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 108,
                InsuranceProductCoverageEBId: 36,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 132,
                InsuranceProductCoverageEBId: 36,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Cancer Treatments',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Coverage for chiropractic and acupuncture services performed by a licensed veterinarian.',
            Id: 37,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 85,
                InsuranceProductCoverageEBId: 37,
                InsuranceProductPlanEBId: 4,
                Value: '$350',
              },
              {
                Id: 109,
                InsuranceProductCoverageEBId: 37,
                InsuranceProductPlanEBId: 5,
                Value: '$600',
              },
              {
                Id: 133,
                InsuranceProductCoverageEBId: 37,
                InsuranceProductPlanEBId: 6,
                Value: '$1,000',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Chiropractic & Acupuncture',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Prescribed by licensed veterinarian in relation to the treatment of illness or injury, and according to the drug formulary.',
            Id: 38,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 86,
                InsuranceProductCoverageEBId: 38,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 110,
                InsuranceProductCoverageEBId: 38,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 134,
                InsuranceProductCoverageEBId: 38,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Prescription Medications',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'No referrals are required. Coverage for exam or consultation fees must be added on separately.',
            Id: 39,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 87,
                InsuranceProductCoverageEBId: 39,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 111,
                InsuranceProductCoverageEBId: 39,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 135,
                InsuranceProductCoverageEBId: 39,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Veterinary Specialist Treatments',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Advanced imaging technology including X-rays, MRIs, CAT Scans, Ultrasounds.',
            Id: 40,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 88,
                InsuranceProductCoverageEBId: 40,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 112,
                InsuranceProductCoverageEBId: 40,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 136,
                InsuranceProductCoverageEBId: 40,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Imaging',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Laboratory and diagnostic testing that is considered medically necessary by a licensed veterinarian.',
            Id: 41,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 89,
                InsuranceProductCoverageEBId: 41,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 113,
                InsuranceProductCoverageEBId: 41,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 137,
                InsuranceProductCoverageEBId: 41,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Laboratory & Diagnostic Testing',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Treatment for injuries relating to the pet\'s teeth resulting from an accident.',
            Id: 42,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 90,
                InsuranceProductCoverageEBId: 42,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 114,
                InsuranceProductCoverageEBId: 42,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 138,
                InsuranceProductCoverageEBId: 42,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Non-routine Dental (accident only)',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Includes devices related to surgery recovery, arthritis, paralysis, and amputation.',
            Id: 43,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 91,
                InsuranceProductCoverageEBId: 43,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 115,
                InsuranceProductCoverageEBId: 43,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 139,
                InsuranceProductCoverageEBId: 43,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Prosthetic & Orthotic Devices',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'We\'ll cover any medication prescribed for the treatment of behavioral problems. If optional exam fee coverage is purchased, we will also cover the exam fees.',
            Id: 44,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 92,
                InsuranceProductCoverageEBId: 44,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 116,
                InsuranceProductCoverageEBId: 44,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 140,
                InsuranceProductCoverageEBId: 44,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Behavioral',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'If the veterinarian determines euthanasia is medically necessary, that expense will be covered along with the cost of cremation.',
            Id: 45,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 93,
                InsuranceProductCoverageEBId: 45,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 117,
                InsuranceProductCoverageEBId: 45,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 141,
                InsuranceProductCoverageEBId: 45,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Euthanasia & Cremation',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Optional coverage is available for exam and consultation fees related to the diagnosis or treatment of a new accident or illness.',
            Id: 46,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 94,
                InsuranceProductCoverageEBId: 46,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 118,
                InsuranceProductCoverageEBId: 46,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 142,
                InsuranceProductCoverageEBId: 46,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Optional: Veterinary Exam Fees (for illness or injury only)',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'Optional coverage available for rehabilitation costs, provided the services are prescribed and administered by a licensed veterinarian.',
            Id: 47,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 95,
                InsuranceProductCoverageEBId: 47,
                InsuranceProductPlanEBId: 4,
                Value: 'Y',
              },
              {
                Id: 119,
                InsuranceProductCoverageEBId: 47,
                InsuranceProductPlanEBId: 5,
                Value: 'Y',
              },
              {
                Id: 143,
                InsuranceProductCoverageEBId: 47,
                InsuranceProductPlanEBId: 6,
                Value: 'Y',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Optional: Rehabilitation',
          },
          {
            CreatedOn: '2020-05-29T22:50:40.-05:00',
            Description: 'This is the maximum amount Figo will reimburse to you for covered claims over the lifetime of your pet. Note: Annual veterinary benefit limits still apply, based on plan selection.',
            Id: 48,
            InsuranceProductCoverageEBXInsuranceProductPlansEB: [
              {
                Id: 96,
                InsuranceProductCoverageEBId: 48,
                InsuranceProductPlanEBId: 4,
                Value: '$100,000',
              },
              {
                Id: 120,
                InsuranceProductCoverageEBId: 48,
                InsuranceProductPlanEBId: 5,
                Value: '$150,000',
              },
              {
                Id: 144,
                InsuranceProductCoverageEBId: 48,
                InsuranceProductPlanEBId: 6,
                Value: '$200,000',
              },
            ],
            InsuranceProductEBId: 2,
            IsActive: true,
            IsVisible: true,
            Name: 'Lifetime Maximum Benefit',
          },
        ],
        DateOfBirthGuess: '2018-04-06T00:00:00',
        Deductibles: [
          {
            Description: '$1,000',
            DollarVal: 1000,
            Id: 5,
          },
          {
            Description: '$750',
            DollarVal: 750,
            Id: 9,
          },
          {
            Description: '$500',
            DollarVal: 500,
            Id: 4,
          },
          {
            Description: '$250',
            DollarVal: 250,
            Id: 8,
          },
          {
            Description: '$200',
            DollarVal: 200,
            Id: 3,
          },
          {
            Description: '$100',
            DollarVal: 100,
            Id: 2,
          },
        ],
        DiamondPolicyId: 0,
        DisableEndorsements: false,
        Discounts: [],
        DOB: '',
        DownPayment: 27.99,
        DownPaymentDscr: '1 month premium due today',
        FullPayCCCharge: 10.0,
        GetAnnualPayAtRate: true,
        HasWaitingPeriodWaiver: false,
        Id: -1,
        ImportantMessage: null,
        InvalidCombinationsPlan: [
          {
            DeductibleId: 9,
            PMSCoverageLimitId: 11,
            ReimbursementId: 1,
          },
          {
            DeductibleId: 4,
            PMSCoverageLimitId: 11,
            ReimbursementId: 1,
          },
          {
            DeductibleId: 8,
            PMSCoverageLimitId: 11,
            ReimbursementId: 1,
          },
          {
            DeductibleId: 2,
            PMSCoverageLimitId: 11,
            ReimbursementId: 1,
          },
        ],
        IsAnnual: true,
        IsCat: false,
        IsExamFees: false,
        IsExamFeesAllowed: true,
        IsFemale: false,
        IsHumaneDiscountAllowed: true,
        IsMilitaryAllowed: true,
        IsServiceDog: false,
        IsSpayedNeutered: false,
        IsVetShelterHumaneDiscountAllowed: true,
        LobId: 3,
        Modifiers: [
          {
            DisplayName: '',
            ModifierDetails: [
              {
                FeeAmount: 0.2148,
                InformationText: 'This benefit is to help cover exam or consultation fees associated with the diagnosis and treatment of your pet for an eligible illness or accident. It is not intended to cover exam fees related to routine, wellness, or preventative visits.',
                InputText: 'This benefit is to help cover exam or consultation fees associated with the diagnosis and treatment of your pet for an eligible illness or accident. It is not intended to cover exam fees related to routine, wellness, or preventative visits.',
                IsSelected: false,
                IsVisible: true,
                PMSModifierId: 70422,
                QuestionType: 0,
                TitleText: 'Veterinary Exam Fees for Accident and Illness Visits',
              },
              {
                FeeAmount: 0.199,
                InformationText: 'Boost your coverage!.',
                InputText: 'Would you like to add Take Home Prescriptions to your policy?',
                IsSelected: true,
                IsVisible: false,
                PMSModifierId: 70423,
                QuestionType: 0,
                TitleText: 'Take Home Prescriptions',
              },
              {
                FeeAmount: 0.0349,
                InformationText: 'This benefit applies to covered accidents and illnesses and must be performed or supervised by a licensed veterinarian. Coverage includes underwater treadmill therapy, swimming, therapeutic exercises, range of motion exercises, gait analysis, laser therapy, cryotherapy, and heat therapy.',
                InputText: 'This benefit applies to covered accidents and illnesses and must be performed or supervised by a licensed veterinarian. Coverage includes underwater treadmill therapy, swimming, therapeutic exercises, range of motion exercises, gait analysis, laser therapy, cryotherapy, and heat therapy.',
                IsSelected: true,
                IsVisible: false,
                PMSModifierId: 70424,
                QuestionType: 0,
                TitleText: 'Rehabilitation',
              },
              {
                FeeAmount: 0.0172,
                InformationText: 'Boost your coverage!',
                InputText: 'Would you like to add Holistic and Alternative fees to your policy?',
                IsSelected: true,
                IsVisible: false,
                PMSModifierId: 70425,
                QuestionType: 0,
                TitleText: 'Holistic and Alternatives',
              },
              {
                FeeAmount: 6.62,
                InformationText: 'Optional coverage is available for final respects (up to $250 for cremation and burial), lost pet advertising/award, vacation cancellation due to pet emergency, boarding fees, pet theft or loss due to straying (up to $150), and third-party property damage liability (up to $10,000 per policy period and subject to a separate deductible).',
                InputText: 'Optional coverage is available for final respects (up to $250 for cremation and burial), lost pet advertising/award, vacation cancellation due to pet emergency, boarding fees, pet theft or loss due to straying (up to $150), and third-party property damage liability (up to $10,000 per policy period and subject to a separate deductible).',
                IsSelected: true,
                IsVisible: false,
                PMSModifierId: 70427,
                QuestionType: 0,
                TitleText: 'Extra Care Pack',
              },
            ],
            ModifierType: 5,
          },
          {
            DisplayName: '',
            ModifierDetails: [
              {
                FeeAmount: null,
                InformationText: '',
                InputText: 'Multiple-Pet Discount',
                IsSelected: false,
                IsVisible: true,
                PMSModifierId: 9,
                QuestionType: 0,
                TitleText: '',
              },
              {
                FeeAmount: null,
                InformationText: '',
                InputText: 'Multi Policy Discount',
                IsSelected: false,
                IsVisible: false,
                PMSModifierId: 20,
                QuestionType: 0,
                TitleText: '',
              },
              {
                FeeAmount: null,
                InformationText: '',
                InputText: 'Group Discount',
                IsSelected: false,
                IsVisible: false,
                PMSModifierId: 16,
                QuestionType: 0,
                TitleText: '',
              },
              {
                FeeAmount: null,
                InformationText: '',
                InputText: 'Corporate Partner / Group Discount',
                IsSelected: false,
                IsVisible: true,
                PMSModifierId: 5,
                QuestionType: 0,
                TitleText: '',
              },
              {
                FeeAmount: null,
                InformationText: '',
                InputText: 'Annual Pay Discount',
                IsSelected: false,
                IsVisible: true,
                PMSModifierId: 14,
                QuestionType: 0,
                TitleText: '',
              },
              {
                FeeAmount: null,
                InformationText: '',
                InputText: 'Online Referral Partner',
                IsSelected: false,
                IsVisible: true,
                PMSModifierId: 43,
                QuestionType: 0,
                TitleText: '',
              },
              {
                FeeAmount: null,
                InformationText: '',
                InputText: 'Select Vet Clinic Customer Discount',
                IsSelected: false,
                IsVisible: true,
                PMSModifierId: 44,
                QuestionType: 0,
                TitleText: '',
              },
              {
                FeeAmount: null,
                InformationText: '',
                InputText: 'Vet Clinic Staff Discount',
                IsSelected: false,
                IsVisible: true,
                PMSModifierId: 45,
                QuestionType: 0,
                TitleText: '',
              },
              {
                FeeAmount: null,
                InformationText: '',
                InputText: 'Affinity Groups Discount',
                IsSelected: false,
                IsVisible: true,
                PMSModifierId: 46,
                QuestionType: 0,
                TitleText: '',
              },
              {
                FeeAmount: null,
                InformationText: '',
                InputText: 'IHC and Administrator Employee Discount',
                IsSelected: false,
                IsVisible: true,
                PMSModifierId: 47,
                QuestionType: 0,
                TitleText: '',
              },
            ],
            ModifierType: 4,
          },
        ],
        MonthlyCost: 64.9,
        MonthlyCostCents: 9,
        MonthlyCostDollars: 80,
        MostPopularPlan: 'PREFERRED',
        NeedsPaymentInfo: true,
        PayrollDeduct: false,
        PetName: 'Morlok',
        Plans: [
          {
            CoverageId: 12,
            MaxAnnual: '$5K',
            Plan: 12,
            PlanName: 'Essential',
            RatingOptions: [
              {
                AnnualPremium: 224.84,
                DeductibleId: 9,
                DeductibleName: '$750',
                Invalid: false,
                MonthlyPremium: 18.74,
                ReimbursementId: 4,
                ReimbursementName: '70%',
              },
              {
                AnnualPremium: 255.74,
                DeductibleId: 9,
                DeductibleName: '$750',
                Invalid: false,
                MonthlyPremium: 21.31,
                ReimbursementId: 3,
                ReimbursementName: '80%',
              },
              {
                AnnualPremium: 285.77,
                DeductibleId: 9,
                DeductibleName: '$750',
                Invalid: false,
                MonthlyPremium: 23.81,
                ReimbursementId: 2,
                ReimbursementName: '90%',
              },
              {
                AnnualPremium: 315.38,
                DeductibleId: 9,
                DeductibleName: '$750',
                Invalid: false,
                MonthlyPremium: 26.28,
                ReimbursementId: 1,
                ReimbursementName: '100%',
              },
              {
                AnnualPremium: 276.76,
                DeductibleId: 4,
                DeductibleName: '$500',
                Invalid: false,
                MonthlyPremium: 23.06,
                ReimbursementId: 4,
                ReimbursementName: '70%',
              },
              {
                AnnualPremium: 314.95,
                DeductibleId: 4,
                DeductibleName: '$500',
                Invalid: false,
                MonthlyPremium: 26.25,
                ReimbursementId: 3,
                ReimbursementName: '80%',
              },
              {
                AnnualPremium: 351.42,
                DeductibleId: 4,
                DeductibleName: '$500',
                Invalid: false,
                MonthlyPremium: 29.29,
                ReimbursementId: 2,
                ReimbursementName: '90%',
              },
              {
                AnnualPremium: 387.9,
                DeductibleId: 4,
                DeductibleName: '$500',
                Invalid: false,
                MonthlyPremium: 32.33,
                ReimbursementId: 1,
                ReimbursementName: '100%',
              },
              {
                AnnualPremium: 331.69,
                DeductibleId: 8,
                DeductibleName: '$250',
                Invalid: false,
                MonthlyPremium: 27.64,
                ReimbursementId: 4,
                ReimbursementName: '70%',
              },
              {
                AnnualPremium: 377.17,
                DeductibleId: 8,
                DeductibleName: '$250',
                Invalid: false,
                MonthlyPremium: 31.43,
                ReimbursementId: 3,
                ReimbursementName: '80%',
              },
              {
                AnnualPremium: 422.22,
                DeductibleId: 8,
                DeductibleName: '$250',
                Invalid: false,
                MonthlyPremium: 35.19,
                ReimbursementId: 2,
                ReimbursementName: '90%',
              },
              {
                AnnualPremium: 0,
                DeductibleId: 8,
                DeductibleName: '$250',
                Invalid: true,
                MonthlyPremium: 0,
                ReimbursementId: 1,
                ReimbursementName: '100%',
              },
              {
                AnnualPremium: 419.22,
                DeductibleId: 2,
                DeductibleName: '$100',
                Invalid: false,
                MonthlyPremium: 34.94,
                ReimbursementId: 4,
                ReimbursementName: '70%',
              },
              {
                AnnualPremium: 477.15,
                DeductibleId: 2,
                DeductibleName: '$100',
                Invalid: false,
                MonthlyPremium: 39.76,
                ReimbursementId: 3,
                ReimbursementName: '80%',
              },
              {
                AnnualPremium: 563.39,
                DeductibleId: 2,
                DeductibleName: '$100',
                Invalid: false,
                MonthlyPremium: 46.95,
                ReimbursementId: 2,
                ReimbursementName: '90%',
              },
              {
                AnnualPremium: 0,
                DeductibleId: 2,
                DeductibleName: '$100',
                Invalid: true,
                MonthlyPremium: 0,
                ReimbursementId: 1,
                ReimbursementName: '100%',
              },
            ],
          },
          {
            CoverageId: 9,
            MaxAnnual: '$10K',
            Plan: 9,
            PlanName: 'Preferred',
            RatingOptions: [
              {
                AnnualPremium: 435.1,
                DeductibleId: 9,
                DeductibleName: '$750',
                Invalid: false,
                MonthlyPremium: 36.26,
                ReimbursementId: 4,
                ReimbursementName: '70%',
              },
              {
                AnnualPremium: 495.6,
                DeductibleId: 9,
                DeductibleName: '$750',
                Invalid: false,
                MonthlyPremium: 41.3,
                ReimbursementId: 3,
                ReimbursementName: '80%',
              },
              {
                AnnualPremium: 555.24,
                DeductibleId: 9,
                DeductibleName: '$750',
                Invalid: false,
                MonthlyPremium: 46.27,
                ReimbursementId: 2,
                ReimbursementName: '90%',
              },
              {
                AnnualPremium: 614.45,
                DeductibleId: 9,
                DeductibleName: '$750',
                Invalid: false,
                MonthlyPremium: 51.2,
                ReimbursementId: 1,
                ReimbursementName: '100%',
              },
              {
                AnnualPremium: 492.16,
                DeductibleId: 4,
                DeductibleName: '$500',
                Invalid: false,
                MonthlyPremium: 41.01,
                ReimbursementId: 4,
                ReimbursementName: '70%',
              },
              {
                AnnualPremium: 560.82,
                DeductibleId: 4,
                DeductibleName: '$500',
                Invalid: false,
                MonthlyPremium: 46.74,
                ReimbursementId: 3,
                ReimbursementName: '80%',
              },
              {
                AnnualPremium: 628.19,
                DeductibleId: 4,
                DeductibleName: '$500',
                Invalid: false,
                MonthlyPremium: 52.35,
                ReimbursementId: 2,
                ReimbursementName: '90%',
              },
              {
                AnnualPremium: 695.55,
                DeductibleId: 4,
                DeductibleName: '$500',
                Invalid: false,
                MonthlyPremium: 57.96,
                ReimbursementId: 1,
                ReimbursementName: '100%',
              },
              {
                AnnualPremium: 530.35,
                DeductibleId: 8,
                DeductibleName: '$250',
                Invalid: false,
                MonthlyPremium: 44.2,
                ReimbursementId: 4,
                ReimbursementName: '70%',
              },
              {
                AnnualPremium: 604.16,
                DeductibleId: 8,
                DeductibleName: '$250',
                Invalid: false,
                MonthlyPremium: 50.35,
                ReimbursementId: 3,
                ReimbursementName: '80%',
              },
              {
                AnnualPremium: 677.53,
                DeductibleId: 8,
                DeductibleName: '$250',
                Invalid: false,
                MonthlyPremium: 56.46,
                ReimbursementId: 2,
                ReimbursementName: '90%',
              },
              {
                AnnualPremium: 0,
                DeductibleId: 8,
                DeductibleName: '$250',
                Invalid: true,
                MonthlyPremium: 0,
                ReimbursementId: 1,
                ReimbursementName: '100%',
              },
              {
                AnnualPremium: 619.17,
                DeductibleId: 2,
                DeductibleName: '$100',
                Invalid: false,
                MonthlyPremium: 51.6,
                ReimbursementId: 4,
                ReimbursementName: '70%',
              },
              {
                AnnualPremium: 705.42,
                DeductibleId: 2,
                DeductibleName: '$100',
                Invalid: false,
                MonthlyPremium: 58.79,
                ReimbursementId: 3,
                ReimbursementName: '80%',
              },
              {
                AnnualPremium: 835.01,
                DeductibleId: 2,
                DeductibleName: '$100',
                Invalid: false,
                MonthlyPremium: 69.58,
                ReimbursementId: 2,
                ReimbursementName: '90%',
              },
              {
                AnnualPremium: 0,
                DeductibleId: 2,
                DeductibleName: '$100',
                Invalid: true,
                MonthlyPremium: 0,
                ReimbursementId: 1,
                ReimbursementName: '100%',
              },
            ],
          },
          {
            CoverageId: 18,
            MaxAnnual: '$20K',
            Plan: 18,
            PlanName: 'Ultimate',
            RatingOptions: [
              {
                AnnualPremium: 500.32,
                DeductibleId: 9,
                DeductibleName: '$750',
                Invalid: false,
                MonthlyPremium: 41.69,
                ReimbursementId: 4,
                ReimbursementName: '70%',
              },
              {
                AnnualPremium: 569.83,
                DeductibleId: 9,
                DeductibleName: '$750',
                Invalid: false,
                MonthlyPremium: 47.49,
                ReimbursementId: 3,
                ReimbursementName: '80%',
              },
              {
                AnnualPremium: 638.48,
                DeductibleId: 9,
                DeductibleName: '$750',
                Invalid: false,
                MonthlyPremium: 53.21,
                ReimbursementId: 2,
                ReimbursementName: '90%',
              },
              {
                AnnualPremium: 706.71,
                DeductibleId: 9,
                DeductibleName: '$750',
                Invalid: true,
                MonthlyPremium: 58.89,
                ReimbursementId: 1,
                ReimbursementName: '100%',
              },
              {
                AnnualPremium: 565.97,
                DeductibleId: 4,
                DeductibleName: '$500',
                Invalid: false,
                MonthlyPremium: 47.16,
                ReimbursementId: 4,
                ReimbursementName: '70%',
              },
              {
                AnnualPremium: 644.49,
                DeductibleId: 4,
                DeductibleName: '$500',
                Invalid: false,
                MonthlyPremium: 53.71,
                ReimbursementId: 3,
                ReimbursementName: '80%',
              },
              {
                AnnualPremium: 722.59,
                DeductibleId: 4,
                DeductibleName: '$500',
                Invalid: false,
                MonthlyPremium: 60.22,
                ReimbursementId: 2,
                ReimbursementName: '90%',
              },
              {
                AnnualPremium: 799.82,
                DeductibleId: 4,
                DeductibleName: '$500',
                Invalid: true,
                MonthlyPremium: 66.65,
                ReimbursementId: 1,
                ReimbursementName: '100%',
              },
              {
                AnnualPremium: 609.73,
                DeductibleId: 8,
                DeductibleName: '$250',
                Invalid: false,
                MonthlyPremium: 50.81,
                ReimbursementId: 4,
                ReimbursementName: '70%',
              },
              {
                AnnualPremium: 694.69,
                DeductibleId: 8,
                DeductibleName: '$250',
                Invalid: false,
                MonthlyPremium: 57.89,
                ReimbursementId: 3,
                ReimbursementName: '80%',
              },
              {
                AnnualPremium: 778.8,
                DeductibleId: 8,
                DeductibleName: '$250',
                Invalid: false,
                MonthlyPremium: 64.9,
                ReimbursementId: 2,
                ReimbursementName: '90%',
              },
              {
                AnnualPremium: 0,
                DeductibleId: 8,
                DeductibleName: '$250',
                Invalid: true,
                MonthlyPremium: 0,
                ReimbursementId: 1,
                ReimbursementName: '100%',
              },
              {
                AnnualPremium: 711.86,
                DeductibleId: 2,
                DeductibleName: '$100',
                Invalid: false,
                MonthlyPremium: 59.32,
                ReimbursementId: 4,
                ReimbursementName: '70%',
              },
              {
                AnnualPremium: 811.41,
                DeductibleId: 2,
                DeductibleName: '$100',
                Invalid: false,
                MonthlyPremium: 67.62,
                ReimbursementId: 3,
                ReimbursementName: '80%',
              },
              {
                AnnualPremium: 959.87,
                DeductibleId: 2,
                DeductibleName: '$100',
                Invalid: false,
                MonthlyPremium: 79.99,
                ReimbursementId: 2,
                ReimbursementName: '90%',
              },
              {
                AnnualPremium: 0,
                DeductibleId: 2,
                DeductibleName: '$100',
                Invalid: true,
                MonthlyPremium: 0,
                ReimbursementId: 1,
                ReimbursementName: '100%',
              },
            ],

          },
        ],
        PolicyNumber: null,
        Reimbursements: [
          {
            Description: '60%',
            Id: 8,
            PercentVal: 0.6,
          },
          {
            Description: '70%',
            Id: 4,
            PercentVal: 0.7,
          },
          {
            Description: '80%',
            Id: 3,
            PercentVal: 0.8,
          },
          {
            Description: '90%',
            Id: 2,
            PercentVal: 0.9,
          },
          {
            Description: '100%',
            Id: 1,
            PercentVal: 1,
          },
        ],
        SelectedDeductibleDscr: '$250',
        SelectedDeductibleId: 8,
        SelectedModifiers: [
          {
            id: 15,
            isSelected: true,
          },
        ],
        SelectedPlan: 'ULTIMATE',
        SelectedPlanId: 18,
        SelectedReimbursementDscr: '90%',
        SelectedReimbursementId: 2,
        State: 'WA',
        TagColorId: 54,
        Tax: 0.0,
        TotalDue: 794.8,
        TotalDueCents: 8,
        TotalDueDollars: 794,
        VersionId: 158,
        VetPast12Months: false,
        WebPreSelectedDeductible: '250',
        WebPreSelectedPlan: null,
        WebPreSelectedReimbursement: '90',
        ZipCode: '98001',
      },
    ],
    InsuranceProductId: 2,
    IsEndorsement: false,
    IsMultiplePets: true,
    IsRated: true,
    IsZipCodeForQuote: false,
    NeedToKnows: [
      {
        NeedToKnowDetails: [
          {
            Text: 'New illnesses and accidents',
          },
          {
            Text: 'Diagnostic tests related to illnesses/accidents',
          },
          {
            Text: 'Prescription medications',
          },
          {
            Text: 'Surgeries, prosthetics, chronic conditions, emergency services, advanced treatments, and much more',
          },
          {
            Text: 'You may visit any licensed veterinarian in the US, Canada, and Puerto Rico.',
          },
        ],
        Title: 'What\'s Covered',
      },
      {
        NeedToKnowDetails: [
          {
            Text: 'Pre-existing conditions',
          },
          {
            Text: 'Routine wellness or preventative care',
          },
          {
            Text: 'Spaying or neutering',
          },
          {
            Text: 'Dental cleanings or procedures not related to an accident',
          },
          {
            Text: 'Prescription food',
          },
        ],
        Title: 'What\'s Not Covered',
      },
      {
        NeedToKnowDetails: [
          {
            Text: 'One annual deductible per policy',
          },
          {
            Text: 'Your coinsurance is the difference between your reimbursement percentage and 100%. (For example, a 90% reimbursement would have a 10% coinsurance.)',
          },
          {
            Text: 'The annual deductible will be applied and deducted from covered claims before benefits apply.',
          },
          {
            Text: 'Once the annual deductible has been met, you will be reimbursed at your selected reimbursement amount for the rest of your policy period.',
          },
        ],
        Title: 'Deductible and Coinsurance',
      },
      {
        NeedToKnowDetails: [
          {
            Text: 'Waiting periods begin on your policy effective date',
          },
          {
            Text: '3-day waiting period for accident (injury)',
          },
          {
            Text: '14-day waiting period for illness',
          },
          {
            Text: '6-month waiting period for knee conditions',
          },
        ],
        Title: 'Waiting Periods',
      },
    ],
    PaymentPlans: [
      {
        Description: 'Monthly',
        Id: 1,
      },
      {
        Description: 'Annual',
        Id: 2,
      },
    ],
    PetQuotes: [],
    PetState: 'WA',
    PetZip: '98001',
    ProductInfo: 'ProductId : 2 - Name IHC001 - Description : Underwritten by Independence American Insurance Group',
    RatingFromComplete: true,
  },
  Referrals: null,
  SecondaryFirstName: null,
  SecondaryLastName: null,
  SecondaryMobilePhone: '',
  SelectedVet: 'Dr Shipp\'s Animal Hospital',
  SelectedVetCode: null,
  SelectedVetID: 533296,
  SelectedVetName: 'Dr Shipp\'s Animal Hospital',
  ShouldProcessPayment: true,
  StateId: '59',
  VetAddress: null,
  VetCity: null,
  VetCompany: null,
  VetZip: null,
};
/* eslint-disable max-len */
