import React from 'react';
import iphoneSkin from '../../../assets/landing/iphone-x-white.png';
import { VimeoVideo } from '../../common/VimeoVideo';
import './Phone.css';

const VIMEO_VIDEO_ID = '952050312';

const Phone = () => (
  <div aria-hidden="true" className="Phone-screenshot">
    <div className="Phone-iphone">
      <div className="Phone-video">
        <VimeoVideo autoplay id={VIMEO_VIDEO_ID} loop muted />
      </div>

      <img alt="" src={iphoneSkin} />
    </div>
  </div>
);

export { Phone };
