import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { forgetPasswordConfirmVisibleChanged } from '../../actions';
import successIcon from '../../assets/check.svg';
import ModalContainer from './ModalContainer';
import './ModalForgetPasswordConfirm.css';

const ModalForgetPasswordConfirm = ({ onModalClosed }) => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const dispatch = useDispatch();
  const { sessionInformation: {
    forgetPasswordModalConfirmVisible,
  } } = store;

  function handleCloseModal() {
    dispatch(forgetPasswordConfirmVisibleChanged({ visible: false }));
    if (onModalClosed) {
      onModalClosed();
    }
  }

  return (
    <ModalContainer
      cancelable={false}
      show={forgetPasswordModalConfirmVisible}
    >
      <div className="Modal-forget-password-confirm-container">
        <img
          alt="confirmation"
          className="Modal-forget-password-confirm-success-icon"
          src={successIcon}
        />

        <span
          className="Modal-forget-password-confirm-title Inter-semi-bold-22"
        >
          Reset Password
        </span>

        <span
          className="Modal-forget-password-confirm-subtitle Inter-regular-16"
        >
          Please check your email for confirmation.
        </span>

        <input
          className={'Modal-forget-password-confirm-close-button'
            + ' Inter-semi-bold-16'}
          onClick={handleCloseModal}
          type="button"
          value="Close"
        />
      </div>
    </ModalContainer>
  );
};

export default ModalForgetPasswordConfirm;
