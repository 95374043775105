import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PetPurchaseFailureCard } from './PetPurchaseFailureCard';
import { PetPurchaseSuccessCard } from './PetPurchaseSuccessCard';

import cat from '../../../assets/cat-selected.svg';
import dog from '../../../assets/dog-selected.svg';

import './PetPurchaseResult.css';

const PetPurchaseResult = ({
  errorMessage = '',
  isCat = false,
  petName = '',
  policyNumber = '',
  success = false,
}) => {
  const { t } = useTranslation('purchaseResult');

  const petIcon = useMemo(() => (isCat ? cat : dog), [isCat]);

  if (success) {
    return (
      <PetPurchaseSuccessCard
        petIcon={petIcon}
        petName={petName}
        policyNumber={policyNumber}
        t={t}
      />
    );
  }

  return (
    <PetPurchaseFailureCard
      error={errorMessage}
      petIcon={petIcon}
      petName={petName}
      t={t}
    />
  );
};

export { PetPurchaseResult };
