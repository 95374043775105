import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

import {
  restartPowerupErrors,
  saveSelectedAddPetModal,
  saveSelectedPetNumber,
  setPowerUpErrors,
} from '../../../actions';
import { DROPDOWN_ITEM_TYPE } from '../../../constants';
import { URL_ROOT_QUOTE } from '../../../routes';
import {
  validatePowerupsSelected,
  forceFocusElement,
  useCustomPlanPills,
} from '../../../util';
import { usePrevious, useSavedElement } from '../../../hooks';

import { PetQuoteDropDownItem } from './PetQuoteDropDownItem';
import { PriceLog } from '../../common/PriceLog';
import './PetQuoteDropDown.css';

const PetQuoteDropDown = ({
  classContainer = '',
  customClass = '',
  hideAddPet = false,
  petQuoteList,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [petQuoteToRemove, setPetQuoteToRemove] = useState(null);
  const history = useHistory();
  const store = useSelector(({ quoting }) => quoting);
  const {
    petQuoteSelected,
    quoteSelectedValues,
    petRemovedLoading,
    data,
  } = store;

  const route = useRouteMatch(URL_ROOT_QUOTE);
  const shouldRenderPriceLog = route && route.isExact;

  const currentPet = petQuoteList
    .find((element) => element.petQuoteId === petQuoteSelected);

  const { saveElementById } = useSavedElement();

  useEffect(() => () => setPetQuoteToRemove(null), [isOpen]);

  const toggleOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const prevLength = usePrevious(petQuoteList.length);
  useEffect(() => {
    if (petQuoteList.length > prevLength) {
      forceFocusElement('petQuoteDropDownItem'
        + `${currentPet.petQuoteId}-header`);
    } else if (petQuoteList.length < prevLength) {
      toggleOpen();
    }
  }, [petQuoteList.length, prevLength, currentPet, toggleOpen]);

  const dispatch = useDispatch();

  function handleHeaderOnClick() {
    if (!petRemovedLoading) {
      setIsOpen(!isOpen);
    }
  }

  const handleItemClick = (petQuoteId) => {
    const petQuoteRateItem = data.ebPetQuoteResponseList
      .find((pqRateItem) => pqRateItem.petQuoteId === petQuoteSelected);
    const powerUpValidation = validatePowerupsSelected(petQuoteRateItem);
    if (!powerUpValidation.isValid) {
      dispatch(setPowerUpErrors(powerUpValidation.modifierIdNotSelectedList));
      setIsOpen(false);
      return;
    }
    if (!petRemovedLoading) {
      dispatch(saveSelectedPetNumber(petQuoteId));
      setIsOpen(!isOpen);
      forceFocusElement('petQuoteDropDownItem'
        + `${currentPet.petQuoteId}-header`);
      dispatch(restartPowerupErrors());
    }
  };

  const handleAddPetClick = () => {
    if (hideAddPet) {
      history.goBack();
      window.scrollTo(0, 0);
    }
    const petQuoteRateItem = data.ebPetQuoteResponseList
      .find((pqRateItem) => pqRateItem.petQuoteId === petQuoteSelected);
    const powerUpValidation = validatePowerupsSelected(petQuoteRateItem);
    if (!powerUpValidation.isValid) {
      dispatch(setPowerUpErrors(powerUpValidation.modifierIdNotSelectedList));
      setIsOpen(false);
      return;
    }
    if (!petRemovedLoading) {
      dispatch(saveSelectedAddPetModal(true));
      saveElementById('petQuoteDropDownItem'
        + `${currentPet.petQuoteId}-header`);
      setIsOpen(!isOpen);
      dispatch(restartPowerupErrors());
    }
  };

  const handleRemoveItem = (petQuoteId) => {
    setPetQuoteToRemove(petQuoteId || null);
  };

  const renderChildList = () => {
    let petItems = [];
    if (currentPet) {
      petItems = [
        currentPet,
        ...petQuoteList.filter((element) => element !== currentPet),
      ];
    }

    return petItems.map((pet) => (
      <PetQuoteDropDownItem
        key={String(pet.petQuoteId)}
        dropDownItemType={DROPDOWN_ITEM_TYPE.pet}
        hasMoreThanOnePet={petQuoteList.length > 1}
        isOpen={isOpen}
        onEditPetSelection={handleRemoveItem}
        onItemClick={handleItemClick}
        petQuote={pet}
        petQuoteToEdit={petQuoteToRemove}
        quoteSelectedValues={quoteSelectedValues}
        toggleOpen={toggleOpen}
      />
    ));
  };

  return (
    <>
      <div className={`Pet-quote-drop-down-main-container ${classContainer}`}>
        <PetQuoteDropDownItem
          dropDownItemType={DROPDOWN_ITEM_TYPE.header}
          hasMoreThanOnePet={petQuoteList.length > 1}
          isOpen={isOpen}
          onEditPetSelection={handleRemoveItem}
          onHeaderClick={handleHeaderOnClick}
          petQuote={currentPet}
          petQuoteToEdit={petQuoteToRemove}
          quoteSelectedValues={quoteSelectedValues}
        />

        {isOpen ? (
          <>
            <button
              aria-hidden="true"
              aria-label="overlay"
              className="Pet-quote-drop-down-overlayed-container"
              onClick={handleHeaderOnClick}
              tabIndex={-1}
              type="button"
            />

            <ul
              aria-labelledby={'petQuoteDropDownItem'
                + `${currentPet?.petQuoteId}-header`}
              className={'Pet-quote-drop-down-child-list-container'
                + ` ${customClass}`}
              id="dd-options-pet-list"
              role="menu"
            >
              {renderChildList()}

              <PetQuoteDropDownItem
                dropDownItemType={DROPDOWN_ITEM_TYPE.addPet}
                isOpen={isOpen}
                onAddPetClick={handleAddPetClick}
                quoteSelectedValues={quoteSelectedValues}
                toggleOpen={toggleOpen}
              />
            </ul>
          </>
        ) : null}
      </div>

      {useCustomPlanPills && shouldRenderPriceLog && <PriceLog />}
    </>
  );
};

export { PetQuoteDropDown };
