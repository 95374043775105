import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import catPlaceholder from '../../../assets/cat-selected.svg';
import dogPlaceholder from '../../../assets/dog-selected.svg';

import './ModalMultiQuoteLoading.css';
import { PURCHASE_MULTI_STATUS } from '../../../constants';

export const ModalMultiQuoteLoading = () => {
  const store = useSelector(({ quoting }) => quoting);
  const { t } = useTranslation('quoting');

  const { purchase } = store || {};

  const extraClassWith = useMemo(() => {
    if (purchase?.multiQuoteList.length === 3) {
      return 'Purchase-Multi-Card-container-with-3';
    }

    if (purchase?.multiQuoteList.length > 3) {
      return 'Purchase-Multi-Card-container-with-more';
    }

    return '';
  }, [purchase.multiQuoteList.length]);

  if (!purchase?.multiQuoteLoading) {
    return null;
  }

  return (
    <div className="Modal-wrapper" id="Purchase-modal-multi">
      <div
        aria-hidden="true"
        aria-labelledby="Purchase-modal-multi"
        className="Purchase-Multi-Modal-backdrop"
        role="button"
        tabIndex={-1}
      />
      <div
        aria-labelledby="Purchase-modal-title"
        aria-modal="true"
        className={`Purchase-Multi-Card-container ${extraClassWith}`}
        role="alertdialog"
      >
        <div>
          <h3
            className="Purchase-modal-title"
            id="Purchase-modal-title"
          >
            {t('purchaseModal.title')}
          </h3>
          <span className="Purchase-modal-legend">
            {t('purchaseModal.legend')}
          </span>
        </div>

        {purchase?.multiQuoteList.map((petQuote) => (
          <div
            key={String(petQuote.id)}
            className="Purchase-modal-pet-row"
          >
            <img
              alt=""
              src={petQuote.isCat ? catPlaceholder : dogPlaceholder}
            />

            <div className="Col">
              {t('purchaseModal.petName', { petName: petQuote.petName })}

              <div className="Row">
                <div
                  className="Purchase-modal-pill"
                  data-status={petQuote.status}
                >

                  <span className="Purchase-modal-pill-icon" />

                  <span className="Purchase-modal-pill-text">
                    {petQuote.status}
                  </span>

                  {[
                    PURCHASE_MULTI_STATUS.progress,
                    PURCHASE_MULTI_STATUS.pending,
                  ].includes(petQuote.status)
                    && (
                      <div
                        className="Purchase-loader"
                        data-status={petQuote.status}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
