import React from 'react';
import '../css/SectionTitle.css';

const SectionTitle = ({ title = '', noMarginTop, className = '' }) => (
  <>
    <h2 className={'Section-title'
      + ` ${noMarginTop ? ' Section-title-no-top-margin' : ''}`
      + ` ${className}`}
    >
      {title}
    </h2>
  </>
);

export { SectionTitle };
