import { useEffect } from 'react';
import { isProdEnv, loadEmbeddedScript } from '../util';
import { useBeaconSalesforce } from './useFeatureFlag';

const BEACON_SALESFORCE_SRC = '//cdn.evgnet.com/beacon/usfireins/'
  + 'figo_mcp_dataset/scripts/evergage.min.js';
const BEACON_SALESFORCE_PROD_SRC = '//cdn.evgnet.com/beacon/usfireins/'
  + 'prodfigo/scripts/evergage.min.js';

const useBeaconSalesforceScript = () => {
  const useBeaconSalesfoce = useBeaconSalesforce();

  useEffect(() => {
    if (useBeaconSalesfoce) {
      loadEmbeddedScript({
        scriptAsync: false,
        scriptDefer: false,
        scriptId: 'figoBeaconSalesforce',
        scriptSrc: isProdEnv
          ? BEACON_SALESFORCE_PROD_SRC
          : BEACON_SALESFORCE_SRC,
      });
    }
  }, [useBeaconSalesfoce]);

  return {
    active: !!window.SalesforceInteractions,
  };
};

export { useBeaconSalesforceScript };
