import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIsAuthenticated } from '@azure/msal-react';
import { useHistory, useLocation } from 'react-router-dom';
import ModalForgetPassword from '../common/ModalForgetPassword';
import ModalCustomerWelcome from '../common/ModalCustomerWelcome';
import {
  forgetPasswordVisibleChanged,
  liveVetModalLoginVisibleChanged,
  newCustomerChanged,
  resetLiveVetAccess,
  setCurrentQuoteStep,
  validateCustomerExistsByEmail,
  validateDiamondUser,
} from '../../actions';
import {
  isPaycheck,
  useCustomPlanPills,
  useEnrollmentflag,
  useROPC,
} from '../../util';
import {
  AFTER_RATE_TAG,
  QuotingSteps,
} from '../../constants';
import {
  useB2CInfo,
  useResponsive,
  useRateSummary,
  useCallbackAfterRate,
} from '../../hooks';
import CardPlanMainHeaderCompany from './CardPlanMainHeaderCompany';
import ModalCustomerLogin from '../common/ModalCustomerLogin';
import NeedToKnowNewDesign from './NeedToKnowNewDesign';
import { EffectiveDateCustom } from '../common/EffectiveDateCustom';
import { PowerUpSection } from './powerups/PowerUpSection';
import { URL_LIVE_VET_YOUR_INFO } from '../../routes';
import { MyPetsContainer } from './myPets/MyPetsContainer';
import DiscountApplied from './yourInfo/DiscountApplied';
import { QuotingFloatingImages } from './QuotingFloatingImages';
import { SaveYourQuoteButton } from './SaveYourQuoteButton';
import { LiveVetCard } from './liveVet/LiveVetCard';
import { PlansSection } from './planSection/PlanSection';
import { DeductibleReimbursement } from './customPlan/DeductibleReimbursement';
import { WhatFigoCovers } from './WhatFigoCovers';
import { WhatsNotCovered } from './WhatsNotCovered';
import { SeeFullCoverage } from './SeeFullCoverage';
import { useTranslations } from '../../hooks/useTranslations';

import './CardPlanMain.css';
import ModalContainer from '../common/ModalContainer';
import { NEW_CUSTOMER_EMAIL_CHANGED } from '../../actions/types';

const CardPlanMain = () => {
  const history = useHistory();
  const store = useSelector(({ quoting }) => quoting);
  const dispatch = useDispatch();
  const { t } = useTranslations('quoting');
  const { isMobile, isTablet } = useResponsive();
  const {
    isLoading,
    newCustomer: { email },
    parameters,
    petQuoteSelected,
    quoteSelectedValues,
    sessionInformation: { isCustomer, nopCommerceUser, loadingLogin },
    loggingWithoutModal,
  } = store;
  const isAuthenticated = useIsAuthenticated();
  const { loginB2C } = useB2CInfo();
  const location = useLocation();

  const ESSENTIAL_PLAN = store.plans[0];

  const quoteSelected = quoteSelectedValues
    .find((item) => item.quoteId === petQuoteSelected)
    || { petName: 'Pet Name' };

  let plans = [];
  let petQuote = null;

  if (store.data) {
    petQuote = store.data.ebPetQuoteResponseList
      .find((item) => item.petQuoteId === petQuoteSelected);

    plans = petQuote ? petQuote.Plans : [];
  }

  const [isMounted, setIsMounted] = useState(false);
  const [currentZipCode, setCurrentZipCode] = useState('');
  const [isLiveVet, setIsLiveVet] = useState(false);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const { petZipCode } = parameters;

  useEffect(() => {
    if (!isMounted || currentZipCode !== petZipCode) {
      setIsMounted(true);
      dispatch(setCurrentQuoteStep(QuotingSteps.SelectPlan.value));
      setCurrentZipCode(petZipCode);
      dispatch(liveVetModalLoginVisibleChanged(false));
      dispatch(resetLiveVetAccess());
    }
  }, [
    currentZipCode,
    dispatch,
    isMounted,
    petZipCode,
  ]);

  useEffect(() => {
    async function validateCustomerB2C() {
      if (!nopCommerceUser.LoginSuccess && email && !isAuthenticated) {
        const payload = await validateCustomerExistsByEmail(email);
        const { IsValid, Data } = payload;
        const userAlreadyExists = IsValid && !!Data;

        dispatch(validateDiamondUser({
          customerExistsData: Data,
          email,
          userAlreadyExists,
          validateCustomer: false,
        }));

        if (userAlreadyExists) {
          if (useROPC && !loggingWithoutModal) {
            setShowModalLogin(true);
          } else if (!useROPC) {
            const currentUrl = `${location.pathname}${location.search}`;

            loginB2C({
              existingEmail: email,
              urlState: currentUrl,
            });
          }
        }
      }
    }

    validateCustomerB2C();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (email && isCustomer && !nopCommerceUser.LoginSuccess
      && !loggingWithoutModal) {
      setShowModalLogin(true);
      dispatch(forgetPasswordVisibleChanged({ visible: false }));
    }

    if (nopCommerceUser.LoginSuccess) {
      setShowModalLogin(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isCustomer, email, nopCommerceUser]);

  const { getSelectedRate } = useRateSummary();

  useCallbackAfterRate(() => {
    getSelectedRate();
  }, [
    AFTER_RATE_TAG.createRate,
    AFTER_RATE_TAG.addPet,
    AFTER_RATE_TAG.removePet,
  ]);

  function getPetName() {
    if (quoteSelected) {
      const petNameFactor = 27;
      if (quoteSelected.petName.length < petNameFactor
        || quoteSelected.petName.includes(' ')) {
        return `${quoteSelected.petName}'s Plan`;
      }

      return `${quoteSelected.petName.slice(0, petNameFactor)}...'s Plan`;
    }

    return 'N/A';
  }

  const goToLiveVet = () => {
    history.push(URL_LIVE_VET_YOUR_INFO, { isCustomerLogged: true });
  };

  const handleCloseForgotPassword = useCallback(() => {
    if (isLiveVet) {
      setIsLiveVet(false);
      dispatch(liveVetModalLoginVisibleChanged(true));
    } else {
      setShowModalLogin(true);
    }
  }, [dispatch, isLiveVet]);

  const NeedToKnowSection = () => {
    if (useEnrollmentflag && (isMobile || isTablet)) {
      return <NeedToKnowNewDesign />;
    }

    return null;
  };

  const onDismissLoginModal = useCallback(() => {
    if (!loadingLogin) {
      dispatch(newCustomerChanged({
        type:
          NEW_CUSTOMER_EMAIL_CHANGED,
        value: '',
      }));
      setShowModalLogin(false);
    }
  }, [dispatch, loadingLogin]);

  const handleForgetPasswordClick = useCallback(() => {
    if (!loadingLogin) {
      setShowModalLogin(false);
      dispatch(forgetPasswordVisibleChanged({ visible: true }));
    }
  }, [dispatch, loadingLogin]);

  return (
    <div
      className={'App-card-plan-main-container '
        + 'Card-plan-main-container-elevated'
        + ' App-card-plan-main-container-bg'}
    >
      <ModalForgetPassword
        onModalClosed={handleCloseForgotPassword}
      />

      <ModalCustomerWelcome goTo={goToLiveVet} />

      {useROPC ? (
        <ModalContainer
          cancelable={false}
          handleOutClick={onDismissLoginModal}
          show={showModalLogin}
        >
          <ModalCustomerLogin
            emailUser={email}
            onDismiss={onDismissLoginModal}
            onForgotPassword={handleForgetPasswordClick}
          />
        </ModalContainer>
      ) : null}

      <div
        className={`${useEnrollmentflag
          ? 'Card-plan-main-container-enrollment '
          : 'Card-plan-main-container '}`
          + 'Card-plan-main-container-margin-top'}
      >
        <section
          aria-label={t('cardPlanHeader')}
          className="Card-plan-header-title-container"
        >
          <CardPlanMainHeaderCompany />
        </section>

        <h1 className="Card-plan-header-title">
          {getPetName()}
        </h1>

        <PlansSection />

        {isPaycheck ? (
          <span className="Deductible-legend-text">
            Based on 24 pay periods
          </span>
        ) : null}

        {!useCustomPlanPills && (
          <DeductibleReimbursement
            isEssentialSelected={quoteSelected.selectedPlanIndex
              === ESSENTIAL_PLAN.index}
            plans={plans}
            quoteSelected={quoteSelected}
          />
        )}

        <EffectiveDateCustom />

        <DiscountApplied />

        <PowerUpSection
          petQuote={petQuote}
          quoteSelected={quoteSelected}
        />

        <MyPetsContainer />

        {useEnrollmentflag && !isLoading
          && (
            <>
              <WhatFigoCovers />

              <WhatsNotCovered />

              <SeeFullCoverage isVisible={!isMobile && !isTablet} />
            </>
          )}

        <SaveYourQuoteButton />

        <NeedToKnowSection />
      </div>

      {!useEnrollmentflag && <NeedToKnowNewDesign />}

      <QuotingFloatingImages />

      <LiveVetCard email={email} parameters={parameters} />
    </div>
  );
};

export default CardPlanMain;
