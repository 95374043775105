import { useEffect } from 'react';

const gtmAuth = process.env.REACT_APP_GTM_AUTH
  ? `&gtm_auth=${process.env.REACT_APP_GTM_AUTH}`
  : '';
const gtmPreview = process.env.REACT_APP_GTM_ENV
  ? `&gtm_preview=${process.env.REACT_APP_GTM_ENV}`
  : '';
const gtmCookies = gtmPreview ? '&gtm_cookies_win=x' : '';

/** Injects GTM scripts and noscript for GTM using environments */
const useGTM = () => {
  useEffect(() => {
    const scriptTemplate = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({
      'gtm.start':new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+
      '${gtmAuth}${gtmPreview}${gtmCookies}';
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KKLXW7');`;
    const noscriptTemplate = `<iframe 
    src="https://www.googletagmanager.com/ns.html?id=GTM-KKLXW7${
  gtmAuth}${gtmPreview}${gtmCookies}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    const scriptEl = document.createElement('script');
    scriptEl.innerHTML = scriptTemplate;

    const noscriptEl = document.createElement('noscript');
    noscriptEl.innerHTML = noscriptTemplate;

    document.head.insertBefore(scriptEl, document.head.firstChild);
    document.body.insertBefore(noscriptEl, document.body.firstChild);

    return () => {
      document.head.removeChild(scriptEl);
      document.body.removeChild(noscriptEl);
    };
  }, []);
};

export { useGTM };
