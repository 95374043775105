import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  resetState,
  validateEmployerId,
} from '../../actions';
import { useB2CInfo, usePartnerSubId } from '../../hooks';
import { formatParameters, setSessionStorage } from '../../util';
import { FigoLoadingOverlay } from '../common/FigoLoadingOverlay';
import { ModalAddPet } from '../common/modalAddPet/ModalAddPet';
import ModalContainer from '../common/ModalContainer';
import { LandingHeader } from './landingHeader';
import { LandingWelcome } from './landingWelcome';
import LandingFooter from './LandingFooter';
import { LandingHowWorks } from './landingHowWorks';
import { AppPresentation } from './appPresentation';
import { LandingQuestions } from './landingQuestions/LandingQuestions';
import { ParentsReview } from './parentsReview/ParentsReview';
import { QuestionsSection } from '../quoting/QuestionsSection';
import { TemporaryMessage } from '../quoting/TemporaryMessage';
import './LandingPage.css';

const LandingPage = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ quoting }) => quoting);
  const location = useLocation();
  const parametersRaw = queryString.parse(location.search);
  const parameters = formatParameters(parametersRaw);
  const [visibleGetQuoteModal, setVisibleGetQuoteModal] = useState(false);
  const { b2cToken, logoutB2C } = useB2CInfo();
  const { accounts } = useMsal();
  const urlRedirection = 'https://figopetinsurance.com/employee-benefits/';
  // TODO - This should be coming from a backend endpoint.

  useEffect(() => {
    if (accounts.length && !visibleGetQuoteModal) {
      const currentUrl = `${location.pathname}${location.search}`;
      logoutB2C({ logoutUrl: currentUrl });
    }
  }, [
    accounts.length,
    location.pathname,
    location.search,
    logoutB2C,
    visibleGetQuoteModal,
  ]);

  useEffect(() => {
    if (parameters.empId) {
      dispatch(validateEmployerId(parameters.empId));
    }

    if (!b2cToken && !parameters.empId) {
      window.location.replace(urlRedirection);
    }
  }, [parameters.empId, dispatch, b2cToken]);

  useEffect(() => {
    if (store.employerIdValidated && !store.employerIdValid && !b2cToken) {
      window.location.replace(urlRedirection);
    }
  }, [b2cToken, store.employerIdValid, store.employerIdValidated]);

  useEffect(() => {
    dispatch(resetState());
    setSessionStorage({ data: {}, key: 'store.quoting' });
  }, [dispatch]);

  usePartnerSubId();

  const handleCloseModal = useCallback(() => {
    setVisibleGetQuoteModal(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setVisibleGetQuoteModal(true);
  }, []);

  return (
    <main className="Landing-page">
      <LandingHeader onGetQuote={handleOpenModal} />

      <LandingWelcome onGetQuote={handleOpenModal} />

      <LandingHowWorks />

      <LandingQuestions />

      <AppPresentation />

      <ParentsReview />

      <TemporaryMessage sticky />

      <QuestionsSection isQuote />

      <LandingFooter />

      <ModalContainer
        cancelable
        classCloseIcon="Landing-page-close-icon-modal"
        handleOutClick={handleCloseModal}
        show={visibleGetQuoteModal}
      >
        <ModalAddPet
          getQuote
          handleCloseModalAddPet={handleCloseModal}
          show={visibleGetQuoteModal}
        />
      </ModalContainer>

      <FigoLoadingOverlay visible={store.validatingEmployerId} />
    </main>
  );
};

export { LandingPage };
