import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import {
  YOUR_INFO_STEPS,
  TEN_DIGIT_NUMBER,
  STICKY_BAR_HEIGHT_SECOND_STEP,
  DEFAULT_STICKY_BAR_HEIGHT,
} from '../../../constants';
import {
  createDiamondClient,
  forgetPasswordVisibleChanged,
  loadAmericanStates,
  newCustomerChanged,
  petTagDateOfBirthChanged,
  sendFSCCustomerLead,
  validateCustomerExistsByEmail,
  validateDiamondUser,
  yourInfoStepChanged,
} from '../../../actions';
import {
  useB2CInfo,
  useOneIncScript,
  useRateSummary,
} from '../../../hooks';
import {
  forceFocusElement,
  isValidDiamondStreet,
  useFSCLeads,
  useROPC,
} from '../../../util';

import ill14 from '../../../assets/ills/ill-14.svg';
import ill9 from '../../../assets/ills/ill-9.svg';
import ill4 from '../../../assets/ills/ill-4.svg';

import { PetParentInfo } from './PetParentInfo';
import PlanSummary from './PaymentSummary';
import { PaymentMethod } from './PaymentMethod';
import { YourInfoFooter } from './YourInfoFooter';
import TermsAndConditions from './TermsAndConditions';
import PaymentBreakdown from './PaymentBreakdown';
import DiscountApplied from './DiscountApplied';
import ModalForgetPassword from '../../common/ModalForgetPassword';
import ModalCustomerLogin
from '../../common/ModalCustomerLogin';
import ModalCustomerWelcome from '../../common/ModalCustomerWelcome';
import { YourFirstPayment } from './YourFirstPayment';
import { FigoLoadingOverlay } from '../../common/FigoLoadingOverlay';
import ImportantDatesNewDesign from './ImportantDatesNewDesign';
import CardPlanMainHeaderCompany from '../CardPlanMainHeaderCompany';
import { Prop103DisclaimerText } from './Prop103DisclaimerText';

import './YourInfoContent.css';
import ModalContainer from '../../common/ModalContainer';
import { NEW_CUSTOMER_EMAIL_CHANGED } from '../../../actions/types';
import { TemporaryMessage } from '../TemporaryMessage';
import { QuestionsSection } from '../QuestionsSection';

const YourInfoContent = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const {
    americanStates,
    isLoading,
    newCustomer,
    oneInc: { tokenId },
    petTags,
    sessionInformation,
    termsAndConditions,
    yourInfoStep,
  } = store;
  const { isCustomer, nopCommerceUser, loadingLogin } = sessionInformation;
  const [
    validateTermsAndConditions,
    setValidateTermsAndConditions,
  ] = useState(0);
  const [validatePetParentInfo, setValidatePetParentInfo] = useState(0);
  const [validatePetCloudLogin, setValidatePetCloudLogin] = useState(0);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const isSecondStep = yourInfoStep === YOUR_INFO_STEPS.secondStep;
  const [yourVeterinarianRef, setYourVeterinarianRef] = useState(null);
  const [scrollbarDown, setScrollbarDown] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  const [scrollTermCondition, setScrollTermCondition] = useState(false);
  const termConditionsRef = useRef();

  const [scrollPaymentMethod, setScrollPaymentMethod] = useState(false);
  const paymentMethodRef = useRef();
  const [showPaymentMethodError, setShowPaymentMethodError] = useState(false);

  const [scrollPetParentInfo, setScrollPetParentInfo] = useState(false);
  const petParentInfoRef = useRef();

  const [
    scrollPetParentInfoSecond,
    setScrollPetParentInfoSecond,
  ] = useState(false);
  const petParentInfoSecondRef = useRef();

  const { loginB2C } = useB2CInfo();
  const location = useLocation();

  const useProp103 = useMemo(() => {
    if (store.isLoading || store.petRemovedLoading) {
      return false;
    }

    if (!store.data) {
      return false;
    }

    return store.data.SiteMessages
      .find((item) => item.Name === 'PROP103') || false;
  }, [store.data, store.isLoading, store.petRemovedLoading]);

  useOneIncScript();
  const { getSelectedRate } = useRateSummary();

  useEffect(() => {
    if (isSecondStep) {
      forceFocusElement('newCustomerPasswordConfirm');
    }
  }, [isSecondStep]);

  useEffect(() => {
    async function validateCustomerB2C() {
      if (newCustomer.email
        && !sessionInformation.nopCommerceUser.LoginSuccess
        && !isAuthenticated) {
        const payload = await validateCustomerExistsByEmail(newCustomer.email);
        const { IsValid, Data } = payload;
        const userAlreadyExists = IsValid && !!Data;

        dispatch(validateDiamondUser({
          customerExistsData: Data,
          email: newCustomer.email,
          userAlreadyExists,
          validateCustomer: false,
        }));

        if (userAlreadyExists && !useROPC) {
          const currentUrl = `${location.pathname}${location.search}`;

          loginB2C({
            existingEmail: newCustomer.email,
            urlState: currentUrl,
          });
        }
      }
    }

    validateCustomerB2C();

    getSelectedRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO - implement validation for personalize date
  const isPersonalizeValid = true;

  const isTermsAndConditionsValid =
    termsAndConditions.agreeTermsAndConditionCheck
    && termsAndConditions.understandConditionCheck;
  const isPetParentInfoValid = newCustomer.name.trim().length
    && newCustomer.lastName.trim().length
    && newCustomer.phoneNumber.trim().length
    && TEN_DIGIT_NUMBER.test(newCustomer.phoneNumber.trim())
    && newCustomer.employeeID.trim().length
    && newCustomer.address.city.trim().length
    && newCustomer.address.street.trim().length
    && isValidDiamondStreet(newCustomer.address.street.trim());
  const isSecondParentInfoValid =
    !store.secondParentFormVisible
    || (store.secondParentFormVisible
      && newCustomer.secondParent.name.trim().length
      && newCustomer.secondParent.lastName.trim().length
      && newCustomer.secondParent.phoneNumber.trim().length
      && TEN_DIGIT_NUMBER.test(newCustomer.secondParent.phoneNumber.trim()));

  const isNewCustomerValid = newCustomer.email.trim().length
    && !newCustomer.emailError.trim().length;

  const petCloudLoginSuccess = isNewCustomerValid || isCustomer;

  const isPurchaseValid = isTermsAndConditionsValid
    && isPersonalizeValid
    && isPetParentInfoValid
    && isSecondParentInfoValid
    && petCloudLoginSuccess
    && tokenId;

  useEffect(() => {
    if (isSecondStep && yourVeterinarianRef) {
      window.scrollTo({
        behavior: 'smooth',
        top: yourVeterinarianRef.offsetTop - 100,
      });
    } else if (!yourVeterinarianRef) {
      const element = document.getElementById('yourVeterinarianRef');
      setYourVeterinarianRef(element);
    }
  }, [yourVeterinarianRef, setYourVeterinarianRef, isSecondStep]);

  useEffect(() => {
    if (scrollTermCondition) {
      window.scrollTo({
        behavior: 'smooth',
        top: termConditionsRef.current.offsetTop,
      });
      setScrollTermCondition(false);
    }
  }, [scrollTermCondition]);

  useEffect(() => {
    if (scrollPaymentMethod) {
      window.scrollTo({
        behavior: 'smooth',
        top: paymentMethodRef.current.offsetTop,
      });
      setScrollPaymentMethod(false);
    }
  }, [scrollPaymentMethod]);

  useEffect(() => {
    if (scrollPetParentInfo) {
      window.scrollTo({
        behavior: 'smooth',
        top: petParentInfoRef.current.offsetTop,
      });
      setScrollPetParentInfo(false);
    }
  }, [scrollPetParentInfo]);

  useEffect(() => {
    if (scrollPetParentInfoSecond) {
      window.scrollTo({
        behavior: 'smooth',
        top: petParentInfoSecondRef.current.offsetTop,
      });
      setScrollPetParentInfoSecond(false);
    }
  }, [scrollPetParentInfoSecond]);

  const sendCustomerLead = useCallback(() => {
    if (useFSCLeads) {
      dispatch(sendFSCCustomerLead({
        address: {
          city: newCustomer.address.city,
          line1: newCustomer.address.street,
          line2: newCustomer.address.street2,
          state: newCustomer.address.state,
          zip: newCustomer.address.zipCode,
        },
        email: newCustomer.email,
        firstName: newCustomer.name,
        lastName: newCustomer.lastName,
        phoneNumber: newCustomer.phoneNumber,
        secondaryPetParent: {
          firstName: newCustomer.secondParent.name,
          lastName: newCustomer.secondParent.lastName,
          phoneNumber: newCustomer.secondParent.phoneNumber,
        },
      }));
    }
  }, [dispatch, newCustomer]);

  useEffect(() => {
    if (americanStates.length <= 0) {
      dispatch(loadAmericanStates());
    }
  }, [americanStates, dispatch]);

  function createCustomer() {
    if (isCustomer && nopCommerceUser.DiamonClientdId) {
      return;
    }

    const {
      newCustomer: {
        address,
        email,
        name,
        lastName,
        phoneNumber,
      },
    } = store;

    dispatch(createDiamondClient({
      address: address.street,
      city: address.city,
      clientId: nopCommerceUser.DiamonClientdId,
      email,
      firstName: name,
      lastName,
      phoneNumber,
      stateAbbreviation: address.stateAbbr,
      stateName: address.state,
      zipCode: address.zipCode,
    }));
  }

  useEffect(() => {
    if (tokenId) {
      setShowPaymentMethodError(false);
    }
  }, [tokenId]);

  const handleContinuePurchaseClick = () => {
    if (isSecondStep) {
      setValidateTermsAndConditions(validateTermsAndConditions + 1);
    }
    setValidatePetParentInfo(validatePetParentInfo + 1);
    setValidatePetCloudLogin(validatePetCloudLogin + 1);

    if (!isPetParentInfoValid) {
      setScrollPetParentInfo(true);
    } else if (!isSecondParentInfoValid) {
      setScrollPetParentInfoSecond(true);
    } else if (!tokenId && isSecondStep) {
      setScrollPaymentMethod(true);
      setShowPaymentMethodError(true);
    } else if (!isTermsAndConditionsValid && isSecondStep) {
      setScrollTermCondition(true);
    }

    if (isPetParentInfoValid && isSecondParentInfoValid
      && petCloudLoginSuccess) {
      createCustomer();

      sendCustomerLead();
      dispatch(yourInfoStepChanged(YOUR_INFO_STEPS.secondStep));
    }
  };

  useEffect(() => {
    if (newCustomer.email && isCustomer && !nopCommerceUser.LoginSuccess) {
      setShowModalLogin(true);
      dispatch(forgetPasswordVisibleChanged({ visible: false }));
    }

    if (nopCommerceUser.LoginSuccess) {
      setShowModalLogin(false);
    }
  }, [dispatch, isCustomer, newCustomer.email, nopCommerceUser]);

  const onModalClosed = () => {
    setShowModalLogin(true);
  };

  const showLoadingOverlay = useMemo(() => isLoading
    || sessionInformation.isLoading
    || store.isLoadingNewAddress
    || store.purchase.loading
    || store.rateSummary.loading, [
    isLoading,
    sessionInformation.isLoading,
    store.isLoadingNewAddress,
    store.purchase.loading,
    store.rateSummary.loading]);

  const birthPurchaseDate = new Date();
  birthPurchaseDate.setFullYear(birthPurchaseDate.getFullYear() - 1);

  const formattedDateMinusOneYear =
    birthPurchaseDate.toLocaleDateString('en-US').replace(/\//g, '-');

  const handleScroll = () => {
    if (document.body) {
      setScrollbarDown(
        document.body.getBoundingClientRect().top <= -10,
      );
    }
  };

  useEffect(() => {
    petTags.forEach((petTag) => {
      const { petQuoteId } = petTag;
      dispatch(petTagDateOfBirthChanged({
        petDateOfBirth: formattedDateMinusOneYear,
        petQuoteId,
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formattedDateMinusOneYear]);

  useEffect(() => {
    // Free user don't have DiamondClientId only CustomerId
    const isFreeUser = sessionInformation.isCustomer
      && !nopCommerceUser.DiamonClientdId
      && nopCommerceUser.CustomerNopCommerceId;
    const isLoadingUser = sessionInformation.isLoading
      || sessionInformation.loadingLogin;

    if (!isSecondStep || !isFreeUser || isLoadingUser) {
      return;
    }

    // create diamondId guess for one inc
    createCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSecondStep, sessionInformation]);

  useEffect(() => {
    // Free user don't have DiamondClientId only CustomerId
    const isFreeUser = sessionInformation.isCustomer
      && !nopCommerceUser.DiamonClientdId
      && nopCommerceUser.CustomerNopCommerceId;
    const isLoadingUser = sessionInformation.isLoading
      || sessionInformation.loadingLogin;

    if (!isSecondStep || !isFreeUser || isLoadingUser) {
      return;
    }

    // create diamondId guess for one inc
    createCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSecondStep, sessionInformation]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const onDismissLoginModal = useCallback(() => {
    if (!loadingLogin) {
      dispatch(newCustomerChanged({
        type:
          NEW_CUSTOMER_EMAIL_CHANGED,
        value: '',
      }));
      setShowModalLogin(false);
    }
  }, [dispatch, loadingLogin]);

  const handleForgetPasswordClick = useCallback(() => {
    if (!loadingLogin) {
      setShowModalLogin(false);
      dispatch(forgetPasswordVisibleChanged({ visible: true }));
    }
  }, [dispatch, loadingLogin]);

  const openLoginModal = useCallback(() => {
    setShowModalLogin(true);
  }, []);

  return (
    <>
      <FigoLoadingOverlay
        visible={showLoadingOverlay}
      />

      <ModalCustomerWelcome />

      <ModalForgetPassword
        onModalClosed={onModalClosed}
      />

      {useROPC ? (
        <ModalContainer
          cancelable={false}
          handleOutClick={onDismissLoginModal}
          show={showModalLogin}
        >
          <ModalCustomerLogin
            emailUser={newCustomer.email}
            onDismiss={onDismissLoginModal}
            onForgotPassword={handleForgetPasswordClick}
          />
        </ModalContainer>
      ) : null}

      <div className={'Your-info-ills-container'
        + `${isSecondStep ? ' Your-info-ills-justify' : ''}`}
      >
        <div className="Your-info-ill-container">
          <img alt="" aria-hidden src={ill14} />
        </div>

        {isSecondStep && (
          <>
            <div className="Your-info-ill-container">
              <img alt="" aria-hidden src={ill9} />
            </div>

            <div className="Your-info-ill-container">
              <img alt="" aria-hidden src={ill4} />
            </div>
          </>
        )}
      </div>

      <div
        className={'Your-info-main-container'
          + `${scrollbarDown ? ' Your-info-scrolled' : ''}`}
      >

        <div className="Your-info-container-company-header">
          <CardPlanMainHeaderCompany />
        </div>

        <div className="Your-info-step-container">
          <PetParentInfo
            containerRef={petParentInfoRef}
            containerSecondaryRef={petParentInfoSecondRef}
            openLogin={openLoginModal}
            sendCustomerLead={sendCustomerLead}
            validatePetParentInfo={validatePetParentInfo}
          />
        </div>

        <div
          className={'Your-info-step-container '
            + ` ${!isSecondStep && 'Your-info-step-gone'}`}
        >
          <PlanSummary />

          <DiscountApplied isSecondStep={isSecondStep} />

          <PaymentBreakdown />

          {useProp103 && <Prop103DisclaimerText />}

          <YourFirstPayment />

          <PaymentMethod
            containerRef={paymentMethodRef}
            showError={showPaymentMethodError}
          />

          <ImportantDatesNewDesign />

          <TermsAndConditions
            containerRef={termConditionsRef}
            triggerValidation={validateTermsAndConditions}
          />
        </div>

        <div className="Your-info-step-container">
          <YourInfoFooter
            handleContinuePurchaseClick={handleContinuePurchaseClick}
            isPurchaseValid={isPurchaseValid}
            isSecondStep={isSecondStep}
            petCloudLoginSuccess={petCloudLoginSuccess}
          />
        </div>
      </div>

      <TemporaryMessage
        positionBottom={isSecondStep
          ? STICKY_BAR_HEIGHT_SECOND_STEP
          : DEFAULT_STICKY_BAR_HEIGHT}
        sticky
      />

      <QuestionsSection isQuote />
    </>
  );
};

export { YourInfoContent };
