import React from 'react';
import feature from '../../../assets/landing/feature.svg';
import './Feature.css';

const Feature = ({
  text = '',
  title = '',
}) => (
  <div className="Feature">
    <img alt="" className="Feature-image" src={feature} />

    <h3>{title}</h3>

    <p>{text}</p>
  </div>
);

export { Feature };
