import React, { useState, createContext, useContext } from 'react';

const StickyBarHeightContext = createContext({
  setStickyBarHeight: () => {},
  stickyBarHeight: 0,
});

export const useStickyBarHeight = () => useContext(StickyBarHeightContext);

export const StickyBarHeightProvider = ({ children }) => {
  const [stickyBarHeight, setStickyBarHeight] = useState(0);
  return (
    <StickyBarHeightContext.Provider
      value={{ setStickyBarHeight, stickyBarHeight }}
    >
      {children}
    </StickyBarHeightContext.Provider>
  );
};
