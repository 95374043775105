import React from 'react';

import okIcon from '../../../assets/purchase/ok.svg';

import './PetPurchaseResult.css';

const PetPurchaseSuccessCard = ({
  petIcon,
  petName,
  policyNumber,
  t,
}) => (
  <div className="Pet-purchase-result">
    <img alt="" aria-hidden src={petIcon} />

    <div className="Pet-purchase-result-summary-content">
      <span>
        <div className="Pet-purchase-result-name">{petName}</div>

        <div className="Pet-purchase-result-policy-number">
          {t('policyNumber', { policyNumber })}
        </div>
      </span>

      <div className="Pet-purchase-result-description-container">
        <img alt="" aria-hidden src={okIcon} />

        <span className="Pet-purchase-result-policy-purchased">
          {t('policyPurchased')}
        </span>
      </div>
    </div>
  </div>
);

export { PetPurchaseSuccessCard };
