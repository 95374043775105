import React from 'react';
import { useLocation } from 'react-router-dom';

import figoLogoSmall from '../../../assets/figo-logo-round.svg';
import { currencyFormat } from '../../../util';
import './css/QuotingHeader.css';

const MyQuote = ({
  isMonthlyPlan = true,
  isPaycheck = false,
  liveVetPrice = {},
  myQuoteFlatCorner = false,
  quoteSelectedValues = [],
  showQuotePriceMobile = false,
}) => {
  const location = useLocation();
  const isYourInfo = location.pathname.includes('/yourInfo');
  const isLiveVet = location.pathname.includes('liveVetInfo');

  function getQuoteTotal() {
    const allQuotes = quoteSelectedValues
      .reduce((previous, current) => {
        if (isYourInfo && !isMonthlyPlan) {
          return previous + current.totalAnnual;
        }

        return previous + current.totalMontly;
      }, 0);

    if (isLiveVet) {
      return {
        decimal: liveVetPrice.decimal,
        integer: `${liveVetPrice.integer} `,
      };
    }

    if (!allQuotes) {
      return { decimal: '00', integer: 0 };
    }

    const quoteTotal = currencyFormat({ value: allQuotes });
    const [integer, decimal] = quoteTotal.toString().split('.');

    return { decimal, integer };
  }

  function renderQuoteTotal() {
    const quoteTotal = getQuoteTotal();

    return (
      <>
        <span className="Quoting-header-quote-currency-small">$</span>

        <span
          className="Quoting-header-quote-currency-big"
        >
          {quoteTotal.integer}
        </span>

        <span
          className="Quoting-header-quote-currency-small"
        >
          .{quoteTotal.decimal}
        </span>
      </>
    );
  }

  /* TODO: paycheck only */
  function renderQuoteFrecuency() {
    if (isLiveVet) {
      return '/ year';
    }

    if (isPaycheck) {
      return '/ paycheck';
    }

    return isYourInfo && !isMonthlyPlan ? '/ year' : '/ month';
  }

  return (
    <div
      className={'Quoting-header-quote-total-wrapper'
        + ` ${showQuotePriceMobile ? 'Quoting-header-mobile-price' : ''}`}
    >
      <div className={`Quoting-header-quote-total-container
              ${myQuoteFlatCorner && 'Quoting-header-quote-total-flat'}`}
      >
        <h2 className="Quoting-header-my-quote">
          <img alt="" src={figoLogoSmall} />

          {isLiveVet
            ? 'Live Vet'
            : 'My Quote'}
        </h2>

        <div className="Quoting-header-quote-currency-container">
          <div className="Quoting-header-quote-currency">
            {renderQuoteTotal()}
          </div>

          <span className="Quoting-header-quote-monthly">
            {renderQuoteFrecuency()}
          </span>
        </div>
      </div>
    </div>
  );
};

export { MyQuote };
