import React from 'react';

import ill5 from '../../assets/ills/ill-5.svg';

import './QuotingFloatingImages.css';

const QuotingFloatingImages = () => (
  <img
    alt=""
    aria-hidden
    className="Quote-content-image-1"
    src={ill5}
  />
);

export { QuotingFloatingImages };
