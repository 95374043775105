import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FigoInput.css';
import { FigoInputMessage } from './FigoInputMessage';
import lockIcon from '../../assets/lock-square-closed.svg';
import visibilityOFFIcon from '../../assets/visibility-off.svg';
import visibilityONIcon from '../../assets/yourInfo/hide-password.svg';
import IconTooltip from './IconTooltip';

const SIZES = {
  large: 'large',
  small: 'small',
  xl: 'xl',
};

const FigoInput = forwardRef(({
  containerClassName = '',
  disabled = false,
  error = false,
  hint = '',
  id = '',
  label = '',
  message = '',
  messageType = '',
  name = '',
  onBlur = () => { },
  onChange = () => { },
  onFocus = () => { },
  placeholder = '',
  readOnly = false,
  rightIcon = null,
  size = SIZES.large,
  type = 'text',
  value = '',
  tooltip = '',
  ...inputProps
}, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation('common');
  const sizeClassName = useMemo(() => `Figo-input-${size}`, [size]);

  const handleVisibility = useCallback(() => {
    setIsVisible((prevVisibility) => !prevVisibility);
  }, []);

  const rightIconDisplay = useMemo(() => {
    if (rightIcon) {
      return (
        <div className="Figo-input-right-display">
          {rightIcon}
        </div>
      );
    }
    if (readOnly) {
      return <img alt="" className="Figo-input-right-display" src={lockIcon} />;
    }
    if (type === 'password') {
      return (
        <button
          className="Figo-input-right-display Figo-input-right-action"
          onClick={handleVisibility}
          type="button"
        >
          <img
            alt={t(`inputs.${isVisible
              ? 'passwordAltVisible' : 'passwordAltNotVisible'}`, { label })}
            src={isVisible ? visibilityONIcon : visibilityOFFIcon}
          />
        </button>
      );
    }
    return null;
  }, [handleVisibility, isVisible, label, readOnly, rightIcon, t, type]);

  const inputType = useMemo(() => {
    if (type === 'password' && isVisible) {
      return 'text';
    }
    return type;
  }, [isVisible, type]);

  return (
    <div className={`Figo-input-container ${containerClassName}`}>
      <label className="Figo-input-label" htmlFor={id}>
        <span>
          {label}

          {tooltip ? <IconTooltip description={tooltip} /> : null}
        </span>

        <input
          ref={ref}
          className={`Figo-input-input ${sizeClassName}`}
          data-error={error}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          readOnly={readOnly}
          type={inputType}
          value={value}
          {...inputProps}
        />

        {rightIconDisplay}
      </label>

      {hint ? (
        <p className="Figo-input-hint">
          {hint}
        </p>
      ) : null}

      <FigoInputMessage message={message} type={messageType} />
    </div>
  );
});

export { FigoInput };
