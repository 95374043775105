import React, { useMemo } from 'react';
import FigoLottie from './FigoLottie';
import whiteSpinner from '../../assets/loading-whites.json';
import navySpinner from '../../assets/loading-navys.json';
import mintSpinner from '../../assets/loading-mints.json';
import loadingSpinner from '../../assets/loading-spinner.json';
import './LoadingButton.css';

const colors = {
  MINT: 'mint',
  NAVY: 'navy',
  WHITE: 'white',
};

const LoadingButton = ({
  ariaLabel = '',
  buttonEvents,
  children,
  className,
  isLoading,
  loadingColor = colors.NAVY,
  submit,
}) => {
  const animationData = useMemo(() => {
    switch (loadingColor) {
      case colors.WHITE:
        return whiteSpinner;
      case colors.MINT:
        return mintSpinner;
      case colors.NAVY:
        return navySpinner;
      default:
        return loadingSpinner;
    }
  }, [loadingColor]);

  let content = children;
  if (isLoading) {
    content = (
      <FigoLottie
        animationData={animationData}
        ariaLabel="Loading"
        height={20}
        width={20}
      />
    );
  }

  const type = useMemo(() => ({
    type: submit ? 'submit' : 'button',
  }), [submit]);

  return (
    <div className="Row" role="status">
      <button
        aria-label={isLoading ? 'Loading' : ariaLabel}
        className={`${className} Loading-button`}
        type="button"
        {...type}
        {...buttonEvents}
      >
        {content}
      </button>
    </div>
  );
};

export { LoadingButton };
