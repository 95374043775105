import React, { useState } from 'react';

export const LiveVetContext = React.createContext();

const Provider = ({ children }) => {
  const [callbackPetParent, setCallbackParnt] = useState({});
  const [callbackNewCustomer, setCallbackNewCustomer] = useState({});

  const petParentValue = {
    forcePetParentValidation: () => {
      if (callbackPetParent
        && callbackPetParent.forcePetParentValidation) {
        callbackPetParent.forcePetParentValidation();
      }
    },
    setForcePetParent: (callback) => {
      setCallbackParnt({ forcePetParentValidation: callback });
    },
  };

  const newCustomerValue = {
    forceNewCustomerValidation: () => {
      if (callbackNewCustomer
        && callbackNewCustomer.forceNewCustomerValidation) {
        callbackNewCustomer.forceNewCustomerValidation();
      }
    },
    setForceNewCustomer: (callback) => {
      setCallbackNewCustomer({ forceNewCustomerValidation: callback });
    },
  };

  return (
    <LiveVetContext.Provider value={{ newCustomerValue, petParentValue }}>
      {children}
    </LiveVetContext.Provider>
  );
};

export default { Consumer: LiveVetContext.Consumer, Provider };
