import React from 'react';
import InboxIcon from '../../assets/inbox-and-docs.svg';
import { useOnEscEvent } from '../../hooks';
import ModalContainer from '../common/ModalContainer';
import './SidebarSaveQuoteModalConfirmation.css';

const SidebarSaveQuoteModalConfirmation = (props) => {
  function handleOnClick(event) {
    if (props.handleOnClick) {
      props.handleOnClick(event);
    }
  }

  useOnEscEvent(() => {
    handleOnClick();
  }, []);

  return (
    <ModalContainer
      cancelable={false}
      handleOutClick={handleOnClick}
      show={props.show}
    >
      <div className="Sidebar-save-quote-modal-confirmation-container">
        <img
          alt=""
          className="Sidebar-save-quote-modal-confirmation-inbox"
          src={InboxIcon}
        />

        <div
          className="Sidebar-save-quote-modal-confirmation-header-container"
        >
          <h4 className="Sidebar-save-quote-modal-confirmation-header">
            Quote Saved
          </h4>
        </div>

        <div className="Sidebar-save-quote-modal-confirmation-desc-container">
          <span className="Sidebar-save-quote-modal-confirmation-desc">
            All set! An email with a link to your saved quote has been sent.
          </span>
        </div>

        <input
          className="Sidebar-save-quote-modal-confirmation-buton"
          onClick={handleOnClick}
          type="button"
          value="Close"
        />
      </div>
    </ModalContainer>
  );
};

export default SidebarSaveQuoteModalConfirmation;
