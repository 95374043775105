import moment from 'moment';
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  effectiveDateCustomChanged,
  updateRateQuote,
} from '../../actions/quoting';
import { isProdEnv } from '../../util';
import './EffectiveDateCustom.css';

const EffectiveDateCustom = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const { data, effectiveDateCustom, petQuoteSelected } = store;
  const dispatch = useDispatch();
  const selectedDateFormated =
    effectiveDateCustom ? new Date(`${effectiveDateCustom}T00:00:00`) : null;

  function doReRate(newDate) {
    const petQuoteList = data.ebPetQuoteResponseList;
    const petSelected = petQuoteList.find((pet) => (
      pet.petQuoteId === petQuoteSelected));

    const {
      parameters,
      guidData,
      sessionInformation: { nopCommerceUser, userEmail },
    } = store;

    dispatch(updateRateQuote({
      diamondClientId: nopCommerceUser.DiamonClientdId,
      ebGuID: guidData.GuID,
      effectiveDateCustom: newDate,
      eMail: userEmail || parameters.email,
      employerName: guidData.Name,
      guidPayload: guidData,
      modifiers: petSelected.modifiers,
      nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      parameters,
      petQuoteList,
      promoCode: data.groupCode,
      quoteId: petSelected.petQuoteId,
      showLoading: true,
    }));
  }

  function onDatePickerChange(newDate) {
    let date = newDate;
    if (newDate) {
      date = moment(newDate).format('YYYY-MM-DD');
    }

    dispatch(effectiveDateCustomChanged(date));

    doReRate(date);
  }

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      ref={ref}
      aria-label="Effective date"
      className="Effective-date-custom-button"
      onClick={onClick}
      tabIndex={-1}
      title="Effective date"
      type="button"
    >
      {value}
    </button>
  ));

  if (isProdEnv) {
    return null;
  }

  return (
    <div aria-hidden="true" className="Effective-date-custom-container">
      <span className="Effective-date-text">
        Effective date (only for testing)
      </span>

      <DatePicker
        allowSameDay
        customInput={(
          <CustomInput
            onClick={onDatePickerChange}
            value={selectedDateFormated}
          />
        )}
        isClearable
        onChange={onDatePickerChange}
        placeholderText="Effective date test"
        selected={selectedDateFormated}
      />
    </div>
  );
};

export { EffectiveDateCustom };
