import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/GetQuoteButton.css';

const GetQuoteButton = ({
  quoteRef,
  className = '',
  onClick = () => { },
  tabIndex = 0,
}) => {
  const { t } = useTranslation('landing');

  return (
    <button
      ref={quoteRef}
      className={`Get-quote-button ${className}`}
      onClick={onClick}
      tabIndex={tabIndex}
      type="button"
    >
      {t('getQuote')}
    </button>
  );
};

export { GetQuoteButton };
