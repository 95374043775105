import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const useSessionFooter = () => {
  const data = useSelector((stores) => stores.quoting.data, shallowEqual);

  const sessionFooter = useMemo(() => {
    if (!data || !data.insuranceProductEB) {
      return '';
    }
    const footerSection = data
      .insuranceProductEB.InsuranceInformationSectionsEB
      .find((item) => item.Title === 'Footer Text');

    return footerSection.InsuranceInformationDetailsEB[0].Text;
  }, [data]);

  return sessionFooter;
};

export { useSessionFooter };
