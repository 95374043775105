import {
  FEATURE_FLAGS_FAILED,
  FEATURE_FLAGS_LOADED,
  FEATURE_FLAGS_REQUESTED,
} from '../actions/types';

const INITIAL_STATE = {
  featureFlags: {
    error: '',
    flags: {},
    loading: false,
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FEATURE_FLAGS_FAILED:
      return {
        ...state,
        featureFlags: {
          ...state.featureFlags,
          error: '',
          flags: {},
          loading: false,
        },
      };
    case FEATURE_FLAGS_LOADED:
      return {
        ...state,
        featureFlags: {
          ...state.featureFlags,
          error: '',
          flags: action.payload,
          loading: false,
        },
      };
    case FEATURE_FLAGS_REQUESTED:
      return {
        ...state,
        featureFlags: {
          ...state.featureFlags,
          error: action.payload,
          loading: true,
        },
      };
    default:
      return state;
  }
}
